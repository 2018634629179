import React, { useState } from 'react'
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Table } from "@mui/material";
import { FaPlusSquare } from "react-icons/fa";
import { FaMinusSquare } from "react-icons/fa";
import './customExpandableTableStyle.css'
import { BsRecordCircleFill } from "react-icons/bs";
function CustomExpandableTable({ columns, rows, no_border, table_legend, selectedRow, expand_columns_1, expand_columns_2, nested_table_title_1, nested_table_title_2 }) {
    const [expandedRow, setExpandedRow] = useState(null);
    const StyledTableCell = styled(TableCell)(({ isAction }) => ({
        ...(isAction && {
            textAlign: 'center',
        }),
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#38818133",
            color: "#000000",
            borderBottom: "none",
            fontWeight: "600",
            fontFamily: "Jost",
            fontSize: "14px",
            padding: "15px 12px",

        },
        [`&.${tableCellClasses.body}`]: {
            maxWidth: "500px",
            overflowWrap: "break-word",
            fontSize: "14px",
            borderBottom: "none",
            fontFamily: "Jost",
            padding: "12px 15px",
            fontWeight: "400",
            overflow: "auto",
            whiteSpace: "normal",
            wordWrap: "break-word",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
            "& > *:first-of-type": {
                backgroundColor: "#ffffff",
            },
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f2f6f6",
            "& > *:first-of-type": {
                backgroundColor: "#f2f6f6",
            },
        },
    }));
    const StyledTableRowWithBorderRadius = styled(TableRow)(({ theme }) => ({
        '&:first-of-type th:first-of-type': {
            borderTopLeftRadius: no_border ? '0px' : '10px',
            borderBottomLeftRadius: no_border ? '0px' : '10px',
        },
        '&:first-of-type th:last-of-type': {
            textAlign: columns.length >= 5 ? 'left' : 'center',
            borderTopRightRadius: no_border ? '0px' : '10px',
            borderBottomRightRadius: no_border ? '0px' : '10px',
        },
    }));
    const NestedTableStyledTableCell = styled(TableCell)(({ isAction }) => ({
        ...(isAction && {
            textAlign: 'center',
        }),
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#38818133",
            color: "#000000",
            borderBottom: "none",
            fontWeight: "600",
            fontFamily: "Jost",
            fontSize: "14px",
            padding: "8px 12px",

        },
        [`&.${tableCellClasses.body}`]: {
            maxWidth: "500px",
            overflowWrap: "break-word",
            fontSize: "14px",
            borderBottom: "none",
            fontFamily: "Jost",
            padding: "12px 15px",
            fontWeight: "400",
            overflow: "auto",
            whiteSpace: "normal",
            wordWrap: "break-word",
        },
    }));

    const NestedTableStyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
            "& > *:first-of-type": {
                backgroundColor: "#ffffff",
            },
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f2f6f6",
            "& > *:first-of-type": {
                backgroundColor: "#f2f6f6",
            },
        },
    }));
    const NestedTableStyledTableRowWithBorderRadius = styled(TableRow)(({ theme }) => ({
        '&:first-of-type th:first-of-type': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        '&:first-of-type th:last-of-type': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },
    }));

    const toggleRowExpand = (rowKey) => {
        setExpandedRow(expandedRow === rowKey ? null : rowKey);
    };
    return (
        <div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead style={{
                        borderRadius: "20px",
                        //  whiteSpace:'nowrap'
                    }}>
                        <StyledTableRowWithBorderRadius>
                            {columns &&
                                columns.map((column) => (
                                    <StyledTableCell
                                        key={column.key}
                                        isAction={!!column.actions}
                                    >
                                        {column.title}
                                    </StyledTableCell>
                                ))}
                        </StyledTableRowWithBorderRadius>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <>
                                <StyledTableRow key={row.key} >
                                    <>

                                        {columns.map((column) => (
                                            <>
                                                {column.key === "expand" ? <StyledTableCell>
                                                    {row?.is_date_changes || row?.is_cancelled ?
                                                        <div className='custom_table_expand_container'>
                                                           

                                                            <IconButton
                                                                // disabled={!row?.is_date_changes}
                                                                onClick={() => toggleRowExpand(row.key)}
                                                                variant="outlined"
                                                                size="small"
                                                            >
                                                                <div className='custom_table_expand_container'>
                                                                    {expandedRow === row.key ? <FaMinusSquare className='custom_table_expand_icon' /> : <FaPlusSquare className='custom_table_expand_icon' />}
                                                                </div>
                                                            </IconButton>

                                                            {row?.is_current && (
                                                                <IconButton
                                                                    // disabled={!row?.is_date_changes}
                                                                    variant="outlined"
                                                                    size="small"
                                                                >
                                                                    <div >
                                                                        <BsRecordCircleFill className='highlight_icon' style={{ color: row.is_highlight_row ? row.highlight_color : '' }} />
                                                                    </div>
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                        : <></>}
                                                </StyledTableCell> : <StyledTableCell
                                                    key={column.key}
                                                    style={{ color: row.is_highlight_row ? row.highlight_color : '' }}
                                                    onClick={() => {
                                                        if (selectedRow && row.is_row_select) {
                                                            selectedRow(row)
                                                        }
                                                    }}
                                                >
                                                    {column.action ? (
                                                        column.action(row)
                                                    ) : column.wrapper ? (
                                                        column.wrapper(row)
                                                    ) : (
                                                        <div>
                                                            <p>
                                                                {row[column.dataIndex] == 0 || row[column.dataIndex] ? row[column.dataIndex] : " - "}
                                                            </p>
                                                        </div>
                                                    )}
                                                </StyledTableCell>}
                                            </>

                                        ))}
                                        {/*ExpandableTable */}

                                    </>
                                </StyledTableRow>
                                {expandedRow === row.key && (
                                    <>

                                        {/* //Cancellation */}
                                        {row?.nested_table_row_data_2 && row?.nested_table_row_data_2.length > 0 && (
                                            <TableRow>
                                                <TableCell colSpan={columns.length + 1} style={{ border: '1px solid #074D4D' }}>
                                                    <p className='nested_table_header_title'>{nested_table_title_2}</p>
                                                    <Table size="small" aria-label="nested table" >
                                                        <TableHead style={{
                                                            borderRadius: "20px"
                                                        }}>
                                                            <NestedTableStyledTableRowWithBorderRadius>
                                                                {expand_columns_2 &&
                                                                    expand_columns_2.map((column) => (
                                                                        <NestedTableStyledTableCell
                                                                            key={column.key}
                                                                        // isAction={!!column.actions}
                                                                        >
                                                                            {column.title}
                                                                        </NestedTableStyledTableCell>
                                                                    ))}
                                                            </NestedTableStyledTableRowWithBorderRadius>
                                                        </TableHead>
                                                        <TableBody>
                                                            {/* Example nested row data */}
                                                            {row?.nested_table_row_data_2 && row?.nested_table_row_data_2.map((subRow, index) => (
                                                                <NestedTableStyledTableRow key={index} >
                                                                    {expand_columns_2.map((column) => (
                                                                        <>
                                                                            <StyledTableCell
                                                                                key={column.key}
                                                                            >
                                                                                <p>
                                                                                    {subRow[column.dataIndex] == 0 || subRow[column.dataIndex] ? subRow[column.dataIndex] : " - "}
                                                                                </p>
                                                                            </StyledTableCell>
                                                                        </>

                                                                    ))}
                                                                </NestedTableStyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {/* //Date Chnage */}
                                        {row?.nested_table_row_data_1 && row?.nested_table_row_data_1.length > 0 && (
                                            <TableRow>
                                                <TableCell colSpan={columns.length + 1} style={{ border: '1px solid #074D4D' }}>
                                                    <p className='nested_table_header_title'>{nested_table_title_1}</p>
                                                    <Table size="small" aria-label="nested table" >
                                                        <TableHead style={{
                                                            borderRadius: "20px"
                                                        }}>
                                                            <NestedTableStyledTableRowWithBorderRadius>
                                                                {expand_columns_1 &&
                                                                    expand_columns_1.map((column) => (
                                                                        <NestedTableStyledTableCell
                                                                            key={column.key}
                                                                        // isAction={!!column.actions}
                                                                        >
                                                                            {column.title}
                                                                        </NestedTableStyledTableCell>
                                                                    ))}
                                                            </NestedTableStyledTableRowWithBorderRadius>
                                                        </TableHead>
                                                        <TableBody>
                                                            {/* Example nested row data */}
                                                            {row?.nested_table_row_data_1 && row?.nested_table_row_data_1.map((subRow, index) => (
                                                                <NestedTableStyledTableRow key={index} >
                                                                    {expand_columns_1.map((column) => (
                                                                        <>
                                                                            <StyledTableCell
                                                                                key={column.key}
                                                                            >
                                                                                <p>
                                                                                    {subRow[column.dataIndex] == 0 || subRow[column.dataIndex] ? subRow[column.dataIndex] : " - "}
                                                                                </p>
                                                                            </StyledTableCell>
                                                                        </>

                                                                    ))}
                                                                </NestedTableStyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                    </>

                                )}
                            </>
                        ))}
                    </TableBody>
                    {table_legend}
                </Table>
            </TableContainer>
            {rows && rows.length === 0 && (
                <div className="no_data_container">
                    <div className="no_data_sub_container">
                        {/* <img src={table_no_data} className='no_data_image' /> */}
                        <p>No data available</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CustomExpandableTable