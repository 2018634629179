import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './customModalStyle.css'
function CustomModal({ open, handleClose, title, close, body ,maxWidth,title_other}) {
    return (
        <div>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={() => { handleClose(false) }}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth:maxWidth?maxWidth:"550px",  // Set your width here
                      },
                    },
                  }}
            >
                <DialogTitle >
                    {title_other ? (
                        <div className='custom_modal_multiple_header_text_container' style={{marginRight:close?30:''}}>
                            <p className='custom_modal_multiple_header_text'>{title}</p>
                            <p className='custom_modal_multiple_header_sub_text'>{title_other}</p>
                        </div>
                    ):<p className='custom_modal_multiple_header_text'>{title}</p>}
                    
                </DialogTitle>
                {close}
                <DialogContent>
                    {body}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CustomModal