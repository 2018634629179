import { ApiConfig } from "./apiConfig";

export const get_country_lists = async () => {
  try {
    const response = await ApiConfig.post(`listCountries`, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const get_state_lists = async (country_id) => {
  try {
    const response = await ApiConfig.post(`listStates`, { countryId: country_id }, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get_city_lists = async (country_id, state_id) => {
  try {
    const response = await ApiConfig.post(`listCities`, { country_id: country_id, state_id: state_id }, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get_passenger_type_lists = async () => {
  try {
    const response = await ApiConfig.post(`passengerType`, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get_titles_lists = async () => {
  try {
    const response = await ApiConfig.post(`titleType`, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get_cabin_types = async () => {
  try {
    const response = await ApiConfig.post(`getCabintype`, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};
//changed by najma on 04-10-2024
export const get_cient_types = async () => {
  try {
    const response = await ApiConfig.post(`clientType`, { headers: { "Content-Type": "multipart/form-data" } });
    return response.data;
  } catch (error) {
    throw error;
  }
};