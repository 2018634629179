
import './App.css';
import './global_style/globalstyle.css'
import UiRouters from './routers/UiRouters';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
     <ToastContainer />
     <UiRouters/>
    </>
  );
}

export default App;
