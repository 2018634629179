import { ApiConfig } from "./apiConfig";

export const user_login = async (data) => {
  try {
    const response = await ApiConfig.post(`login`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const user_logout = async () => {
  try {
    const token = localStorage.getItem('access_token');
    const headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${token}`, // Pass the Bearer token here
    };
    const response = await ApiConfig.post(`logout`, {}, { headers })
    return response;
  } catch (error) {
    throw error;
  }
};