import React, { useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './roleFormStyle.css'
import { useFormik } from 'formik';
import { get_role_by_id, role_registration, update_role_details } from '../../../../../api/rolesApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import * as Yup from "yup";
import { get_status } from '../../../../../api/statusApis';
import { useEffect } from 'react';
function RoleForm() {
    const navigate = useNavigate()
    let { role_id } = useParams();
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [status, setstatus] = useState([])

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_role") {
            setIsView(true)
        }
        getStatus()
        if (role_id) {
            get_role_by_id(role_id).then((response) => {
                const response_data = response.data
                formik.setValues({
                    ...formik.values,
                    'role_name': response_data.role_name,
                    'status': Number(response_data.role_status),
                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setstatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const formik = useFormik({
        initialValues: {
            'role_name': '',
            'status': 1,
        },
        validationSchema: Yup.object({
            status: Yup.string().required("Status is required"),
            role_name: Yup.string().required("Role is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            if (!role_id) {
                role_registration(formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.status)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error.data.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                formData.append('id', role_id);
                update_role_details(formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.status)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.message) {
                        if (error?.data?.message.hasOwnProperty(key)) {
                            const keyMessages = error?.data?.message[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }

        }
    });
    const resetFormData = () => {
        formik.setValues({
            ...formik.values,
            'role_name': "",
            'status': 1,
        });
    }

    return (
        <div className='role_form_super_container'>
            <div >
                <div className='role_form_sub_container'>
                    <div className='role_form_single_row_alignment'>
                        <IoArrowBackSharp className='role_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='role_form_title'>{role_id && isView ? "View" : role_id ? 'Update' : "Add New"} Role</p>
                    </div>
                    {!isView && (
                        <div className='role_form_single_row_alignment'>
                            {!role_id && (
                                <button className='role_form_reset_button' onClick={() => {
                                    resetFormData()
                                }}>Reset</button>
                            )}
                            <button className='role_form_save_button'
                                type="submit"
                                form="roleform">Save</button>
                        </div>
                    )}

                </div>

                <div>
                    <form id="roleform" onSubmit={formik.handleSubmit}>
                        <div className='role_form_main_container'>
                            <p className="role_form_legend">General</p>
                            <div>
                                <p className='role_form_label'>Role<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    autoFocus
                                    isDisabled={isView}
                                    name="role_name"
                                    placeholder={"Enter role name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.role_name}
                                    error={formik.touched.role_name &&
                                        Boolean(formik.errors.role_name)
                                    }
                                    helperText={
                                        formik.touched.role_name && formik.errors.role_name
                                    }
                                />
                            </div>
                            <div>
                                <p className='role_form_label'>Status<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                   isDisabled={isView}
                                    name="status"
                                    placeholder={"Select a status"}
                                    options={status}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "status",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        status.find((status) =>
                                            status.value ===
                                            formik.values.status
                                        ) || null
                                    }
                                    error={
                                        formik.touched.status &&
                                        Boolean(formik.errors.status)
                                    }
                                    helperText={
                                        formik.touched.status &&
                                        formik.errors.status
                                    }
                                />
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default RoleForm