import React, { useEffect, useState } from 'react'
import './dashboardStyle.css'
import CustomCardV1 from '../../../components/custom_card_v1/CustomCardV1'
import flight_icon from '../../../assets/flight_icon.png'
import packages_icon from '../../../assets/packages_icon.png'
import cabs_icon from '../../../assets/cabs_icon.png'
import visa_icon from '../../../assets/visa_icon.png'
import hotel_resort_icon from '../../../assets/hotel_resort_icon.png'
import passport_icon from '../../../assets/passport_icon.png'
import insurance_icon from '../../../assets/insurance_icon.png'
import train_icon from '../../../assets/train_icon.png'
import CustomHorizontalDivider from '../../../components/custom_horizontal_divider/CustomHorizontalDivider'
import { get_dashboard_datas } from '../../../api/dashboardApis'
function Dashboard() {
    const [dashboardData,setDashboardData]=useState(null)
    useEffect(()=>{
        get_dashboard_datas().then((response)=>{
            setDashboardData(response?.data?.data)
        }).catch((error)=>{
            console.log(error)
        })
    },[])
  return (
    <div>
        <div className='dashboard_main_container_0'>
            <div className='dashboard_sub_container'>
                <p className='dashboard_title_text_1'>Dashboard</p>
                {/* <p>Picker</p> */}
            </div>
            <div className='dashboard_card_listing_main_container'>
                <CustomCardV1 miniContainerColor={"#F3ABAB"} borderColor={"#F8A9A9"} backgroundColor={"#fffbfb"} image={flight_icon} text={'Flights'}>
                    <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Domestic Tickets</p>
                            <p className='dashboard_main_text'>{dashboardData?.flightIDomesticCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>International Tickets</p>
                            <p className='dashboard_main_text'>{dashboardData?.flightInternationalCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        {/* <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Tickets</p>
                            <p className='dashboard_main_text'>{dashboardData?.flightTotalCount}</p>
                        </div> */}
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#AA71A4"} borderColor={"#967492"} backgroundColor={"#f8f1f7"} image={packages_icon} text={'Packages'}>
                <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Domestic Packages</p>
                            <p className='dashboard_main_text'>{dashboardData?.packageDomesticCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>International Packages</p>
                            <p className='dashboard_main_text'>{dashboardData?.packageInternationalCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#8481E1"} borderColor={"#8481E1"} backgroundColor={"#fafaff"} image={cabs_icon} text={'Cabs'}> 
                    <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>LAS - Travels Cabs</p>
                            <p className='dashboard_main_text'>{dashboardData?.internalCabCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Other Cabs</p>
                            <p className='dashboard_main_text'>{dashboardData?.externalCabCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#669664"} borderColor={"#669664"} backgroundColor={"#f5fff4"} image={visa_icon} text={'Visa/Attestation'}>
                   <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Visa</p>
                            <p className='dashboard_main_text'>{dashboardData?.visaCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Attestation</p>
                            <p className='dashboard_main_text'>{dashboardData?.attestationCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#388181"} borderColor={"#388181"} backgroundColor={"#eefcfc"} image={hotel_resort_icon} text={'Hotel/Resort'}>
                <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Totel Bookings</p>
                            <p className='dashboard_main_text'>{dashboardData?.hotelCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                        
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#C2194F"} borderColor={"#C2194F"} backgroundColor={"#fff9fb"} image={passport_icon} text={'Passport'}>
                <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Passport</p>
                            <p className='dashboard_main_text'>{dashboardData?.passportCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#92543D"} borderColor={"#92543D"} backgroundColor={"#fff8f5"} image={insurance_icon} text={'Insurance'}>
                <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Insurance</p>
                            <p className='dashboard_main_text'>{dashboardData?.insuranceCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
                <CustomCardV1 miniContainerColor={"#DC14B1"} borderColor={"#D481E1"} backgroundColor={"#FFE1FA"} image={train_icon} text={'Train'}>
                <div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Train</p>
                            <p className='dashboard_main_text'>{dashboardData?.trainCount}</p>
                        </div>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_sub_text'>Sales Value</p>
                            <p className='dashboard_sub_text'>--</p>
                        </div>
                    </div>
                </CustomCardV1>
            </div>
        </div>
        {/* <div>
            <p>Graph</p>
        </div> */}
    </div>
  )
}

export default Dashboard