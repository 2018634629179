import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import { get_hotel_lists_with_pagination, get_hotels } from '../../../../../api/hotelApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import { RiFilterFill } from "react-icons/ri";
import dayjs from "dayjs";
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import { get_ecr_status, get_package_status, get_ticket_type } from '../../../../../api/commonLookUpApis';
import { useDispatch, useSelector } from 'react-redux';
import { resetNotificationRedirect } from '../../../../../store/commonStore';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_hotel_booking_status } from '../../../../../api/process_ecr/hotelBookingApis';

function HotelFilter({searchFilter}) {
    const filter_data_store = useSelector((state) => state.commonStore.notification_redirect)
    const dispatch = useDispatch()
    const [status, setStatus] = useState([])

    const [client, setClient] = useState([])
    const [supplier, setSupplier] = useState([])
    const [hotels, setHotels] = useState([])

    const [advanceFilter, setAdvanceFilter] = useState(false)



    const getHotels = () => {
        get_hotel_lists_with_pagination().then((response) => {
            const data = response?.data?.data?.data.map((item) => {
                return {
                    label: item.hotelName,
                    value: item.hotel_id,
                };
            });
            setHotels(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getEcrStatus = () => {
        get_hotel_booking_status().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.ESL_name,
                    value: item.ESL_id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getHotels()
        getClientList()
        getEcrStatus()
        getSupplierList()
        if (filter_data_store?.filter_data) {
            formik.setValues({ ...formik.values, 'enquiry_number': filter_data_store?.filter_data })
            searchFilter({ ...formik.values, 'enquiry_number': filter_data_store?.filter_data })
        } else {
            searchFilter({ ...formik.values, 'enquiry_number': "" })
        }
    }, [])



    const formik = useFormik({
        initialValues: {
            'enquiry_number': '',
            "hotel": '',
            'client': "",
            'supplier':"",
            'start_date': '',
            'end_date': '',
            'status': '',
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });

    // const getStatus = () => {
    //     get_status().then((response) => {
    //         const data = response.map((item) => {
    //             return {
    //                 label: item.status_name,
    //                 value: item.id,
    //             };
    //         });
    //         setStatus(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    const resetFilter = () => {
        dispatch(resetNotificationRedirect())
        formik.setValues({
            ...formik.values, ...{
                'enquiry_number': '',
                "hotel": '',
                'client': "",
                'supplier':"",
                'start_date': '',
                'end_date': '',
                'status': '',
            }
        })
    }

    return (
        <div className='users_filter_main_container'>
            <form id="packagesFilterForm" onSubmit={formik.handleSubmit}>
                <div className='package_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="enquiry_number"
                            placeholder={"Enter enquiry number"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.enquiry_number}
                        />
                    </div>

                    <div>
                        <CustomAutoCompleteDropdown
                            name="hotel"
                            placeholder={"Select a hotel "}
                            options={hotels}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "hotel",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                hotels.find((type) =>
                                    type.value ===
                                    formik.values.hotel
                                ) || null
                            }
                        />
                    </div>

                    <div>
                        <CustomAutoCompleteDropdown
                            name="client"
                            placeholder={"Select a client"}
                            options={client}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "client",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                client.find((type) =>
                                    type.value ===
                                    formik.values.client
                                ) || null
                            }

                        />
                    </div>

                    <div>
                        <CustomAutoCompleteDropdown
                            name="supplier"
                            placeholder={"Select a supplier"}
                            options={supplier}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "supplier",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                supplier.find((type) =>
                                    type.value ===
                                    formik.values.supplier
                                ) || null
                            }

                        />
                    </div>


                    <div>
                        <CustomAutoCompleteDropdown
                            name="status"
                            placeholder={"Select a status"}
                            options={status}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "status",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                status.find((type) =>
                                    type.value ===
                                    formik.values.status
                                ) || null
                            }
                        />
                    </div>

                    <div className='user_filter_actions'>
                        <button className='user_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='user_filter_action_search'
                            type="submit"
                            form="packagesFilterForm"
                        >Search</button>
                        <div>
                            <RiFilterFill className='filter_action_icons' onClick={() => {
                                setAdvanceFilter(!advanceFilter)
                            }} />
                        </div>
                    </div>

                </div>
                {advanceFilter && (
                    <>
                        <div className='flight_ticket_filter_sub_container'>
                            <div className='flight_ticket_filter_sub_date_selection_container'>
                                <div className='flight_ticket_filter_style_1'>
                                    <p className='flight_ticket_filter_legent_style_1'>Issue start to end date</p>
                                    <CustomDatePicker
                                        no_border
                                        name="start_date"
                                        onChange={(date) => {
                                            formik.setFieldValue("start_date", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.start_date &&
                                            Boolean(formik.errors.start_date)
                                        }
                                        helperText={
                                            formik.touched.start_date && formik.errors.start_date
                                        } />
                                </div>
                                <div>
                                    <p>--</p>
                                </div>
                                <div className='flight_ticket_filter_style_1'>
                                    {/* <p className='flight_ticket_filter_legent_style_2'>End date</p> */}
                                    <CustomDatePicker
                                        no_border
                                        disabled={formik.values.start_date ? false : true}
                                        minDate={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                        name="end_date"
                                        onChange={(date) => {
                                            formik.setFieldValue("end_date", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.end_date, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.end_date &&
                                            Boolean(formik.errors.end_date)
                                        }
                                        helperText={
                                            formik.touched.end_date && formik.errors.end_date
                                        } />
                                </div>
                            </div>
                        </div>

                    </>
                )}


            </form>
        </div>
    )
}

export default HotelFilter