import React from 'react'
import { useState } from 'react';
import { IoSettingsOutline } from "react-icons/io5";
import './customTableColumnSelector.css'
function CustomTableColumnSelector({columns,checkedColumns,onChange}) {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <div className='custom_table_column_selector_main_container'>
                <div className='custom_table_column_sub_container'>
                    <div className='custom_table_column_sub_setting_container_0' onClick={() => {
                        setIsVisible(!isVisible)
                    }}>
                        <div className='custom_table_column_sub_setting_container_1'>
                        <IoSettingsOutline  className='custom_table_column_setting_icon'/>
                        </div>
                       
                    </div>
                    {isVisible && (
                        <div className='custom_table_column_items_main_container'>
                            <p className='custom_table_column_items_main_container_title'>Columns</p>
                            {columns && columns.map((item,index)=>(
                                <div className='custom_table_column_items_sub_container'>
                                    <input type='checkbox' className='custom_check_box_color' checked={checkedColumns?.includes(index)}  onChange={() => onChange(index)}/>
                                    <p className='custom_table_column_items_sub_title'>{item.title}</p>
                                </div>
                                
                            ))}
                        </div>
                    )}
                </div>
            </div>
    )
}

export default CustomTableColumnSelector