import { TextField } from '@mui/material';
import React from 'react'

function CustomTextArea({ name, placeholder, type ,onChange,onBlur,value,error,helperText,rows,isDisabled,noBorder,autoFocus}) {
    const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
            fontFamily: 'Poppins',
            fontSize:'15px',
            '& fieldset': {
                borderColor:!noBorder?'#C0C0C0':'#00000000',
            },
            '&:hover fieldset': {
               borderColor:!noBorder?'#C0C0C0':'#00000000',
            },
            '&.Mui-focused fieldset': {
               borderColor:!noBorder?'#C0C0C0':'#00000000',
            },
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#272727",
        },
    };
  return (
    <div>
         <TextField
                autoFocus={autoFocus}
                style={{outline:'none'}}
                className="modified_text_area"
                disabled={isDisabled}
                name={name}
                fullWidth
                multiline
                rows={rows}
                autoComplete="off"
                size="small"
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error}
                type={type}
                helperText={helperText}
                FormHelperTextProps={{
                    style: { marginLeft: 0 },
                }}
                 variant="outlined"
                 sx={ModifiedTextField}
            />
    </div>
  )
}

export default CustomTextArea