import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
// import './PackageCommentModal.css'
import CustomTable from '../../../../../components/custom_table/CustomTable';
import { hotel_booking_review } from '../../../../../api/process_ecr/hotelBookingApis';

function HotelCommentModal({ open, handleClose, booking_id }) {
    
    const [reviewHistory, setReviewHistory] = useState([])
    useEffect(() => {
        if (booking_id) {
            const payload = {
                booking_id
            }
            hotel_booking_review(payload).then((response) => {
                if (response?.data?.status === true) {
                    setReviewHistory(response?.data?.data)
                } else {
                    setReviewHistory([])
                }

            }).catch((error) => {
                console.log(error)
            })
        }
    }, [booking_id])
    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "User",
            dataIndex: "user",
            key: "user",
        },
        {
            title: "Date Time",
            dataIndex: "date_time",
            key: "date_time",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
        },
    ]

    const datas = () => {
        const result =
            reviewHistory && reviewHistory.map((item, index) => {
                return {
                    key: index + 1,
                    user: item?.user,
                    date_time: item.created,
                    comments: item.comments,
                    status: item?.status
                };
            });
        return result;
    };
  return (
    <div>
    <CustomModal
        maxWidth={'1000px'}
        open={open}
        handleClose={handleClose}
        close={<IconButton
            aria-label="close"
            onClick={() => {
                handleClose(false)
                setReviewHistory([])
            }}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
            <div>

                {reviewHistory && reviewHistory.length > 0 ? <>
                    <CustomTable columns={Columns} rows={datas()} />
                    {/* {reviewHistory && reviewHistory.map((item, index) => (
                        <div className='review_history_item_container'>
                            <p>{index + 1}.</p>
                            <p>{item.reviewer?.name} - {item.FBRH_review_date} - {item.FBRH_review_comment} ({item.status.name})</p>
                        </div>
                    ))} */}
                </> : <p className='review_history_item_empty'>No Comments</p>}

            </div>
        }
        title={"Action History"}
    />
</div>
  )
}

export default HotelCommentModal