import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";
import './flightTicketInvoiceDetailsModal.css'
import { flight_ticket_booking_review_history } from '../../../../../api/process_ecr/flightTicketApis'
import CustomTable from '../../../../../components/custom_table/CustomTable'
import { IconButton } from '@mui/material'
function FlightTicketInvoiceDetailsModal({ selected_item, open_modal, modal_close }) {
    const [invoiceDeails,setInvoiceDetails]=useState([])
    useEffect(()=>{
        flight_ticket_booking_review_history(selected_item?.FBD_Id).then((response)=>{
            setInvoiceDetails(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    },[])
    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Invoice Number",
            dataIndex: "actions",
            key: "actions",
            action: (row) => (
                <div>
                    <p className='downoad_action_text' onClick={()=>{
                      if(row.file){
                        CommonHelpers.DownloadAssets(row.file)
                      }
                    }}>{row.invoice_number}</p>
                </div>
            ),
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Date and Time",
            dataIndex: "date_and_time",
            key: "date_and_time",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
    ];
    const datas = () => {
        const result =
            invoiceDeails &&
            invoiceDeails.map((item, index) => {
                return {
                    key: index + 1,
                    invoice_number:item?.invoice_number,
                    comment:item?.review_comment,
                    date_and_time:item?.review_date,
                    status:item?.ecrStatus,
                    file:item?.FBBD_file
                };
            });
        return result;
    };
  return (
    <div>
    <CustomModal
    maxWidth={"1000px"}
        open={open_modal}
        handleClose={(e)=>{
            modal_close(e)
        }}
        close={<IconButton
            aria-label="close"
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}
            onClick={() => {
                modal_close(false)
            }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
            <div>
                <CustomTable columns={Columns} rows={datas()} />
                {/* <div>
                    <p className='financial_level_invoice_modal_label'>Invoice Number</p>
                    <CustomTextField isDisabled={true} value={selected_item?.FBD_invoice_number}
                    />
                </div>
                <div>
                    <p className='financial_level_invoice_modal_label'>Comment</p>
                    <CustomTextArea isDisabled={true} rows={3} value={selected_item?.billingdoc[0]?.comments[0]}/>
                </div>

                <div>
                    <p className='financial_level_invoice_modal_label'>Attachments</p>
                    {selected_item?.billingdoc && selected_item?.billingdoc?.length > 0 &&  selected_item?.billingdoc?.map((item, index) => (
                        <div className='financial_level_invoice_info_attchment_item'>
                            <p>{item.FBBD_file ? item.FBBD_file : ''}</p>
                            <div>
                                <RiDownloadLine className='financial_level_invoice_download_action'  onClick={() => {
                                    if (item.file_url) {
                                        CommonHelpers.DownloadAssets(item.file_url)
                                    }
                                }} />
                            </div>
                        </div>
                    ))}

                </div> */}

                {/* <div className='financial_level_invoice_modal_form_single_row_alignment'>
                    <button className='financial_level_invoice_modal_form_reset_button' onClick={() => {
                        modal_close(false)
                    }}>Cancel</button>

                </div> */}
            </div>
        }
        title_other={'PNR - '+selected_item?.FBD_PNR}
        title={"Invoice Details"}
    />
</div>
  )
}

export default FlightTicketInvoiceDetailsModal