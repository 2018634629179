import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { get_client_lists_without_pagination } from '../../../../../../api/clientApis';
import CustomAutoCompleteDropdown from '../../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import CustomDatePicker from '../../../../../../components/custom_date_picker/CustomDatePicker';
import dayjs from "dayjs";
import * as Yup from "yup";
import { ErrorToast } from '../../../../../../toast/toasts';
function TransactionReportFilter({searchFilter}) {
    const [client, setClient] = useState([])
    useEffect(()=>{
        getClientList()
    },[])
    const formik = useFormik({
        initialValues: {
            'client': '',
            'start_date': '',
            'end_date': '',
        },
        validationSchema: Yup.object({
            client: Yup.string().required('Client is required'),
            // start_date: Yup.string().required('Start date is required'),
            // end_date: Yup.string().required('End date is required'),
          }),
        onSubmit: (values) => {
            if(values.start_date && values.end_date){
                searchFilter(values);
            }else{
                ErrorToast('Please select the transaction start date and end date.')
            } 
        }
    });
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'client': '',
                'start_date': '',
                'end_date': '',
            }
        })
        searchFilter(null)
    }
    return (
        <div>
            <form id="TransactionReportFilterForm" onSubmit={formik.handleSubmit}>
                <div className='flight_ticket_filter_sub_container'>
                    <div className='flight_ticket_filter_style'>
                        <CustomAutoCompleteDropdown
                            name="client"
                            placeholder={"Select a client"}
                            options={client}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "client",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                client.find((type) =>
                                    type.value ===
                                    formik.values.client
                                ) || null
                            }
                            error={
                                formik.touched.client &&
                                Boolean(formik.errors.client)
                            }
                            helperText={
                                formik.touched.client && formik.errors.client
                            } 
                        />
                    </div>
                    <div className='flight_ticket_filter_sub_date_selection_container'>
                    <div className='flight_ticket_filter_style_1'>
                        <p className='flight_ticket_filter_legent_style_1'>Transaction Start and End Date</p>
                        <CustomDatePicker
                            no_border
                            name="start_date"
                            onChange={(date) => {
                                formik.setFieldValue("start_date", date);
                                if(!formik.values.end_date){
                                    formik.setFieldValue("end_date", date);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                            error={
                                formik.touched.start_date &&
                                Boolean(formik.errors.start_date)
                            }
                            helperText={
                                formik.touched.start_date && formik.errors.start_date
                            } />
                    </div>
                    <div>
                        <p>--</p>
                    </div>
                    <div className='flight_ticket_filter_style_1'>
                        <CustomDatePicker
                            no_border
                            disabled={formik.values.start_date ? false : true}
                            minDate={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                            name="end_date"
                            onChange={(date) => {
                                formik.setFieldValue("end_date", date);
                            }}
                            onBlur={formik.handleBlur}
                            value={dayjs(formik.values.end_date, "DD-MM-YYYY")}
                            error={
                                formik.touched.end_date &&
                                Boolean(formik.errors.end_date)
                            }
                            helperText={
                                formik.touched.end_date && formik.errors.end_date
                            } />
                    </div>
                </div>
                <div className='flight_ticket_filter_actions'>
                        <button className='flight_ticket_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='flight_ticket_filter_action_search'
                            type="submit"
                            form="TransactionReportFilterForm"
                        >Search</button>
                    </div>
                </div>
              
            </form>
        </div>
    )
}

export default TransactionReportFilter