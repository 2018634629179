import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";
// import './InsuranceInvoiceDetailsModal.css'
import CustomTable from '../../../../../components/custom_table/CustomTable'
import { IconButton } from '@mui/material'
function InsuranceInvoiceDetailsModal({ selected_item, open_modal, modal_close }) {
    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Invoice Number",
            dataIndex: "actions",
            key: "actions",
            action: (row) => (
                <div>
                    <p className='downoad_action_text' onClick={() => {
                        if (row.file) {
                            CommonHelpers.DownloadAssets(row.file)
                        }
                    }}>{row.invoice_number}</p>
                </div>
            ),
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Date and Time",
            dataIndex: "date_and_time",
            key: "date_and_time",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
    ];
    const datas = () => {
        const result =
            selected_item?.invoice &&
            selected_item?.invoice.map((item, index) => {
                return {
                    key: index + 1,
                    invoice_number: item?.invoice_number,
                    comment: item?.invoice_comment,
                    date_and_time: item?.invoice_created,
                    status: item?.status,
                    file: item?.attachment
                };
            });
        return result;
    };

    return (
        <div>
            <CustomModal
                maxWidth={"1000px"}
                open={open_modal}
                handleClose={(e) => {
                    modal_close(e)
                }}
                close={<IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={() => {
                        modal_close(false)
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <div>
                        <CustomTable columns={Columns} rows={datas()} />
                    </div>
                }
                title_other={'File Name - ' + selected_item?.insurance_pnr}
                title={"Invoice Details"}
            />
        </div>
    )
}

export default InsuranceInvoiceDetailsModal