import React, { useEffect, useState } from 'react'
import './AginReportStyle.css'
import { Select, MenuItem, FormControl, Box, IconButton } from '@mui/material';
import CustomBottomPagination from '../../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { ApiConfig } from '../../../../../api/apiConfig';
import { ErrorToast } from '../../../../../toast/toasts';
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { get_services } from '../../../../../api/servicesApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import AgingReportTable from './AgingReportTable';
import { hasPrivilege } from '../../../../../common_privilige_methods/Privilege_Methods';
import { RiFilterFill } from 'react-icons/ri';
const AginReport = () => {
    const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItemPerPage, setTotalItemPerPage] = useState(25)
    const [reportList, setReportList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [selectedService, setSelectedService] = useState("All");

    const [customersList, setCustomersList] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState("All");

    const [grandTotal, setGrandTotal] = useState({});
    const [advanceFilter, setAdvanceFilter] = useState(false)


    //FOR GET DOWNLOAD PRIVILEGE IS TRUE OR FALSE
    const isNotDisableDownload = hasPrivilege("Reports", "Aging Report", "Download");

    const getServices = () => {
        get_services().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.ES_services_name,
                    value: item.ES_id,
                };
            });

            // Add "All" option to the data
            const updatedData = [{ label: "All", value: "All" }, ...data];

            setServicesList(updatedData);
        }).catch((error) => {
            console.log(error)
        })
    }
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            const updatedData = [{ label: "All", value: "All" }, ...data];

            setCustomersList(updatedData);
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getServices()
        getClientList()
    }, [])

    useEffect(() => {
        getAgingReport();
    }, [pageNumber, totalItemPerPage, selectedCustomer, selectedService])


    //----------------FOR HANDLE PAGINATION START
    const getAgingReport = async () => {
        const formData = new FormData();
        formData.append('page', pageNumber);
        formData.append('pageItem_count', totalItemPerPage);
        formData.append('client', selectedCustomer === "All" ? "" : selectedCustomer);
        formData.append('service', selectedService === "All" ? "" : servicesList?.find(item => item.value === selectedService)?.label);

        const response = await ApiConfig.post('/getAgingReport', formData).then((response) => {
            if (response?.data?.status && response?.data?.code) {
                setReportList(response?.data?.data)
                setGrandTotal(response?.data?.totals)
                setTotalPages(response?.data?.pagination?.last_page)
            } else {
                setReportList([])
                setGrandTotal({})
                setTotalPages(0)
                ErrorToast(response?.data?.message)
            }

        }).catch((error) => {
            let message = "Something went wrong!";
            ErrorToast(message)
        })
    }
    //----------------FOR HANDLE PAGINATION END

    // Handle customer change
    const handleCustomerChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCustomer(selectedValue);
    };

    const handleServiceChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedService(selectedValue);
    };
    const updatePagination = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const updateItemPageCount = (pageItemCount) => {
        setTotalItemPerPage(pageItemCount)
    }

    const Columns = [
        {
            title: "Sl.No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Customer Name",
            dataIndex: "client_name",
            key: "client_name",
        },
        {
            title: "0 to 15",
            dataIndex: "zero_to_15",
            key: "zero_to_15",
        },
        {
            title: "16 to 30",
            dataIndex: "sxteen_to_30",
            key: "sxteen_to_30",
        },
        {
            title: "31 to 60",
            dataIndex: "thirty_one_to_60",
            key: "thirty_one_to_60",
        },
        {
            title: "61 to 90",
            dataIndex: "sixty_one_to_90",
            key: "sixty_one_to_90",
        },
        {
            title: ">90",
            dataIndex: "ninety_plus",
            key: "ninety_plus",
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
        },
    ]

    const datas = () => {
        const result =
            reportList && reportList.map((item, index) => {
                return {
                    key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
                    client_name: item.client_name,
                    zero_to_15: item.zero_to_15,
                    sxteen_to_30: item.sxteen_to_30,
                    thirty_one_to_60: item.thirty_one_to_60,
                    sixty_one_to_90: item.sixty_one_to_90,
                    ninety_plus: item.ninety_plus,
                    total: item.total,

                };
            });
        return result;
    };

    //FOR TTITLE WITH EXCEL DOWNLOAD
    const handleExport = async () => {
        try {
            const req = {
                page: pageNumber,
                pageItem_count: totalItemPerPage,
                client: selectedCustomer === "All" ? "" : selectedCustomer,
                service: selectedService === "All" ? "" : servicesList?.find(item => item.value === selectedService)?.label
            }
            const url = `/getAgingReport`;
            const response = await ApiConfig.post(url, req);
            if (response?.data?.status && response?.data?.code) {
                const reportList = response.data.data
                const grandTotals = response.data.totals
                // Data for Excel export
                const result = reportList?.map((item) => ({
                    "Client name": item.client_name,
                    "0 to 15": item.zero_to_15,
                    "16 to 30": item.sxteen_to_30,
                    "31 to 60": item.thirty_one_to_60,
                    "61 to 90": item.sixty_one_to_90,
                    ">90": item.ninety_plus,
                    "Total": item.total,
                }));

                // Add Grand Total to the last row
                result.push({
                    "Client name": "Total",
                    "0 to 15": grandTotals.zero_to_15,
                    "16 to 30": grandTotals.sxteen_to_30,
                    "31 to 60": grandTotals.thirty_one_to_60,
                    "61 to 90": grandTotals.sixty_one_to_90,
                    ">90": grandTotals.ninety_plus,
                    "Total": grandTotals.total,
                });

                if (result.length > 0) {

                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(result, { origin: "A3" });

                    const serviceLabel = servicesList?.find(item => item.value === selectedService)?.label || '';
                    const customerLabel = customersList?.find(item => item.value === selectedCustomer)?.label || '';

                    // Add title
                    const title = `Aging Report-${selectedService === 'All' && selectedCustomer === 'All'
                        ? 'All'
                        : `${selectedCustomer !== 'All' ? customerLabel : 'All'}${selectedService !== 'All' ? ` (${serviceLabel})` : ''}`
                        }`;

                    // const title = `${`Aging`} Report ${servicesList?.find(item => item.value === selectedService)?.label}`;
                    XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: "A1" });

                    // Adjust column widths
                    worksheet["!cols"] = [
                        { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 15 }
                    ];

                    // Add styles for header
                    worksheet["!rows"] = [
                        { hpt: 30 }, // Increase height for the title row
                    ];
                    worksheet["A1"].s = {
                        font: { bold: true, sz: 16 }, // Bold and larger font
                        alignment: { horizontal: "center", vertical: "center" }
                    };

                    // Merge cells for title to span across columns
                    const columnCount = Object.keys(result[0]).length;
                    worksheet["!merges"] = [
                        { s: { r: 0, c: 0 }, e: { r: 0, c: columnCount - 1 } }
                    ];

                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                    //FILE NAME
                    const fileName = `Aging_Report_${selectedService === 'All' && selectedCustomer === 'All'
                        ? 'All'
                        : `${selectedCustomer !== 'All' ? customerLabel : 'All'}${selectedService !== 'All' ? ` (${serviceLabel})` : ''}`
                        }.xlsx`;

                    XLSX.writeFile(workbook, fileName);
                } else {
                    ErrorToast("No record found");
                }
            } else {
                ErrorToast(response?.data?.message || "No data available");
            }
        } catch (error) {
            let message = "An error occurred";
            ErrorToast(message);
        }
    };

    return (
        <div className='sales_report_main_container' style={{ width: isCollapsed ? '90vw' : '82vw' }}>

            <div className='sales_report_header'>
                <p className="sales_report_title">
                    {`Aging Report - ${selectedService === 'All' && selectedCustomer === 'All'
                        ? 'All'
                        : `${customersList?.find(item => item.value === selectedCustomer)?.label || ''} (${servicesList?.find(item => item.value === selectedService)?.label || ''})`
                        }`}
                </p>
            </div>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2, // Adjust gap for proper spacing between select boxes
                    marginBottom: 3,
                    marginTop: 3
                }}
            >
                <FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                    >
                        {customersList?.map((location) => (
                            <MenuItem key={location.value} value={location.value}>
                                {location.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                {/* advance filter for services */}
                {advanceFilter && (<FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={selectedService}
                        onChange={handleServiceChange}
                    >
                        {servicesList?.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>)
                }
                <div>
                    <RiFilterFill className='filter_action_icons' onClick={() => {
                        setAdvanceFilter(!advanceFilter)
                    }} />
                </div>
                {isNotDisableDownload && <IconButton onClick={() => {
                    handleExport()
                }}>
                    <SiMicrosoftexcel className='sales_excel_icon' />
                </IconButton>}
            </Box>
            <AgingReportTable columns={Columns} rows={datas()} grandTotal={grandTotal} />
            <CustomBottomPagination pageFrom={`sales_report`} total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />

        </div>
    )
}

export default AginReport
