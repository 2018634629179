import React from 'react'
import './flightDateChangeStyle.css'
function FlightDateChangeDetails({ datas }) {
    return (
        <div className='flight_date_change_details_main_container'>
            <div className='flight_data_change_details_main_legent_container'>
                <p className="flight_data_change_details_legend">Ticket details</p>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Booking Id</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_booking_id}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Passenger Name</p>
                    <p className='flight_date_change_details_label'>{datas?.title_name +" " + datas?.full_name}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Passenger Type</p>
                    <p className='flight_date_change_details_label'>{datas?.type_name}</p>
                </div >
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Trip Type</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.trip_type}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Ticket Type</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.ticket_type}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Cabin Name</p>
                    <p className='flight_date_change_details_label'>{datas?.cabin_name}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>New Ticket Number</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_ticketnumber}</p>
                </div>
                <div className='flight_date_change_details_sub_container' >
                    <p className='flight_date_change_details_label'>New Pnr Number</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_changed_PNR}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>New Travel Date</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_changed_travel_date}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>New Return Date</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_changed_return_date?datas?.date_change_details[0]?.FDCD_changed_return_date:'-'}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Reason</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_datechange_reason}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Comment</p>
                    <p className='flight_date_change_details_label'>{datas?.date_change_details[0]?.FDCD_comment}</p>
                </div>
            </div>
            <div className='flight_data_change_details_main_legent_container'>
            <p className="flight_data_change_details_legend">Ticket fare details</p>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Service Fee</p>
                    <p className='flight_date_change_details_label_price'>{datas?.date_change_details[0]?.Total_service_fee}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Supplier Fee</p>
                    <p className='flight_date_change_details_label_price'>{datas?.date_change_details[0]?.Total_supplier_amount}</p>
                </div>
                <div className='flight_date_change_details_sub_container'>
                    <p className='flight_date_change_details_label'>Total Customer Net</p>
                    <p className='flight_date_change_details_label_price'>{datas?.date_change_details[0]?.Total_supplier_amount + datas?.date_change_details[0]?.Total_service_fee}</p>
                </div>
            </div>
        </div>
    )
}

export default FlightDateChangeDetails