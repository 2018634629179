import { ApiConfig } from "./apiConfig";

export const get_locations_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`locations_list`,data,{ headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch (error) {
      throw error;
    }
};
export const get_locations_with_out_pagination = async () => {
    try {
      const response = await ApiConfig.post(`locations_list`);
      return response.data;
    } catch (error) {
      throw error;
    }
};
export const get_location_by_id = async (location_id) => {
    try {
      const response = await ApiConfig.post(`locations/${location_id}`);
      return response;
    } catch (error) {
      throw error;
    }
};
export const location_registation = async (data) => {
    try {
      const response = await ApiConfig.post(`locations_register`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};
export const update_location_by_id = async (location_id,data) => {
    try {
      const response = await ApiConfig.post(`location_Update/${location_id}`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const delete_location_by_id = async (location_id) => {
    try {
      const response = await ApiConfig.delete(`locations/${location_id}`);
      return response;
    } catch (error) {
      throw error;
    }
};