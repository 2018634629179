import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Table, Checkbox } from "@mui/material";

function AdvanceFilterSupplierTable({
    columns,
    rows,
    table_legend,
    selectedRowId,
    handleCheckboxChange,
}) {
    const [tableRoes, setTableRows] = useState([])
    const [hoveredRowId, setHoveredRowId] = React.useState(null);
    useEffect(() => {
        setTableRows(rows)
    }, [rows])
    const StyledTableCell = styled(TableCell)(({ isAction }) => ({
        ...(isAction && {
            textAlign: "center",
        }),
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#38818133",
            color: "#000000",
            borderBottom: "none",
            fontWeight: "600",
            fontFamily: "Jost",
            fontSize: "14px",
            padding: "15px 12px",
        },
        [`&.${tableCellClasses.body}`]: {
            maxWidth: "500px",
            overflowWrap: "break-word",
            fontSize: "14px",
            borderBottom: "none",
            fontFamily: "Jost",
            padding: "12px 15px",
            fontWeight: "400",
            overflow: "auto",
            whiteSpace: "normal",
            wordWrap: "break-word",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f2f6f6",
        },
    }));
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            {/* <StyledTableCell>Select</StyledTableCell> Checkbox column */}
                            {columns &&
                                columns.map((column) => (
                                    <StyledTableCell key={column.key} isAction={!!column.actions}>
                                        {column.title}
                                    </StyledTableCell>
                                ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {tableRoes &&
                            tableRoes.map((row) => (
                                <StyledTableRow key={row.key}>
                                    {/* <StyledTableCell>
                                        <Checkbox
                                            checked={selectedRowId == row.id ? true : false}
                                            onChange={() => {
                                                handleCheckboxChange(row)
                                            }}
                                        />
                                    </StyledTableCell> */}
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            style={{
                                                background: hoveredRowId === row.id
                                                ? '#38818133' // Light color for hover
                                                    : "f5f5f5",

                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transitions
                                            }}
                                            onClick={() => { handleCheckboxChange(row) }}

                                            onMouseEnter={() => setHoveredRowId(row.id)} // Set hovered row ID
                                            onMouseLeave={() => setHoveredRowId(null)} // Clear hovered row ID
                                        >
                                            {column.action
                                                ? column.action(row)
                                                : column.wrapper
                                                    ? column.wrapper(row)
                                                    : row[column.dataIndex] ?? " - "}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    {table_legend}
                </Table>
            </TableContainer>
            {rows && rows.length === 0 && (
                <div className="no_data_container">
                    <div className="no_data_sub_container">
                        <p>No data available</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default AdvanceFilterSupplierTable;
