import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField';
import './emailNotificationStyle.css'
import { create_new_email_with_context, delete_email_with_context, get_email_context_lists, get_email_with_context_lists } from '../../../../../../api/generalSettingApis';
import { ErrorToast, SuccessToast } from '../../../../../../toast/toasts';
import { MdDelete } from "react-icons/md";
import { IconButton } from '@mui/material';
import CustomDeleteConfirmModal from '../../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
function EmailNotification({ isSavePermission }) {
    // const [selectedId, setSelectedId] = useState("");
    // const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [ContextList, setContextList] = useState([])
    // const [emailWithContextList, setEmailWithContextList] = useState([])
    // const [checkedContext, setCheckedContext] = useState([])
    const [contextWithEmails, setContextWithEmails] = useState({
        contexts: [],
        emails: []
    })
    const [emailIds, setEmailIds] = useState([])
    useEffect(() => {
        getContextList()
        getEmailWithContextList()
    }, [])

    const getContextList = () => {
        get_email_context_lists().then((response) => {
            if (response?.code == 200) {
                let context_list = {}
                response?.data.map((item) => {
                    if (Object.keys(context_list).includes(item.module)) {
                        let reStructure = context_list[item.module]
                        context_list[item.module] = [...reStructure, item]
                    } else {
                        context_list[item.module] = [item]
                    }

                })
                const reStructuredIntoArray = []
                Object.entries(context_list).map(([key, value]) => {
                    reStructuredIntoArray.push({ "module": key, "values": value })
                })
                setContextList(reStructuredIntoArray)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getEmailWithContextList = () => {
        get_email_with_context_lists().then((response) => {
            if (response?.code == 200) {
                setContextWithEmails({ ...contextWithEmails, ['emails']: response?.data?.email ? response?.data?.email?.split(',') : [], ['contexts']: response?.data?.context ? response?.data?.context?.split(',').map(Number) : [] })
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const formik = useFormik({
        initialValues: {
            'email_id': '',
        },
        validationSchema: Yup.object({
            email_id: Yup.string().email("Invalid email address").required("Email is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            if (contextWithEmails.emails.includes(values.email_id)) {
                ErrorToast('Email already exists')
            } else {
                setContextWithEmails({ ...contextWithEmails, ['emails']: [...contextWithEmails.emails, values.email_id] })
                resetForm()
            }
            // setEmailIds([...emailIds, values.email_id])

            // const reStructure = {
            //     email: values?.email_id,
            //     context: checkedContext && checkedContext.length > 0 ? checkedContext.join(",") : ''
            // }
            // create_new_email_with_context(reStructure).then((response) => {
            //     if (response?.data.code == 200) {
            //         SuccessToast(response?.data?.message)
            //         resetForm()
            //         setCheckedContext([])
            //         getEmailWithContextList()
            //     } else {
            //         ErrorToast(response?.data?.message)
            //     }
            // }).catch((error) => {
            //     console.log(error)
            // })
        }
    });
    // const handleDeleteModalClose = (is_close) => {
    //     setDeleteConfirmation(is_close);
    //     setSelectedId(null)
    // };
    // const delete_email = () => {
    //     if (selectedId) {
    //         delete_email_with_context(selectedId).then((response) => {
    //             if (response?.data.code == 200) {
    //                 SuccessToast(response?.data?.message)
    //                 handleDeleteModalClose(false)
    //                 getEmailWithContextList()
    //             } else {
    //                 ErrorToast(response?.data?.message)
    //             }
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    //     }

    // }
    const delete_email = (index) => {
        let filterOutEmails = contextWithEmails?.emails?.filter((_, ind) => ind != index)
        setContextWithEmails({ ...contextWithEmails, ['emails']: [...filterOutEmails], ['contexts']: filterOutEmails.length != 0 ? [...contextWithEmails.contexts] : [] })
    }
    const check_and_uncheck_context = (context_id) => {
        if (contextWithEmails?.emails?.length === 0) return ErrorToast('Please add at least one email.')
        if (contextWithEmails.contexts.includes(context_id)) {
            const filterContext = contextWithEmails.contexts.filter((item) => item != context_id)
            setContextWithEmails({ ...contextWithEmails, ['contexts']: [...filterContext] })
        } else {
            setContextWithEmails({ ...contextWithEmails, ['contexts']: [...contextWithEmails.contexts, context_id] })
        }
    }
    const save_email_with_context = () => {
        const formData = new FormData();
        formData.append("email", contextWithEmails.emails);
        formData.append("context", contextWithEmails.contexts);
        create_new_email_with_context(formData).then((response) => {
            if (response?.data.code == 200) {
                SuccessToast(response?.data?.message)
            } else {
                ErrorToast(response?.data?.message)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <div>
            <div className='email_notification_action_button_container'>
                {isSavePermission && <button className='email_notification_form_save_button' onClick={() => {
                    save_email_with_context()
                }}>Save</button>}
            </div>
            <form id="email_notification_form" onSubmit={formik.handleSubmit}>
                {/* <div className='email_notification_form_main_container'>
                    <p className="email_notification_form_legend">Add Email</p>
                    <div>
                        <div>
                            <p>Email id<span className='label_required_remark'>*</span></p>
                            <div className='email_notification_sub_main_container'>
                                <div className='email_main_container'>
                                    <CustomTextField
                                        name="email_id"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email_id}
                                        error={formik.touched.email_id &&
                                            Boolean(formik.errors.email_id)
                                        }
                                        helperText={
                                            formik.touched.email_id && formik.errors.email_id
                                        }
                                    />
                                </div>

                                <div>
                                    <button className='email_notification_form_save_button'
                                        type="submit"
                                        form="email_notification_form"
                                    >Save</button>
                                </div>
                            </div>

                            <div>
                                {ContextList && ContextList.map((item) => (
                                    <div className='email_context_list_item'>
                                        <input type='checkbox' checked={checkedContext.includes(item.context_id)} onChange={() => {
                                            if (checkedContext.includes(item.context_id)) {
                                                const context = checkedContext.filter((item) => item != item.context_id)
                                                setCheckedContext(context)
                                            } else {
                                                setCheckedContext([...checkedContext, item.context_id])
                                            }
                                        }} />
                                        <p>{item?.context}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                </div>

                <div className='email_notification_form_main_container'>
                    <p className="email_notification_form_legend">Email Lists</p>
                    <div className='email_listing_container'>
                        {emailWithContextList && emailWithContextList.length>0 ?<>
                            {emailWithContextList && emailWithContextList.map((item_1) => (
                            <div className='email_list_item'>
                                <p className='email_label_text' >Email : <span>{item_1.email}</span></p>
                                {ContextList && ContextList.map((item_2) => {
                                    return (
                                        <div className='email_context_list_item'>
                                            <input type='checkbox' checked={item_1?.context.split(',')?.map(Number)?.includes(item_2.context_id)} />
                                            <p>{item_2?.context}</p>
                                        </div>
                                    )
                                })}
                                <div className='email_notification_action_container'>
                                    <IconButton>
                                        <MdDelete className='email_notification_delete_icon' onClick={()=>{
                                            setSelectedId(item_1?.email_context_map_id)
                                            setDeleteConfirmation(true)
                                        }} />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        
                        </> :<><p><p>No data available</p></p></>}
                        

                    </div>

                </div> */}

                <div className='email_notification_form_main_container'>
                    <p className="email_notification_form_legend">Email Contexts</p>
                    <div className='email_context_listing_container'>
                        {ContextList && ContextList.map((item_1) => {
                            return (
                                <div className='email_context_sub_container'>
                                    <p className="email_context_legend">{item_1?.module}</p>
                                    {item_1?.values.map((item_2) => (
                                        <div className="email_context_with_check_box">
                                            <input type='checkbox' disabled={!isSavePermission} checked={contextWithEmails?.contexts?.includes(item_2?.context_id)} onClick={() => {
                                                check_and_uncheck_context(item_2?.context_id)
                                            }} />
                                            <p>{item_2.context}</p>
                                        </div>
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='email_notification_form_main_container_without_grid'>
                    <p className="email_notification_form_legend">{!isSavePermission ? `View Email IDs` : `Configure Email IDs`} </p>
                    <div>
                        <div className='email_notification_sub_main_container_with_action'>
                            <div className='email_main_container'>
                                {isSavePermission && <CustomTextField
                                    name="email_id"
                                    type={'email'}
                                    placeholder={"Enter email"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email_id}
                                    error={formik.touched.email_id &&
                                        Boolean(formik.errors.email_id)
                                    }
                                    helperText={
                                        formik.touched.email_id && formik.errors.email_id
                                    }
                                />}
                            </div>
                            <div>
                                {isSavePermission && <IconButton
                                    type="submit"
                                    form="email_notification_form"
                                    className='email_save_icon_button'
                                ><TiTick className='email_save_icon' /></IconButton>}
                            </div>
                        </div>
                        <div className='email_listing_main_container'>
                            {contextWithEmails?.emails && contextWithEmails?.emails.map((item, index) => (
                                <div className='email_text_container'>
                                    <p>{item}</p>
                                    {isSavePermission && <IoMdClose className='email_clear_icon' onClick={() => {
                                        delete_email(index)
                                    }} />}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </form>
            {/* <CustomDeleteConfirmModal module_name={'email'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={delete_email} /> */}
        </div>
    )
}

export default EmailNotification