// Created By - Sajin S Kumar
// Date - 25-11-2024
// Pupose - Airline form - >Master settings ->General settings

import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { get_status } from '../../../../../../../api/statusApis';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomModal from '../../../../../../../components/custom_modal/CustomModal';
import CustomTextField from '../../../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import './airlineFormStyle.css'
import { create_new_airline, get_airline_by_id, update_airline_by_id } from '../../../../../../../api/generalSettingApis';
import { ErrorToast, SuccessToast } from '../../../../../../../toast/toasts';
function AirlineForm({ open, handleClose, airline_id }) {
    const [status, setstatus] = useState([])
    useEffect(() => {
        if (airline_id) {
            get_airline_by_id(airline_id).then((response) => {
                const { AirlineName, IATA_Code, status } = response?.data?.data
                formik.setValues({
                    ...formik.values, AirlineName, IATA_Code, status
                })
            }).catch((error) => {
                console.log(error)
            })
        }
        getStatus()
    }, [airline_id])
    const formik = useFormik({
        initialValues: {
            'AirlineName': '',
            'IATA_Code': '',
            'status': 1
        },
        validationSchema: Yup.object({
            AirlineName: Yup.string().required("Airline name is required"),
            IATA_Code: Yup.string().min(2, 'Character length should be min 2').max(3, 'Character length should be max 3').required("IATA code is required"),
            status: Yup.string().required("Status is required")
        }),
        onSubmit: (values) => {
            if (airline_id) {
                values['airline_id']=airline_id
                update_airline_by_id(values).then((response) => {
                    if(response?.data?.status){
                        SuccessToast(response?.data?.message)
                        handleClose(false)
                    }else{
                        ErrorToast(response?.data?.message)
                    }
                }).catch((error) => {
                    ErrorToast(error?.data?.message)
                    console.log(error)
                })
            } else {
                create_new_airline(values).then((response) => {
                    if(response?.data?.status){
                        SuccessToast(response?.data?.message)
                        handleClose(false)
                    }else{
                        ErrorToast(response?.data?.message)
                    }
                }).catch((error) => {
                    ErrorToast(error?.data?.message)
                    console.log(error)
                })
            }
        }
    })
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setstatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <CustomModal
            maxWidth={'500px'}
            open={open}
            handleClose={handleClose}
            close={<IconButton
                aria-label="close"
                onClick={() => {
                    handleClose(false)
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            > <IoIosCloseCircleOutline />
            </IconButton>}
            title={airline_id ? "Update Airline" : "Create New Airline"}
            body={
                <div>
                    <form id="alirline_form" onSubmit={formik.handleSubmit}>
                        <div>
                            <p className='airline_form_label'>Airline Name<span className='label_required_remark'>*</span></p>
                            <CustomTextField
                                autoFocus
                                // isDisabled={isView}
                                name="AirlineName"
                                placeholder={"Enter airline name"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.AirlineName}
                                error={formik.touched.AirlineName &&
                                    Boolean(formik.errors.AirlineName)
                                }
                                helperText={
                                    formik.touched.AirlineName && formik.errors.AirlineName
                                }
                            />
                        </div>
                        <div>
                            <p className='airline_form_label'>IATA Code<span className='label_required_remark'>*</span></p>
                            <CustomTextField
                                // isDisabled={isView}
                                name="IATA_Code"
                                placeholder={"Enter IATA code"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.IATA_Code}
                                error={formik.touched.IATA_Code &&
                                    Boolean(formik.errors.IATA_Code)
                                }
                                helperText={
                                    formik.touched.IATA_Code && formik.errors.IATA_Code
                                }
                            />
                        </div>
                        <div>
                            <p className='airline_form_label'>Status<span className='label_required_remark'>*</span></p>
                            <CustomAutoCompleteDropdown
                                // isDisabled={isView}
                                name="status"
                                placeholder={"Select a status"}
                                options={status}
                                onChange={(_, newValue) => {
                                    formik.setFieldValue(
                                        "status",
                                        newValue ? newValue.value : ""
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                value={
                                    status.find((type) =>
                                        type.value ==
                                        formik.values.status
                                    ) || null
                                }
                                error={formik.touched.status &&
                                    Boolean(formik.errors.status)
                                }
                                helperText={
                                    formik.touched.status && formik.errors.status
                                }
                            />
                        </div>
                        <div>
                            <div className='airline_modal_form_single_row_alignment'>
                                <button className='airline_modal_form_reset_button' onClick={() => {
                                    handleClose(false)
                                }}>Cancel</button>
                                <button
                                    className='airline_modal_form_submit_button' type='submit' form="alirline_form" >{airline_id ? 'Update' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            } />
    )
}

export default AirlineForm