import React, { useState } from 'react'
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
// import './InsuranceReusableModal.css'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { approve_or_reject_Insurance_Booking } from '../../../../../api/TravelnsuranceApi';
function InsuranceReusableModal({ selected_item, open_modal, modal_close }) {
    const [comment, setComment] = useState('')
    const [showError, setShowError] = useState(false)
    const onSubmit = () => {
        if (!comment.trim()) return setShowError(true)

        const formData = new FormData();
        formData.append('booking_id', selected_item?.id)
        formData.append('type', selected_item?.type)
        formData.append('comments', comment)

        approve_or_reject_Insurance_Booking(formData).then((response) => {
            if (response?.data?.status === true) {
                SuccessToast(response?.data?.message)
                handleClose()
            }
        }).catch((error) => {
            ErrorToast(`Something went wrong!`)
            console.log(error)
        })
    }

    const handleClose = () => {
        modal_close(false); // Close the modal
        setShowError(false); // Reset the error state
        setComment('')
    };

    //FOR GET THE MODALTITLE
    const getTitle = (type) => {
        const titles = {
            Review: "Review Travel Insurance",
            Reject: "Reject Travel Insurance",
            Verify: "Verify Travel Insurance",
            CFOReject: "Reject Travel Insurance",
            Approve: "Approve Travel Insurance",
            CancelReviewed: "Review Cancel",
            CancelApproved: "Approve Cancel",
        };
        return titles[type] || "Hold Travel Insurance";
    };
    return (
        <div>
            <CustomModal
                open={open_modal}
                handleClose={handleClose}
                body={
                    <div>
                        <div>
                            <p className='manager_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                autoFocus
                                rows={10}
                                placeholder={"Enter a comment"}
                                onChange={(e) => {
                                    setComment(e.target.value)
                                    if (showError) {
                                        setShowError(false)
                                    }
                                }}
                            />
                            {showError && (<p className='comment_error_message'>Comment is required</p>)}
                        </div>
                        <div className='manager_level_modal_form_single_row_alignment'>
                            <button className='manager_level_modal_form_reset_button' onClick={handleClose}>Cancel</button>

                            <button
                                className='manager_level_modal_form_update_button' onClick={() => {
                                    onSubmit()
                                }}>Submit</button>
                        </div>
                    </div>
                }
                title_other={'File name - ' + selected_item?.insurance_pnr}
                title={getTitle(selected_item?.type)}
            />
        </div>
    )
}

export default InsuranceReusableModal