import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './supplierFormStyle.css'
import { get_status } from '../../../../../api/statusApis';
import { get_city_lists, get_country_lists, get_state_lists } from '../../../../../api/lookupApis';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { get_supplier_by_id, supplier_registration, update_supplier, update_supplier_bankdetails } from '../../../../../api/supplierApis';
import { get_transactions } from '../../../../../api/transactionApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import SupplierBankTable from './SupplierBankTable';
import SupplierBankDetailsForm from './supplier_bank_details/SupplierBankDetailsForm';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
function SupplierForm({ is_open_modal, close_modal }) {
    const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings`
    const baseMainRouteUrl=`/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings/suppliers`
    const navigate = useNavigate()
    const location = useLocation()
    const { supplier_id } = useParams()
    const [status, setStatus] = useState([])
    const [isView, setIsView] = useState(null)
    const [isSupplier, setIsSupplier] = useState(true)
    const [bankDetails, setBankDetails] = useState([])
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_supplier") {
            setIsView(true)
        }
        getStatus()

        if (supplier_id) {
            get_supplier_by_id(supplier_id).then((response) => {
                const response_data = response
                formik.setValues({
                    ...formik.values,
                    "SD_name": response_data.SD_name?response_data.SD_name:"",
                    "SD_email": response_data.SD_email?response_data.SD_email:"",
                    "SD_phone": response_data.SD_phone?response_data.SD_phone:"",
                    "SD_address": response_data.SD_address?response_data.SD_address:"",
                    "SD_gst_num": response_data.SD_gst_num?response_data.SD_gst_num:"",
                    "SD_pan_num": response_data.SD_pan_num?response_data.SD_pan_num:"",
                    "SD_tan_num": response_data.SD_tan_num?response_data.SD_tan_num:"",
                    "SD_statusId": response_data.SD_statusId?response_data.SD_statusId:"",
                    "SD_Remarks": response_data.SD_Remarks?response_data.SD_Remarks:"",
                    "SD_pincode": response_data.SD_pincode?response_data.SD_pincode:"",
                    "SD_credit_limit_amount":response_data.SD_credit_limit_amount?response_data.SD_credit_limit_amount:0,
                    "SD_credit_limit_days":response_data.SD_credit_limit_days?response_data.SD_credit_limit_days:0,
                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [supplier_id])
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            "SD_name": "",
            "SD_email": "",
            "SD_phone": "",
            "SD_address": "",
            "SD_gst_num": "",
            "SD_pan_num": "",
            "SD_tan_num": "",
            "SD_statusId": 1,
            "SD_Remarks": "",
            "SD_pincode": "",
            "SD_credit_limit_amount":0.00,
            "SD_credit_limit_days":0,
        },
        validationSchema: Yup.object({
            SD_name: Yup.string().required("Name is required"),
            SD_email: Yup.string().email("Invalid email address").required("Email is required"),
            SD_statusId: Yup.string().required("Status is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();


            if (!supplier_id) {
                for (const key in values) {
                    if (!key.includes('SBD_')) {
                        formData.append(key, values[key]);
                    }

                }
                supplier_registration(formData).then((response) => {
                    if (response.status == 201) {
                        if(is_open_modal){
                            close_modal(false)
                        }else{
                            navigate(baseRouteUrl + `/${response.data.id}/update_supplier`)
                        }
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                for (const key in values) {
                    formData.append(key, values[key]);
                }
                formData.append("SD_id", supplier_id);
                update_supplier(formData).then((response) => {
                    SuccessToast(response.data.message)
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }
        }
    })
    const ResetForm = () => {
        formik.setValues({
            ...formik.values,
            "SD_name": "",
            "SD_email": "",
            "SD_phone": "",
            "SD_address": "",
            "SD_gst_num": "",
            "SD_pan_num": "",
            "SD_tan_num": "",
            "SD_statusId":1,
            "SD_Remarks": "",
            "SD_pincode": "",
            "SD_credit_limit_amount":0,
            "SD_credit_limit_days":0
        });
    }


    const BankDetailsOnSubmit = (values) => {
        if (Array.isArray(values)) {
            setBankDetails([...values])
        } else {
            setBankDetails([...bankDetails, values])
        }

    }

    const UpdateParticularBankDetails = (index, values) => {
        bankDetails.splice(index, 1, values)
        setBankDetails(bankDetails)
    }

    const DeleteParticularBankDetails = (index) => {
        bankDetails.splice(index, 1)
        setBankDetails([...bankDetails])
    }
    const UpdateSupplierBankDetails = () => {
        if (bankDetails.length > 0) {
            const bank_details = { "SD_id": supplier_id, "SBD_data": bankDetails }
            update_supplier_bankdetails(bank_details).then((response) => {
                SuccessToast(response.data.message)
            }).catch((error) => {
                let message = "";
                for (const key in error?.data?.errors) {
                    if (error?.data?.errors.hasOwnProperty(key)) {
                        const keyMessages = error?.data?.errors[key].map(message => message);
                        message = message.concat(keyMessages);
                    }
                }
                ErrorToast(message)
            })
        } else {
            ErrorToast("Atleast 1 bank details is required")
        }

    }
    return (
        <div className={!is_open_modal?'suppliers_form_super_container':""}>
            <div >
                <div className='suppliers_form_sub_container'>
                    {!is_open_modal && (
                        <>
                            <div className='suppliers_form_single_row_alignment'>
                                <IoArrowBackSharp className='suppliers_form_back' onClick={() => {
                                    navigate(baseMainRouteUrl)
                                }} />
                                <p className='suppliers_form_title'>{supplier_id && isView ? "View" : supplier_id ? 'Update' : "Add New"} Supplier</p>
                                <div className={isSupplier ? 'selected_supplier_menu' : 'un_selected_supplier_menu'} onClick={() => {
                                    setIsSupplier(true)
                                }}><p>Supplier</p></div>
                                <div className={!supplier_id ? 'disabled_supplier_menu' : !isSupplier ? 'selected_supplier_menu' : 'un_selected_supplier_menu'} onClick={() => {
                                    if (supplier_id) {
                                        setIsSupplier(false)
                                    }

                                }}><p>Bank Details</p></div>
                            </div>
                            {!isView && (
                                <div className='suppliers_form_single_row_alignment'>
                                    {!supplier_id && (
                                        <button className='suppliers_form_reset_button' onClick={() => {
                                            ResetForm()
                                        }}>Reset</button>
                                    )}
                                    <button className='suppliers_form_save_button'
                                        type="submit"
                                        form={isSupplier ? "supplier_details_form" : ""}
                                        onClick={() => {
                                            if (!isSupplier) {
                                                UpdateSupplierBankDetails()
                                            }
                                        }}

                                    >Save</button>
                                </div>)}
                        </>
                    )}


                </div>

                <div>
                    {isSupplier ?
                        <form id="supplier_details_form" onSubmit={formik.handleSubmit} >
                            <div className='suppliers_form_main_container'>
                                <p className="suppliers_form_legend">Details</p>
                                <div className='suppliers_form_sub_container_1'>
                                    <div>
                                        <p className='suppliers_form_label'>Supplier name<span className='label_required_remark'>*</span></p>
                                        <CustomTextField
                                            autoFocus
                                            isDisabled={isView}
                                            name="SD_name"
                                            placeholder={"Enter supplier name"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_name}
                                            error={formik.touched.SD_name &&
                                                Boolean(formik.errors.SD_name)
                                            }
                                            helperText={
                                                formik.touched.SD_name && formik.errors.SD_name
                                            }
                                        />
                                    </div>
                                    <div>
                                        <p className='suppliers_form_label'>Email<span className='label_required_remark'>*</span></p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_email"
                                            placeholder={"Enter email"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_email}
                                            error={formik.touched.SD_email &&
                                                Boolean(formik.errors.SD_email)
                                            }
                                            helperText={
                                                formik.touched.SD_email && formik.errors.SD_email
                                            }
                                        />
                                    </div>
                                    <div>
                                        <p className='suppliers_form_label'>Phone Number</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_phone"
                                            placeholder={"Enter number"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_phone}
                                            error={formik.touched.SD_phone &&
                                                Boolean(formik.errors.SD_phone)
                                            }
                                            helperText={
                                                formik.touched.SD_phone && formik.errors.SD_phone
                                            }
                                        />
                                    </div>
                                    <div>
                                        <p className='suppliers_form_label'>Address</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_address"
                                            placeholder={"Enter address"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_address}
                                            error={formik.touched.SD_address &&
                                                Boolean(formik.errors.SD_address)
                                            }
                                            helperText={
                                                formik.touched.SD_address && formik.errors.SD_address
                                            }
                                        />
                                    </div>
                                    {/* <div>
                                    <p className='clients_form_label'>Country</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="country_id"
                                        placeholder={"Select a country"}
                                        options={countryLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "country_id",
                                                newValue ? newValue.value : ""
                                            );
                                            getStateLists(newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            countryLists.find((country) =>
                                                country.value ===
                                                formik.values.country_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.country_id &&
                                            Boolean(formik.errors.country_id)
                                        }
                                        helperText={
                                            formik.touched.country_id &&
                                            formik.errors.country_id
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>State</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="state_id"
                                        placeholder={"Select a state"}
                                        options={stateLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "state_id",
                                                newValue ? newValue.value : ""
                                            );
                                            getCityLists(formik.values.country_id, newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            stateLists.find((state) =>
                                                state.value ===
                                                formik.values.state_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.state_id &&
                                            Boolean(formik.errors.state_id)
                                        }
                                        helperText={
                                            formik.touched.state_id &&
                                            formik.errors.state_id
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>City</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="cities_id"
                                        placeholder={"Select a city"}
                                        options={cityLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "cities_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            cityLists.find((city) =>
                                                city.value ===
                                                formik.values.cities_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.cities_id &&
                                            Boolean(formik.errors.cities_id)
                                        }
                                        helperText={
                                            formik.touched.cities_id &&
                                            formik.errors.cities_id
                                        }

                                    />
                                </div> */}

                                    <div>
                                        <p className='suppliers_form_label'>Postal Code</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_pincode"
                                            placeholder={"Enter postal code"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_pincode}
                                            error={formik.touched.SD_pincode &&
                                                Boolean(formik.errors.SD_pincode)
                                            }
                                            helperText={
                                                formik.touched.SD_pincode && formik.errors.SD_pincode
                                            }
                                        />
                                    </div>

                                    <div>
                                        <p className='suppliers_form_label'>Gst Number</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_gst_num"
                                            placeholder={"Enter gst number"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_gst_num}
                                            error={formik.touched.SD_gst_num &&
                                                Boolean(formik.errors.SD_gst_num)
                                            }
                                            helperText={
                                                formik.touched.SD_gst_num && formik.errors.SD_gst_num
                                            }
                                        />
                                    </div>

                                    <div>
                                        <p className='suppliers_form_label'>Pan Number</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_pan_num"
                                            placeholder={"Enter pan number"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_pan_num}
                                            error={formik.touched.SD_pan_num &&
                                                Boolean(formik.errors.SD_pan_num)
                                            }
                                            helperText={
                                                formik.touched.SD_pan_num && formik.errors.SD_pan_num
                                            }
                                        />
                                    </div>
                                    <div>
                                        <p className='suppliers_form_label'>Tan Number</p>
                                        <CustomTextField
                                            isDisabled={isView}
                                            name="SD_tan_num"
                                            placeholder={"Enter tan number"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.SD_tan_num}
                                            error={formik.touched.SD_tan_num &&
                                                Boolean(formik.errors.SD_tan_num)
                                            }
                                            helperText={
                                                formik.touched.SD_tan_num && formik.errors.SD_tan_num
                                            }
                                        />
                                    </div>
                                    <div>
                                    <p className='clients_form_label'>Credit Limit</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="SD_credit_limit_amount"
                                        type={'number'}
                                        inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                                        placeholder={"Enter credit limit amount"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.SD_credit_limit_amount}
                                        error={formik.touched.SD_credit_limit_amount &&
                                            Boolean(formik.errors.SD_credit_limit_amount)
                                        }
                                        helperText={
                                            formik.touched.SD_credit_limit_amount && formik.errors.SD_credit_limit_amount
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Credit Period (Days)</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="SD_credit_limit_days"
                                        type={'number'}
                                        inputProps={{ inputProps: { min: 0 , max:365} }}
                                        placeholder={"Enter credit limit days"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.SD_credit_limit_days}
                                        error={formik.touched.SD_credit_limit_days &&
                                            Boolean(formik.errors.SD_credit_limit_days)
                                        }
                                        helperText={
                                            formik.touched.SD_credit_limit_days && formik.errors.SD_credit_limit_days
                                        }
                                    />
                                </div>
                                    <div>
                                        <p className='clients_form_label'>Status<span className='label_required_remark'>*</span></p>
                                        <CustomAutoCompleteDropdown
                                            isDisabled={isView}
                                            name="SD_statusId"
                                            placeholder={"Select a status"}
                                            options={status}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue(
                                                    "SD_statusId",
                                                    newValue ? newValue.value : ""
                                                );

                                            }}
                                            onBlur={formik.handleBlur}
                                            value={
                                                status.find((status) =>
                                                    status.value ===
                                                    formik.values.SD_statusId
                                                ) || null
                                            }
                                            error={
                                                formik.touched.SD_statusId &&
                                                Boolean(formik.errors.SD_statusId)
                                            }
                                            helperText={
                                                formik.touched.SD_statusId &&
                                                formik.errors.SD_statusId
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='suppliers_form_sub_container_2'>
                                    <p className='suppliers_form_label'>Remarks</p>
                                    <CustomTextArea
                                        isDisabled={isView}
                                        rows={4}
                                        name="SD_Remarks"
                                        placeholder={"Enter a remark"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.SD_Remarks}
                                        error={formik.touched.SD_Remarks &&
                                            Boolean(formik.errors.SD_Remarks)
                                        }
                                        helperText={
                                            formik.touched.SD_Remarks && formik.errors.SD_Remarks
                                        }
                                    />
                                </div>
                            </div>
                        </form> : <SupplierBankDetailsForm bankDetails={bankDetails} onUpdateBankDetails={BankDetailsOnSubmit} UpdateParticularBankDetails={UpdateParticularBankDetails} DeleteParticularBankDetails={DeleteParticularBankDetails} />}

                </div>
                {is_open_modal && (
                    <div className='suppliers_form_single_row_alignment_modal'>
                        <button className='suppliers_form_reset_button' onClick={() => {
                            close_modal(false)
                        }}>Cancel</button>
                        <button className='suppliers_form_save_button'
                            type="submit"
                            form={isSupplier ? "supplier_details_form" : ""}
                        >Save</button>
                    </div>
                )}

            </div>
        </div>
    )
}

export default SupplierForm