import React, { useEffect, useState } from 'react'
import './flightCancelFormStyle.css'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import FlightTicketCancelConfirmation from './flight_ticket_cancel_confirm/FlightTicketCancelConfirmation'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import { IconButton } from '@mui/material'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { ErrorToast } from '../../../../../toast/toasts'
import CustomTableColumnWithEditableRow from '../../../../../components/custom_table_column_with_editable_row/CustomTableColumnWithEditableRow'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker'
import { cancel_ticket_reason } from '../../../../../constant/reasons'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { flight_ticket_cancellation_reasons } from '../../../../../api/process_ecr/flightTicketApis'
function FlightCancelForm({ details, onClose }) {
    const [checkItems, setCheckedItems] = useState([])
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [passengerDetails, setPassengerDetails] = useState([])
    const [cancelTicketReasons,setCancelTicketReasons]=useState([])
    const [passengerCounts, setPassengerCounts] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })
    const [amount, setAmount] = useState({
        totalAmount: 0,
        cancellationFee: 0
    })
    const [formDetails, setFormDetails] = useState(null)
    const [basicDetails, setBasicDetails] = useState({
        cancellation_reason_id:null,
        cancellation_reason:'',
        remark: '',
        date_of_cancellation: ''
    })
    
    useEffect(() => {
        get_passenger_cancel_reasons()
        passengerCount()
        setPassengerDetails(datas(details?.passanges))
    }, [details])
    const get_passenger_cancel_reasons=()=>{
        flight_ticket_cancellation_reasons().then((response)=>{
            const reStructureResponse=response.data.map((item)=>{return {'value':item.CRL_id,'label':item.CRL_reason}})
            setCancelTicketReasons(reStructureResponse)
        }).catch((error)=>{
            console.log(error)
        })
    }
    const Columns = [
        {
            title: "check",
            dataIndex: "header_checkbox",
            key: "header_checkbox",
            action: (row) => (
                <input type='checkbox' className='custom_check_box_color' checked={checkItems?.length == passengerDetails?.length ? true : checkItems?.includes(row?.key)} onChange={() => {
                    if (Array.isArray(row)) {
                        if (checkItems.length == passengerDetails.length) {
                            setCheckedItems([])
                            setAmount({ ...amount, ['totalAmount']: 0, ['cancellationFee']: 0 })
                        } else {
                            let totalAmount = 0
                            let cancellationFee = 0
                            setCheckedItems(row.map((item) => item.key))
                            row.map((item) => {
                                const { airline_supplier_fee, gst, las_service_fee, customer_net } = item
                                totalAmount = Number(totalAmount) + Number(customer_net)
                                cancellationFee = Number(cancellationFee) + Number(airline_supplier_fee) + Number(gst) + Number(las_service_fee)
                            })
                            setAmount({ ...amount, ['totalAmount']: totalAmount?.toFixed(2), ['cancellationFee']: cancellationFee?.toFixed(2) })

                        }
                    } else {
                        if (checkItems.includes(row.key)) {
                            setCheckedItems(checkItems.filter((item) => item !== row.key))
                            const { airline_supplier_fee, gst, las_service_fee } = row
                            setAmount({ ...amount, ['totalAmount']: (Number(amount.totalAmount) - Number(row.customer_net))?.toFixed(2), ['cancellationFee']: (Number(amount.cancellationFee) - (Number(airline_supplier_fee) + Number(gst) + Number(las_service_fee))).toFixed(2) })
                        } else {
                            setCheckedItems([...checkItems, row.key])
                            const { airline_supplier_fee, gst, las_service_fee } = row
                            setAmount({ ...amount, ['totalAmount']: (Number(amount.totalAmount) + Number(row.customer_net))?.toFixed(2), ['cancellationFee']: (Number(amount.cancellationFee) + (Number(airline_supplier_fee) + Number(gst) + Number(las_service_fee))).toFixed(2) })
                        }
                    }
                }} />
            ),

        },
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "Passenger Type",
            dataIndex: "passenger_type_name",
            key: "passenger_type_name",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Current Supplier Fee",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Current Service Fee",
            dataIndex: "service_charge",
            key: "service_charge",
        },
        {
            title: "Current Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Cancel Supplier Fee",
            dataIndex: "airline_supplier_fee",
            key: "airline_supplier_fee",
            editableField: (row) => (
                <div>
                    {checkItems.includes(row.key) ? (
                        <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net),step:'.01' } }} defaultValue={row.airline_supplier_fee}
                            onBlur={(e) => {
                                updateIsEditableFieldValue(row.key - 1, 'number', 'airline_supplier_fee', e.target.value)

                            }}
                        />
                    ) : <p>{row.airline_supplier_fee}</p>}


                </div>
            )
        },
        {
            title: "Cancel Service Fee",
            dataIndex: "las_service_fee",
            key: "las_service_fee",
            editableField: (row) => (
                <div>
                    {checkItems.includes(row.key) ? (
                        <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net),step:'.01'  } }} defaultValue={row.las_service_fee}
                            onBlur={(e) => {
                                updateIsEditableFieldValue(row.key - 1, 'number', 'las_service_fee', e.target.value)
                            }}
                        />
                    ) : <p>{row.las_service_fee}</p>}
                </div>
            )
        },
        // {
        //     title: "GST",
        //     dataIndex: "gst",
        //     key: "gst",
        //     editableField: (row) => (
        //         <div>
        //             {checkItems.includes(row.key) ? (
        //                 <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net) } }} defaultValue={row.gst}
        //                     onBlur={(e) => {
        //                         updateIsEditableFieldValue(row.key - 1, 'number', 'gst', e.target.value)
        //                     }}
        //                 />
        //             ) : <p>{row.gst}</p>}

        //         </div>
        //     )
        // },
    ]

    const datas = (passengerDetails) => {
        const result =
            passengerDetails && passengerDetails.filter((item) => item.FBPD_cancel_status !== 1).map((item, index) => {
                let Total_Customer_net = (item?.date_changes?.length > 0 && item?.date_changes[0]['Total_service_fee'] ? item?.date_changes[0]['Total_service_fee'] : 0) + (item?.date_changes?.length > 0 && item?.date_changes[0]['Total_supplier_amount'] ? item?.date_changes[0]['Total_supplier_amount'] : 0)
  
                return {
                    key: index + 1,
                    id: item.FBPD_id,
                    title_name: item.passengerTitleName,
                    name: item.FBPD_FullName,
                    passenger_type: item.FBPD_Passanger_type,
                    passenger_type_name: item.passengerTypeName,
                    ticket_number: item?.date_changes?.length > 0 && item?.date_changes[0]['FDCD_ticketnumber'] ? item?.date_changes[0]['FDCD_ticketnumber'] : item.FBPD_ticket_number,
                    supplier_amount: item?.date_changes?.length > 0 && item?.date_changes[0]['Total_supplier_amount'] ? item?.date_changes[0]['Total_supplier_amount'] : item.FBPD_supplier_amount,
                    service_charge: item?.date_changes?.length > 0 && item?.date_changes[0]['Total_service_fee'] ? item?.date_changes[0]['Total_service_fee'] : item.FBPD_service_fee,
                    customer_net: Total_Customer_net ? Total_Customer_net : item.FBPD_customer_net,
                    airline_supplier_fee: 0,
                    las_service_fee: 0,
                    gst: 0,
                    isEditable: false
                };
            });
        return result;
    };
    const passengerCount = () => {
        let adultCount = 0;
        let childCount = 0;
        let infantCount = 0;
        let passengerNet = 0
        details?.passanges?.filter((item) => item.FBPD_cancel_status !== 1)?.forEach((item) => {
            passengerNet = passengerNet + Number(item.FBPD_customer_net)
            if (item.FBPD_Passanger_type === 1) adultCount++;
            else if (item.FBPD_Passanger_type === 2) childCount++;
            else if (item.FBPD_Passanger_type === 3) infantCount++;

        });

        setPassengerCounts({
            adult: adultCount,
            child: childCount,
            infants: infantCount,
        });
    }
   
    const handleCancelModalClose = (isclose) => {
        setCancelConfirmModal(isclose)
    }
    const handleCancelModalTotalClose = (isclose) => {
        onClose(isclose)
        setCancelConfirmModal(isclose)
    }

    const updateIsEditableFieldValue = (index, type, key, value) => {
        const passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails))
        passengerDetailsCopy[index][key] = type === "number" ? Number(value) : value
        UpdateRefundAmount(passengerDetailsCopy)
        setPassengerDetails(passengerDetailsCopy)
    }


    const UpdateRefundAmount = (passengerDetails) => {
        let CancellationAmount = 0
        passengerDetails && passengerDetails.forEach((item) => {
            CancellationAmount = CancellationAmount + item.airline_supplier_fee + item.las_service_fee + item.gst
        });
        setAmount({ ...amount, ['cancellationFee']: CancellationAmount?.toFixed(2) })
    }
    const onProceed = () => {
        let refundAmount = (amount.totalAmount - amount.cancellationFee)?.toFixed(2)

        if (refundAmount < 0) return ErrorToast('Please check the calculation')
        if (checkItems.length === 0) return ErrorToast('Please select passenger')
        if (!basicDetails.remark || !basicDetails.date_of_cancellation || !basicDetails.cancellation_reason_id) return ErrorToast(!basicDetails.remark ? "Remark is required." :!basicDetails.date_of_cancellation ? "Date Of Cancellation is required.":'Cancellation reason is required.')
        if (checkItems.length === 0) {
            ErrorToast('Please select passenger')
        } else {
            const checkedPassengers = passengerDetails.filter((item) => checkItems.includes(item.key))
            setFormDetails({
                passengerData: checkedPassengers, basicDetails: { ...basicDetails, 'booking_id': details.FBD_Id, 'pnr_number': details.FBD_PNR },
                amount: { totalAmount: amount.totalAmount, cancellationFee: amount.cancellationFee, refundAmount: refundAmount }
            })
            setCancelConfirmModal(true)
        }

    }
    return (
        <div>
            <CustomModal
                maxWidth={"1500px"}
                open={cancelConfirmModal}
                handleClose={handleCancelModalClose}
                close={<IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={() => {
                        handleCancelModalClose(false)
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <FlightTicketCancelConfirmation
                        formDetails={formDetails}
                        amount={amount}
                        handleCloseCancelConfirmModal={handleCancelModalClose}
                        handleCloseAllConfirmModal={handleCancelModalTotalClose}
                    />
                }
                title={"Cancel Ticket"}
            />
            <>
                <div className='flight_cancel_form_main_container'>
                    <p className="flight_cancel_form_legend">Passenger Details</p>
                    <div >
                        <div className='flight_cancel_passenger_count'>
                            <p>Adult : {passengerCounts.adult}</p>
                            <p>Child : {passengerCounts.child}</p>
                            <p>Infants : {passengerCounts.infants}</p>
                        </div>
                        <CustomTableColumnWithEditableRow columns={Columns} rows={passengerDetails} checkItems={checkItems} no_border={true} />
                    </div>
                </div>

                <div className='flight_cancel_form_main_container'>
                    <p className="flight_cancel_form_legend">Cancellation Details</p>
                    <div className='flight_cancel_form_sub_container'>
                        <div >
                            <div className='flight_cancel_form_row_alignment' style={{ maxWidth: '500px' }}>
                                <p className='flight_cancel_form_label_text'>Date Of Cancellation <span className='flight_cancel_form_label_required'>*</span></p>
                                <CustomDatePicker
                                    name="airline_cancellation_date"
                                    onChange={(e) => {
                                        setBasicDetails({ ...basicDetails, ['date_of_cancellation']: e })
                                    }}
                                />
                            </div>
                            <div className='flight_cancel_form_row_alignment'>
                                <p className='flight_cancel_form_label_total_text'>No Of Passengers</p>
                                <p className='flight_cancel_form_label_total_text'>{checkItems.length}</p>
                            </div>
                            <div className='flight_cancel_form_row_alignment'>
                                <p className='flight_cancel_form_label_total_text'>Total Amount</p>
                                <p className='flight_cancel_form_label_total_text'>{amount.totalAmount}</p>
                            </div>
                            <div className='flight_cancel_form_row_alignment'>
                                <p className='flight_cancel_form_label_total_text'>Total Cancellation Fee</p>
                                <p className='flight_cancel_form_label_total_text'>{amount.cancellationFee}</p>
                            </div>
                            <div className='flight_cancel_form_row_alignment'>
                                <p className='flight_cancel_form_label_total_text'>Refund Amount</p>
                                <p className='flight_cancel_form_label_total_text'>{(amount.totalAmount - amount.cancellationFee)?.toFixed(2)}</p>
                            </div>

                        </div>
                        <div>
                            <div>
                                <p>Cancel Reason <span className='flight_cancel_form_label_required'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    name=""
                                    placeholder={"Select reason"}
                                    options={cancelTicketReasons}
                                    onChange={(event, newValue) => {
                                        setBasicDetails({ ...basicDetails, ['cancellation_reason_id']:newValue?.value,['cancellation_reason']:newValue?.label })
                                    }}
                                />
                            </div>
                            <div className='flight_cancel_form_remark_container'>
                                <p className="flight_cancel_form_remark_legend">Remarks <span className='flight_cancel_form_label_required'>*</span></p>
                                <CustomTextArea
                                    value={basicDetails.remark}
                                    noBorder={true}
                                    rows={6}
                                    name="narration"
                                    placeholder={"Enter a remarks"}
                                    onChange={(e) => {
                                        setBasicDetails({ ...basicDetails, ['remark']: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flight_cancel_form_single_row_alignment'>
                    <button className='flight_cancel_form_reset_button' onClick={() => {
                        onClose(false)
                    }} >Close</button>
                    <button
                        className='flight_cancel_form_save_button' onClick={() => {
                            onProceed()
                        }}>Proceed</button>
                </div>
            </>


        </div>
    )
}

export default FlightCancelForm