import { ApiConfig } from "../apiConfig";

export const get_hotel_bookings_list_with_pagination = async (data) => {
    try {
        const response = await ApiConfig.post(`getHotelBookingsList`, data,{ headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};



export const get_hotel_bookings_details_by_id = async (data) => {
    try {
        const response = await ApiConfig.post(`getHotelBookingDetails`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const create_new_hotel_booking = async (data) => {
    try {
        const response = await ApiConfig.post(`addHotelBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const update_hotel_booking = async (data) => {
    try {
        const response = await ApiConfig.post(`updateHotelBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const hotel_booking_level_actions = async (data) => {
    try {
        const response = await ApiConfig.post(`changeHotelBookingStatus`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const hotel_booking_review = async (data) => {
    try {
        const response = await ApiConfig.post(`getHotelBookingHistory`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const delete_hotel_booking = async (data) => {
    try {
        const response = await ApiConfig.post(`deleteHotelBooking`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const get_hotel_booking_status = async (data) => {
    try {
        const response = await ApiConfig.post(`getPackageStatusList`, data);
        return response;
    } catch (error) {
        throw error;
    }
};