import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import './flightTicketPassengerForm.css'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { SuccessToast } from '../../../../../toast/toasts';
function FlightTicketPassengerForm({ totalPassenger, passengerOtherCounts, existingPassengerDetails, onClose, setPassengerDetails, updatePassengerDetails }) {
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [cabinType, setCabinType] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [showSuccessMessage,setShowSuccessMessage]=useState(false)
    useEffect(() => {
        getPassengerList()
        getPassengerTitle()
        getPassengerCabin()
        if (existingPassengerDetails) {
            formik.setValues({
                ...formik.values, ...{
                    'FBPD_Passanger_type': existingPassengerDetails.type,
                    'FBPD_title_id': existingPassengerDetails.title,
                    'FBPD_FullName': existingPassengerDetails.full_name,
                    'FBPD_ticket_number': existingPassengerDetails.ticket_number,
                    'FBPD_base_fare': existingPassengerDetails.base_fare,
                    'FBPD_jn_tax': existingPassengerDetails.jn_tax,
                    'FBPD_yq_tax': existingPassengerDetails.yq_tax,
                    'FBPD_oc_tax': existingPassengerDetails.oc_tax,
                    'FBPD_other_tax': existingPassengerDetails.other_tax,
                    'FBPD_supplier_amount': existingPassengerDetails.supplier_amount,
                    'FBPD_customer_net': existingPassengerDetails.customer_net,
                    'FBPD_service_fee': existingPassengerDetails.service_fee,
                    'FBPD_cabin_type': existingPassengerDetails.cabin_id
                }
            })
        }
        setTotalCount(passengerOtherCounts?.adult + passengerOtherCounts?.child + passengerOtherCounts?.infants)
    }, [existingPassengerDetails])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            let filterAdults = []
            let filterChild = []
            let filterInfants = []
            if (passengerOtherCounts?.adult > 0) {
                filterAdults = response?.data?.filter((item) => item.PTL_name === 'Adult')
            }
            if (passengerOtherCounts?.child > 0) {
                filterChild = response?.data?.filter((item) => item.PTL_name === 'Child')
            }
            if (passengerOtherCounts?.infants > 0) {
                filterInfants = response?.data?.filter((item) => item.PTL_name === 'Infant')
            }
            const data = [...filterAdults, ...filterChild, ...filterInfants].map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }


    const getPassengerCabin = () => {
        get_cabin_types().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CTL_name,
                    value: item.CTL_id,
                };
            });
            setCabinType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            'FBPD_Passanger_type': '',
            'FBPD_title_id': '',
            'FBPD_FullName': '',
            'FBPD_ticket_number': '',
            'FBPD_base_fare': 0,
            'FBPD_jn_tax': 0,
            'FBPD_yq_tax': 0,
            'FBPD_oc_tax': 0,
            'FBPD_other_tax': 0,
            'FBPD_supplier_amount': 0,
            'FBPD_customer_net': 0,
            'FBPD_service_fee': 0,
            'FBPD_cabin_type': ''
        },
        validationSchema: Yup.object({
            FBPD_Passanger_type: Yup.string()
                .required("Passenger type is required")
                .test(
                    'check-passenger-type',
                    'Passenger is required',
                    function (value) {
                        const errorMessage = (() => {
                            const { FBPD_Passanger_type } = this.parent;
                            const passengerCount = totalPassenger.filter(item => item.FBPD_Passanger_type == FBPD_Passanger_type).length;
                            const limit = passengerOtherCounts[FBPD_Passanger_type === '1' ? 'adult' : FBPD_Passanger_type === '2' ? 'child' : 'infants'];
                            // Determine the error message based on the condition
                            if (!FBPD_Passanger_type) {
                                return "Passenger type is required";
                            }

                            if (passengerCount === 0 && limit !== 0) {
                                return null
                            } else if (limit === 0) {
                                return "The passenger count limit is set to 0.";
                            } else if (passengerCount == limit && existingPassengerDetails?.type == FBPD_Passanger_type) {
                                return null
                            } else if (passengerCount >= limit) {
                                return "The number of passengers exceeds the allowed limit."
                            }

                            // Return null if no error
                            return null;
                        })();

                        // Return true if no error message is set, otherwise return false
                        return errorMessage === null ? true : this.createError({ message: errorMessage });
                    }
                ),
            FBPD_title_id: Yup.string().required("Title is required"),
            FBPD_FullName: Yup.string().required("Full name is required"),
            FBPD_ticket_number: Yup.string().required("Ticket number is required"),
            FBPD_cabin_type: Yup.string().required("Cabin type is required")
        }),
        onSubmit: (values, { resetForm }) => {
            let value = JSON.parse(JSON.stringify(values))
            value['passenger_type_name'] = passengerType.find((type) =>
                type.value ===
                formik.values.type
            )?.label
            if (existingPassengerDetails) {
                value['index'] = existingPassengerDetails.index
                updatePassengerDetails(value)
            } else {
                setPassengerDetails(value)
            }
            if (existingPassengerDetails) {
                setTotalCount(0)
                onClose(false)
            } else {
                showSuccessMessageTimeOut()
                SuccessToast("Sucessfully added new passenger")
                resetForm()
            }

        }
    })
    
    function showSuccessMessageTimeOut(){
        setShowSuccessMessage(true)
        setTimeout(()=>{
            setShowSuccessMessage(false)
        },2000)
    }
    return (
        <div className='flight_ticket_passenger_form_super_container'>
            <form id="flightTicketPassengerForm" onSubmit={formik.handleSubmit}>
                {/* <div className='passenger_count_exceeded_message'>
                    {!existingPassengerDetails && totalPassenger.length === totalCount && (
                        <p>The passenger count has been exceeded</p>
                    )}
                </div> */}
                {showSuccessMessage && (
                    <div className='passenger_added_success_message'>
                    <p>Sucessfully added new passenger</p>
                </div>
                )}
                <div className='flight_ticket_passenger_form_main_container'>
                    <p className="flight_ticket_passenger_form_legend">Passenger Details</p>
                    <div>
                        <div className='flight_ticket_passenger_form_sub_container_0'>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Title<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    autoFocus={true}
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_title_id"
                                    placeholder={"Select a title"}
                                    options={passengerTitle}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "FBPD_title_id",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        passengerTitle.find((type) =>
                                            type.value ===
                                            formik.values.FBPD_title_id
                                        ) || null
                                    }
                                    error={
                                        formik.touched.FBPD_title_id &&
                                        Boolean(formik.errors.FBPD_title_id)
                                    }
                                    helperText={
                                        formik.touched.FBPD_title_id &&
                                        formik.errors.FBPD_title_id
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Full Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_FullName"
                                    placeholder={"Enter full name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_FullName}
                                    error={formik.touched.FBPD_FullName &&
                                        Boolean(formik.errors.FBPD_FullName)
                                    }
                                    helperText={
                                        formik.touched.FBPD_FullName && formik.errors.FBPD_FullName
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Passenger Type<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_Passanger_type"
                                    placeholder={"Select a passenger type"}
                                    options={passengerType}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "FBPD_Passanger_type",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        passengerType.find((type) =>
                                            type.value ===
                                            formik.values.FBPD_Passanger_type
                                        ) || null
                                    }
                                    error={
                                        formik.touched.FBPD_Passanger_type &&
                                        Boolean(formik.errors.FBPD_Passanger_type)
                                    }
                                    helperText={
                                        formik.touched.FBPD_Passanger_type &&
                                        formik.errors.FBPD_Passanger_type
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Cabin Type<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_cabin_type"
                                    placeholder={"Select a passenger type"}
                                    options={cabinType}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "FBPD_cabin_type",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        cabinType.find((type) =>
                                            type.value ===
                                            formik.values.FBPD_cabin_type
                                        ) || null
                                    }
                                    error={
                                        formik.touched.FBPD_cabin_type &&
                                        Boolean(formik.errors.FBPD_cabin_type)
                                    }
                                    helperText={
                                        formik.touched.FBPD_cabin_type &&
                                        formik.errors.FBPD_cabin_type
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Ticket Number<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_ticket_number"
                                    placeholder={"Enter ticket number"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_ticket_number}
                                    error={formik.touched.FBPD_ticket_number &&
                                        Boolean(formik.errors.FBPD_ticket_number)
                                    }
                                    helperText={
                                        formik.touched.FBPD_ticket_number && formik.errors.FBPD_ticket_number
                                    }
                                />
                            </div>

                        </div>

                        <div className='flight_ticket_passenger_form_sub_container_0'>
                            <div>
                                <p className='flight_ticket_passenger_form_label'>Base Fare</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    type={'number'}
                                    name="FBPD_base_fare"
                                    placeholder={"Enter base fare"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_jn_tax) +
                                            Number(formik.values.FBPD_yq_tax) +
                                            Number(formik.values.FBPD_oc_tax) +
                                            Number(formik.values.FBPD_other_tax)
                                        formik.setFieldValue("FBPD_supplier_amount", amount.toFixed(2));
                                        formik.setFieldValue("FBPD_customer_net", (amount + formik.values.FBPD_service_fee).toFixed(2))

                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_base_fare}
                                    error={formik.touched.FBPD_base_fare &&
                                        Boolean(formik.errors.FBPD_base_fare)
                                    }
                                    helperText={
                                        formik.touched.FBPD_base_fare && formik.errors.FBPD_base_fare
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>JN Tax</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="FBPD_jn_tax"
                                    placeholder={"Enter jn tax"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_base_fare) +
                                            Number(formik.values.FBPD_yq_tax) +
                                            Number(formik.values.FBPD_oc_tax) +
                                            Number(formik.values.FBPD_other_tax)
                                        formik.setFieldValue("FBPD_supplier_amount", amount.toFixed(2));
                                        formik.setFieldValue("FBPD_customer_net", (amount + formik.values.FBPD_service_fee).toFixed(2))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_jn_tax}
                                    error={formik.touched.FBPD_jn_tax &&
                                        Boolean(formik.errors.FBPD_jn_tax)
                                    }
                                    helperText={
                                        formik.touched.FBPD_jn_tax && formik.errors.FBPD_jn_tax
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>YQ Tax</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="FBPD_yq_tax"
                                    placeholder={"Enter yq tax"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_base_fare) +
                                            Number(formik.values.FBPD_jn_tax) +
                                            Number(formik.values.FBPD_oc_tax) +
                                            Number(formik.values.FBPD_other_tax)
                                        formik.setFieldValue("FBPD_supplier_amount", amount.toFixed(2));
                                        formik.setFieldValue("FBPD_customer_net", (amount + formik.values.FBPD_service_fee).toFixed(2))

                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_yq_tax}
                                    error={formik.touched.FBPD_yq_tax &&
                                        Boolean(formik.errors.FBPD_yq_tax)
                                    }
                                    helperText={
                                        formik.touched.FBPD_yq_tax && formik.errors.FBPD_yq_tax
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>OC Tax</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="FBPD_oc_tax"
                                    placeholder={"Enter oc tax"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_base_fare) +
                                            Number(formik.values.FBPD_jn_tax) +
                                            Number(formik.values.FBPD_yq_tax) +
                                            Number(formik.values.FBPD_other_tax)
                                        formik.setFieldValue("FBPD_supplier_amount", amount.toFixed(2));
                                        formik.setFieldValue("FBPD_customer_net", (amount + formik.values.FBPD_service_fee).toFixed(2))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_oc_tax}
                                    error={formik.touched.FBPD_oc_tax &&
                                        Boolean(formik.errors.FBPD_oc_tax)
                                    }
                                    helperText={
                                        formik.touched.FBPD_oc_tax && formik.errors.FBPD_oc_tax
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Other Tax</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="FBPD_other_tax"
                                    placeholder={"Enter other tax"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_base_fare) +
                                            Number(formik.values.FBPD_jn_tax) +
                                            Number(formik.values.FBPD_yq_tax) +
                                            Number(formik.values.FBPD_oc_tax)
                                        formik.setFieldValue("FBPD_supplier_amount", amount.toFixed(2));
                                        formik.setFieldValue("FBPD_customer_net", (amount + formik.values.FBPD_service_fee).toFixed(2))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_other_tax}
                                    error={formik.touched.FBPD_other_tax &&
                                        Boolean(formik.errors.FBPD_other_tax)
                                    }
                                    helperText={
                                        formik.touched.FBPD_other_tax && formik.errors.FBPD_other_tax
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Airline/Supplier Amount</p>
                                <CustomTextField
                                    isDisabled={true}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="FBPD_supplier_amount"
                                    placeholder={"Enter supplier amount"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_supplier_amount}
                                    error={formik.touched.FBPD_supplier_amount &&
                                        Boolean(formik.errors.FBPD_supplier_amount)
                                    }
                                    helperText={
                                        formik.touched.FBPD_supplier_amount && formik.errors.FBPD_supplier_amount
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Service Fee</p>
                                <CustomTextField
                                   isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="FBPD_service_fee"
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    placeholder={"Enter service fee"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        let amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.FBPD_supplier_amount)
                                        formik.setFieldValue("FBPD_customer_net", amount.toFixed(2))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_service_fee}
                                    error={formik.touched.FBPD_service_fee &&
                                        Boolean(formik.errors.FBPD_service_fee)
                                    }
                                    helperText={
                                        formik.touched.FBPD_service_fee && formik.errors.FBPD_service_fee
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Customer Net</p>
                                <CustomTextField
                                    isDisabled={true}
                                    name="FBPD_customer_net"
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    placeholder={"Enter customer net"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FBPD_customer_net}
                                    error={formik.touched.FBPD_customer_net &&
                                        Boolean(formik.errors.FBPD_customer_net)
                                    }
                                    helperText={
                                        formik.touched.FBPD_customer_net && formik.errors.FBPD_customer_net
                                    }
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='flight_ticket_passenger_form_action_container'>
                    {!existingPassengerDetails && totalPassenger.length === totalCount ? <></> :
                        <div className='flight_ticket_passenger_form_single_row_alignment'>
                            {!existingPassengerDetails && (<button className='flight_ticket_passenger_form_reset_button' type='button'>Reset</button>)}

                            <button
                                className='flight_ticket_passenger_form_save_button'
                                type="submit"
                                form="flightTicketPassengerForm">{!existingPassengerDetails ? "Save" : "Update"}</button>
                        </div>
                    }

                </div>
            </form>
        </div>
    )
}

export default FlightTicketPassengerForm