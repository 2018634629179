import React, { useEffect, useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustomTable from '../../../../../components/custom_table/CustomTable';
import './flightTicketPassengerTable.css'
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import FlightTicketPassengerForm from '../flight_ticket_passenger_form/FlightTicketPassengerForm';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation, useParams } from 'react-router-dom';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { GoDotFill } from "react-icons/go";
import FlightDateChangeDetails from '../flight_date_change_details/FlightDateChangeDetails';
import FlightCancellationChangeDetails from '../flight_cancellation_change_details/FlightCancellationChangeDetails';
import TicketCancellationSummary from '../summary/ticket_cancellation_summary/TicketCancellationSummary';
import TicketDateChangeSummary from '../summary/ticket_date_change_summary/TicketDateChangeSummary';
import CustomExpandableTable from '../../../../../components/custom_expandable_table/CustomExpandableTable';
function FlightTicketPassengerTable({ passengerCount, passengerOtherCount, passengerList, setPassengerList, updatePassengerDetails, passengerDelete }) {
    let { booking_id } = useParams();
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [open, setOpen] = useState(false);
    const [wholeData, setWholeData] = useState([])
    const [dateChangeDetailsModal, setdateChangeDetailsModal] = useState(false);
    const [cancelDetailsModal, setcancelDetailsModal] = useState(false);
    const [passengerDetails, setPassengerDetails] = useState(null)
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [selectedRowDetails, setSelectedRowDetails] = useState(null)
    const [cabinType, setCabinType] = useState([])
    const [isAmendment, setIsAmendment] = useState(false)
    const [cancellationList, setCancellationList] = useState([])
    const [cancellationSummaryModal, setCancellationSummaryModal] = useState(false);
    const [dateChangeSummaryModal, setDateChangeSummaryModal] = useState(false)
    const [dateChangeList, setDateChangeList] = useState([])

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        } else if (location.pathname.split('/').pop() === "amendment_flight_ticket") {
            setIsView(true)
            setIsAmendment(true)
        }
        getPassengerList()
        getPassengerTitle()
        getPassengerCabin()
        const filterOutCancelledList = passengerList?.filter((item) => item?.cancellations?.length > 0)
        setCancellationList([...filterOutCancelledList])
        const filterOutDateChangeList = passengerList?.filter((item) => item?.date_changes?.length > 0)
        setDateChangeList(filterOutDateChangeList)
    }, [passengerList])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerCabin = () => {
        get_cabin_types().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CTL_name,
                    value: item.CTL_id,
                };
            });
            setCabinType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const Columns = [
        {
            title: "",
            dataIndex: "Expand",
            key: "expand",

        },
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Cabin",
            dataIndex: "cabin_name",
            key: "cabin_name",

        },
        {
            title: "Base Fare",
            dataIndex: "base_fare",
            key: "base_fare",
        },
        {
            title: "JN Tax",
            dataIndex: "jn_tax",
            key: "jn_tax",
        },
        {
            title: "YQ Tax",
            dataIndex: "yq_tax",
            key: "yq_tax",
        },
        {
            title: "OC Tax",
            dataIndex: "oc_tax",
            key: "oc_tax",
        },
        {
            title: "Other Tax",
            dataIndex: "other_tax",
            key: "other_tax",
        },
        {
            title: "Supplier Amount",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
        },
        {
            title: "Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            hide: isAmendment ? false : isView,
            action: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
                    <BiEditAlt className='flight_ticket_passenger_table_action_edit' onClick={() => {
                        setPassengerDetails(row)
                        setOpen(true)
                    }} />
                    {!isAmendment && (
                        <MdOutlineDeleteOutline className='flight_ticket_passenger_table_action_delete' onClick={() => {
                            passengerDelete(row.index)
                        }} />
                    )
                    }
                </div>
            ),
        },
    ];
    const ExpandColumns_1 = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "New PNR Number",
            dataIndex: "new_pnr_number",
            key: "new_pnr_number",
        },
        {
            title: "New Ticket Number",
            dataIndex: "new_ticket_number",
            key: "new_ticket_number",
        },
        {
            title: "New Travel Date",
            dataIndex: "new_travel_date",
            key: "new_travel_date",
        },
        {
            title: "New Return Date",
            dataIndex: "new_return_date",
            key: "new_return_date",
        },
        {
            title: "Base Fee",
            dataIndex: "base_fee",
            key: "base_fee",
        },
        {
            title: "Penality Fee",
            dataIndex: "penality_fee",
            key: "penality_fee",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
        },
        {
            title: "Total Fee",
            dataIndex: "total_fee",
            key: "total_fee",
        }
    ];
    const ExpandColumns_2 = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Cancellation Date",
            dataIndex: "cancellation_date",
            key: "cancellation_date",
        },

        {
            title: "Supplier Fee",
            dataIndex: "supplier_fee",
            key: "supplier_fee",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
        },
        {
            title: "Refund Fee",
            dataIndex: "refund_fee",
            key: "refund_fee",
        },
        {
            title: "Cancel Reason",
            dataIndex: "cancel_reason",
            key: "cancel_reason",
        },
    ];
    const handleClose = (is_close) => {
        if (!is_close) {
            setPassengerDetails(null)
        }
        setOpen(is_close);
    };
    const set_passenger_details = (details) => {
        setPassengerList(details)
    }
    const update_passenger_details = (details) => {
        updatePassengerDetails(details)
    }
    const datas = () => {
        const result =
            passengerList &&
            passengerList.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.FBPD_Passanger_type)?.label;
                const passengerCabinType = cabinType.find(pt => pt.value == item.FBPD_cabin_type)?.label;
                const passengerTitleLable = passengerTitle.find(pt => pt.value == item.FBPD_title_id)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.FBPD_Passanger_type,
                    type_name: passengerTypeItem,
                    title: item.FBPD_title_id,
                    title_name: passengerTitleLable,
                    ticket_number: item.FBPD_ticket_number,
                    full_name: item.FBPD_FullName,
                    base_fare: Number(item.FBPD_base_fare),
                    jn_tax: Number(item.FBPD_jn_tax),
                    yq_tax: Number(item.FBPD_yq_tax),
                    oc_tax: Number(item.FBPD_oc_tax),
                    other_tax: Number(item.FBPD_other_tax),
                    service_fee: Number(item.FBPD_service_fee),
                    supplier_amount: Number(item.FBPD_supplier_amount),
                    customer_net: Number(item.FBPD_customer_net),
                    cabin_name: passengerCabinType,
                    cabin_id: item.FBPD_cabin_type,
                    cancellation_comment: item?.cancellation_comment,
                    cancellation_reason: item?.cancellation_reason,
                    is_highlight_row: item?.FBPD_cancel_status || item?.FBPD_dateChange_status ? true : false,
                    highlight_color: item?.FBPD_cancel_status ? 'red' : item?.FBPD_dateChange_status ? 'orange' : '',
                    is_row_select: item?.FBPD_dateChange_status || item?.FBPD_cancel_status ? true : false,
                    date_change_details: item?.date_changes,
                    cancellation_details: item?.cancellations,
                    is_date_changes: item?.date_changes && item?.date_changes?.length > 0,
                    is_cancelled: item?.cancellations && item?.cancellations?.length > 0,
                    nested_table_row_data_1: expandable_datas_1(item?.date_changes),
                    nested_table_row_data_2: expandable_datas_2(item?.cancellations),
                    is_current:item?.FBPD_cancel_status==3 || item?.FBPD_dateChange_status ===3
                    // isMoveOver: item?.FBPD_cancel_status ? "comment" : false
                };
            });
        return result;
    };

    const expandable_datas_1 = (rowDetails) => {
        const result =
            rowDetails &&
            rowDetails.map((item, index) => {
                return {
                    key: index + 1,
                    index: index,
                    new_pnr_number: item.FDCD_changed_PNR,
                    new_ticket_number: item.FDCD_ticketnumber,
                    new_travel_date: item.FDCD_changed_travel_date,
                    new_return_date: item.FDCD_changed_return_date,
                    base_fee: item.FDCD_base_fee,
                    penality_fee: item.FDCD_change_penalty,
                    service_fee: item.FDCD_service_fee,
                    supplier_fee: item.FDCD_supplier_fee,
                    total_fee: item.Total_customer_net
                };
            });
        return result;
    };

    const expandable_datas_2 = (rowDetails) => {
        const result =
            rowDetails &&
            rowDetails.map((item, index) => {
                return {
                    key: index + 1,
                    index: index,
                    cancellation_date: item.FBCD_cancel_date,
                    cancel_reason: item.cancellation_reason,
                    service_fee: item.FBCD_service_fee,
                    supplier_fee: item.FBCD_supplier_fee,
                    refund_fee: item.refund_customer_net,
                };
            });
        return result;
    };

    const selectedRow = (values) => {
        setSelectedRowDetails(values)
        if (values.cancellation_details && values.cancellation_details.length > 0) {
            setcancelDetailsModal(true)
        } else if (values.date_change_details && values.date_change_details.length > 0) {
            setdateChangeDetailsModal(true)
        }
    }

    const updateDateChangeModalState = (is_open) => {
        setdateChangeDetailsModal(is_open)
        setcancelDetailsModal(is_open)
    }

    const updateSummaryModal = (is_open) => {
        setCancellationSummaryModal(is_open)
        setDateChangeSummaryModal(is_open)
    }

    const hoverRow = (values) => {
        console.log(values)
    }
    return (
        <div>
            <CustomModal
                maxWidth={"1500px"}
                open={open}
                handleClose={handleClose}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <FlightTicketPassengerForm totalPassenger={passengerList} passengerOtherCounts={passengerOtherCount} existingPassengerDetails={passengerDetails} onClose={handleClose} setPassengerDetails={set_passenger_details} updatePassengerDetails={update_passenger_details} />
                }
                title={!passengerDetails ? "Add Passenger" : "Update Passenger"}
            />

            <CustomModal
                maxWidth={"1000px"}
                open={dateChangeDetailsModal || cancelDetailsModal}
                handleClose={updateDateChangeModalState}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        updateDateChangeModalState(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <>{cancelDetailsModal ? <FlightCancellationChangeDetails datas={selectedRowDetails} /> : dateChangeDetailsModal ? <FlightDateChangeDetails datas={selectedRowDetails} /> : <></>}</>

                }
                title={cancelDetailsModal ? "Passenger Cancellation Details" : dateChangeDetailsModal ? "Date Change Passenger Details" : ""}
            />

            <CustomModal
                maxWidth={"1500px"}
                open={cancellationSummaryModal || dateChangeSummaryModal}
                handleClose={updateSummaryModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        updateSummaryModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <>{cancellationSummaryModal ? <TicketCancellationSummary datas={cancellationList} /> : dateChangeSummaryModal ? <TicketDateChangeSummary datas={dateChangeList} /> : <></>}</>

                }
                title={cancellationSummaryModal ? "Passenger Cancellation Summary" : dateChangeSummaryModal ? "Passenger Date change Summary" : ""}
            />

            <div className='flight_ticket_passenger_add_passenger_action_container' >
                <div>
                    {!isView && passengerCount != 0 && passengerCount > 0 && passengerList.length != passengerCount && (
                        <div className='flight_ticket_passenger_table_header_action_container' tabindex="0" onClick={() => {
                            setOpen(true)
                        }}>
                            <IoAddOutline className='flight_ticket_passenger_table_action_add' />
                            <p className='flight_ticket_passenger_table_action_text'>Add Passenger</p>
                        </div>
                    )}
                </div>

            </div>

            <div className='flight_ticket_passenger_table_header_component'>
                <p className='flight_ticket_passenger_table_header_text'>Passenger Details</p>
            </div>
            <div>
                <CustomExpandableTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} expand_columns_1={ExpandColumns_1} expand_columns_2={ExpandColumns_2} nested_table_title_1={"Date Change Summary"} nested_table_title_2={"Cancellation Summary"} />
                {/* <CustomTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} selectedRow={selectedRow} /> */}
            </div>
            <div className='table_legent_main_container'>
                {/* {booking_id && (
                    <>
                        {cancellationList && cancellationList.length > 0 && (
                            <p className='passenger_table_action_summary_button' onClick={() => {
                                setCancellationSummaryModal(!cancellationSummaryModal)
                            }}>Cancellation Summary</p>
                        )}
                        {dateChangeList && dateChangeList.length > 0 && (
                            <p className='passenger_table_action_summary_button' onClick={() => {
                                setDateChangeSummaryModal(!dateChangeSummaryModal)
                            }} >Date Change Summary</p>
                        )}
                    </>
                )} */}
                <GoDotFill className='table_legent_date_change_icon' />
                <p className='table_legent_date_change_text'>Date Change</p>
                <GoDotFill className='table_legent_cancel_icon' />
                <p className='table_legent_cancel_text'>Cancelled</p>
            </div>
        </div>
    )
}

export default FlightTicketPassengerTable