import React from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import './serviceFormStyle.css'

function ServiceForm() {
    const navigate=useNavigate()
    return (
      <div className='service_form_super_container'>
      <div >
          <div className='service_form_sub_container'>
              <div className='service_form_single_row_alignment'>
                  <IoArrowBackSharp className='service_form_back' onClick={() => {
                      navigate(-1)
                  }} />
                  <p className='service_form_title'>Add New Service</p>
              </div>
              <div className='service_form_single_row_alignment'>
                  <button className='service_form_reset_button'>Reset</button>
                  <button className='service_form_save_button'>Save</button>
              </div>
          </div>
  
          <div>
              <form id="serviceform" >
                  <div className='service_form_main_container'>
                      <p className="service_form_legend">Details</p>
                      <div>
                          <p className='service_form_label'>Service</p>
                          <CustomTextField
                              name="service"
                              placeholder={"Enter service"}
                          />
                      </div>
                      <div>
                          <p className='service_form_label'>Status</p>
                          <CustomAutoCompleteDropdown
                              name="status"
                              placeholder={"Select a status"}
                              options={[
                                  { label: 'Active', year: 1994 },
                                  { label: 'In Active', year: 1972 }]}
                          />
                      </div>
                  </div>
  
              </form>
  
          </div>
      </div>
  </div>
    )
}

export default ServiceForm