
import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import './CabFinanceLevelModals.css'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { approve_or_reject_filght_ticket } from '../../../../../api/process_ecr/flightTicketApis'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import { approve_or_reject_package } from '../../../../../api/packageApis'
import { approve_or_reject_cab } from '../../../../../api/CabApis'
function CabFinanceLevelModals({ selected_item, open_modal, modal_close }) {
    const [attachments, setAttachments] = useState([])
    const [fieldValues, setFieldValues] = useState({
        'invoice_number': '',
        'comment': ''
    });
    const [showError, setShowError] = useState({
        'invoice_number': '',
        'comment': '',
        'attachment': ''
    });

    const onSubmit = () => {
        let errors = {
            'invoice_number': '',
            'comment': '',
            'attachment': ''
        };

        if (!fieldValues.comment.trim()) {
            errors.comment = "Comment is required";
        }
        // selected_item?.type === "Verify" &&
        if (!fieldValues.invoice_number.trim()) {
            errors.invoice_number = "Invoice number is required";
        }
        // selected_item?.type === "Verify" &&
        if (attachments?.length === 0) {
            errors.attachment = "Attachment is required";
        }
        
        if (!errors.comment && !errors.invoice_number && !errors.attachment) {
            const formData = new FormData();

            formData.append('booking_id', selected_item?.id)
            formData.append('type', selected_item?.type)
            formData.append('comments', fieldValues.comment);
            formData.append('invoice_number', fieldValues.invoice_number);
            formData.append('attachment', attachments[0]?.file);

            approve_or_reject_cab(formData).then((response) => {
                if (response?.data?.status === true) {
                    SuccessToast(response?.data?.message)
                    modal_close(false)
                    resetStates()
                }else { //added by sajin 9/12/24 
                    ErrorToast(response?.data?.message || `Something went wrong!`)
                }
            }).catch((error) => {
                ErrorToast(`Something went wrong!`)
                console.log(error)
            })
        } else {
            setShowError(errors);
        }
    };

    function handleFileSelect(event, index) {
        const fileInput = event.target;
        const file = fileInput?.files[0];
        const fileName = file?.name;
        const fileType = file?.type.split("/")[1];
        if (file) {
            setAttachments([{ file: file, fileName: fileName, fileType: fileType }])
            setShowError({ ...showError, ['attachment']: '' })
        }
    }


    const resetStates = () => {
        setFieldValues({ 'invoice_number': '', 'comment': '' });
        setAttachments([]);
        setShowError({ 'invoice_number': '', 'comment': '', 'attachment': '' });
    };

    const delete_particular_attachment = (index, item) => {
        attachments.splice(index, 1)
        setAttachments([...attachments])
    }

    return (
        <div>
            <CustomModal
                maxWidth="500px"
                open={open_modal}
                handleClose={(e) => {
                    modal_close(e);
                    resetStates();
                }}
                body={
                    <div>
                        <div>
                            <p className='finance_level_modal_label'>Invoice Number<span className='label_required_remark'>*</span></p>
                            <CustomTextField
                                autoFocus
                                placeholder="Enter invoice number"
                                value={fieldValues.invoice_number}
                                onChange={(e) => {
                                    setFieldValues({ ...fieldValues, 'invoice_number': e.target.value });
                                    setShowError(prev => ({ ...prev, invoice_number: '' }));
                                }}
                            />
                            {showError.invoice_number && (<p className='comment_error_message'>{showError.invoice_number}</p>)}
                        </div>
                        <div>
                            <p className='finance_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                rows={5}
                                placeholder="Enter a comment"
                                value={fieldValues.comment}
                                onChange={(e) => {
                                    setFieldValues({ ...fieldValues, 'comment': e.target.value });
                                    setShowError(prev => ({ ...prev, comment: '' }));
                                }}
                            />
                            {showError.comment && (<p className='comment_error_message'>{showError.comment}</p>)}
                        </div>
                        <div>
                            <p className='finance_level_modal_label'>Attachment<span className='label_required_remark'>*</span></p>
                            <div style={{ margin: '20px 0px' }}>
                                <label htmlFor="files" className="flight_ticket_choose_file_field">
                                    <span id="fileInfo">Choose file</span>
                                </label>
                                <input
                                    id="files"
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/jpeg,image/png,application/pdf"
                                    onChange={(event) => handleFileSelect(event)}
                                />
                            </div>
                            {showError.attachment.length > 0 && (<p className='comment_error_message'>{showError.attachment}</p>)}
                            <div>
                                {attachments && attachments.length > 0 && attachments.map((item, index) => (
                                    <div key={index} className='flight_ticket_additional_info_attchment_item'>
                                        <p className='flight_ticket_additional_info_attachment_item' onClick={() => {
                                            if (item.url) {
                                                CommonHelpers.DownloadAssets(item.url)
                                            } else {
                                                CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                            }
                                        }} >{item.fileName ? item.fileName : item.FBPD_file}</p>
                                        <div>
                                            <MdDeleteOutline className='flight_ticket_additional_info_attachment_item_delete' onClick={() => {
                                                delete_particular_attachment(index, item)
                                            }} />

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='finance_level_modal_form_single_row_alignment'>
                            <button className='finance_level_modal_form_reset_button' onClick={() => {
                                modal_close(false)
                                resetStates()
                            }}>Cancel</button>

                            <button
                                className='finance_level_modal_form_update_button' onClick={() => {
                                    onSubmit()
                                }}>Submit</button>
                        </div>
                    </div>
                }
                title_other={`PNR - ${selected_item?.cab_pnr}`}
                title={selected_item?.type === "Hold" ? "Hold Cab" : "Verify Cab"}
            />
        </div>
    )
}

export default CabFinanceLevelModals