import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './clientFilterStyle.css'
import { get_status } from '../../../../../api/statusApis';
import { useFormik } from 'formik';
function ClientFilter({searchFilter}) {
    const [state, setStatus] = useState([])
    useEffect(() => {
        getStatus()
    }, [])
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            'name': '',
            'phone':'',
            'cd_email':'',
            'status_id': ""
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });
    const resetFilter=()=>{
        formik.setValues({
            ...formik.values, ...{
               'name': '',
            'phone':'',
            'cd_email':'',
            'status_id': ""
            }
        })
        searchFilter(null)
    }
  return (
    <div className='clients_filter_main_container'>
    <form id="clientFilterform" onSubmit={formik.handleSubmit}>
        <div className='clients_filter_sub_container'>
            <div>
                <CustomTextField
                    name="name"
                    placeholder={"Enter name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                />
            </div>
            <div>
                <CustomTextField
                    name="cd_email"
                    placeholder={"Enter email"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cd_email}
                />
            </div>
            <div>
                <CustomTextField
                    name="phone"
                    placeholder={"Enter phone"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                />
            </div>
            <div>
                <CustomAutoCompleteDropdown
                    name="status_id"
                    placeholder={"Select a status"}
                    options={state}
                    onChange={(event, newValue) => {
                        formik.setFieldValue(
                            "status_id",
                            newValue ? newValue.value : ""
                        );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                        state.find((status) =>
                            status.value ===
                            formik.values.status_id
                        ) || null
                    }
                />
            </div>
            <div className='client_filter_actions'>
                <button className='client_filter_action_reset' onClick={()=>{
                    resetFilter()
                }}>Reset</button>
                <button className='client_filter_action_search'
                type="submit"
                form="clientFilterform"
                >Search</button>
            </div>
        </div>

    </form>
</div>
  )
}

export default ClientFilter