import React, { useEffect, useState } from 'react'
import "./customNotificationContainerStyle.css"
import { get_user_notification, user_notification_read } from '../../api/userApis'
import { GoDotFill } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../services/localStorageServices';
import { useDispatch } from 'react-redux';
import { updateNotificationRedirect, updateNotificationState } from '../../store/commonStore';
function CustomNotificationContainer({ notificationList, update_notification_read,viewAllAction,is_border}) {
    let baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/`
    const navigate=useNavigate()
    const dispatch=useDispatch()
    // const [notificationList,setNotificationList]=useState([])
    // useEffect(()=>{
    //     get_all_notifications()
    // },[])
    // console.log("coloo")

    // const get_all_notifications=()=>{
    //     get_user_notification().then((response)=>{
    //         setNotificationList(response.data)
    //     }).catch((error)=>{
    //         console.log(error)
    //     })
    // }
    // const update_notification_read=(notification_id)=>{
    //     user_notification_read(notification_id).then((response)=>{
    //         get_all_notifications()
    //     }).catch((error)=>{
    //         console.log(error)
    //     })
    // }
    const navigate_to_module=(module,filter_data)=>{
        dispatch(updateNotificationRedirect({module,filter_data}))
        if(module==="Packages"){
            navigate(baseRouteUrl+`process_ecr/packages`)
        }else if(module==="Flight Tickets"){
            navigate(baseRouteUrl+`process_ecr/flight_ticket`)
        }

    }
    return (
        <div className='header_notification_listing_main_container' style={{border:is_border?'1px solid #eaeaea':'none'}}>
            {notificationList && notificationList.length > 0 ?
                <div>
                    {notificationList.map((item) => (
                        <div className='header_notification_list_sub_container' onClick={() => {
                            navigate_to_module(item?.module,item?.module_number)
                            update_notification_read(item?.notification_id)
                            dispatch(updateNotificationState())
                        }}>

                            <div className='header_notification_list_flex'>
                                <GoDotFill style={{ color: item?.read_at ? 'grey' : 'green' }} />
                                <p className='header_notification_message'>{item.message}</p>
                            </div>
                            <div className='header_notification_user_details_main_container_container'>
                                {/* <p className='header_notification_user_name'>{item.userName}</p> */}
                                <p className='header_notification_date_time'>{item.created_at}</p>
                            </div>

                        </div>
                    ))}
                    {viewAllAction && (
                        <div className='header_notification_view_all_container'>
                        <p className='notification_view_all_text' id='notification_view_all_text' onClick={()=>{
                             navigate(baseRouteUrl+`notification`)
                        }}>View All</p>
                       </div>
                    )}
                 
                </div> : <div className='header_notification_list_sub_container'>
                    <p className='header_notification_not_found'>No data found</p>
                </div>
            }
        </div>
    )
}

export default CustomNotificationContainer