import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import './flightTicketFinanceLevelModals.css'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { approve_or_reject_filght_ticket } from '../../../../../api/process_ecr/flightTicketApis'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import dayjs from 'dayjs'
import { TailSpin } from 'react-loader-spinner'
function FlightTicketFinanceLevelModals({ selected_item, open_modal, modal_close }) {
    const [passengerList, setPassengerList] = useState([])
    const [passengerFieldValidation, setPassengerFieldValidaion] = useState([])
    const [attachments, setAttachments] = useState([])
    const [fieldValues, setfieldValues] = useState({
        'invoice_number': '',
        'comment': ''
    })
    const [showError, setShowError] = useState({
        'invoice_number': '',
        'comment': '',
        'attachment': ''
    })

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (selected_item?.passenger_list) {
            const filtered_out_passenger = selected_item?.passenger_list?.filter((item) => item.FBPD_cancel_status === 3 || item.FBPD_dateChange_status === 3)
            const restructured = filtered_out_passenger.map((item, index) => {
                return {
                    key: index,
                    booking_id: item.FBPD_booking_id,
                    passenger_id: item.FBPD_id,
                    passenger_type: item.passengerTypeName,
                    passenger_title: item.passengerTitleName,
                    passenger_name: item.FBPD_FullName,
                    invoice_number: null,
                    invoice_attachment: null
                }
            })
            setPassengerList(restructured)
        }
    }, [selected_item?.passenger_list, open_modal])


    const onSubmit = () => {
        let FieldValidation = []
        let errors = {
            'invoice_number': '',
            'comment': '',
            'attachment': ''
        }
        if (!fieldValues.comment.trim()) {
            errors.comment = "Comment is required"
        }
        if (passengerList.length === 0) {
            if (selected_item?.type === "Verified" && !fieldValues.invoice_number.trim()) {
                errors.invoice_number = "Invoice number is required"
            }
            if ((selected_item?.type === "Verified" && attachments.length === 0)) {
                errors.attachment = "Attachment is required"
            }
        } else {
            passengerList.map((item) => {
                const error = {
                    index: item.key
                }
                if (!item.invoice_number?.trim()) {
                    error['invoice_number'] = "Invoice number is required"
                }
                if (!item.invoice_attachment) {
                    error['invoice_attachment'] = "Invoice attachment is required"
                }
                if (error.invoice_number || error.invoice_attachment) {
                    FieldValidation.push(error)
                }

            })
            if (FieldValidation && FieldValidation.length > 0) {
                setPassengerFieldValidaion(FieldValidation)
            }
        }

        if (errors.comment.trim()) {
            setShowError(errors)
        }
        if (selected_item?.type === "Verified" && passengerList.length > 0 && FieldValidation.length > 0) return
        if (!errors.comment.trim() && !(selected_item?.type === "Verified" && errors.invoice_number.trim()) && !(selected_item?.type === "Verified" && errors.attachment.trim())) {
            const currentDate = dayjs();
            const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
            const formData = new FormData();
            formData.append('bookingId', selected_item?.id)
            formData.append('PNR_Number', selected_item?.pnr)
            formData.append('FBD_ECRstatusID', selected_item?.type === "OnHold" ? 7 : selected_item.ecr_cancelled_status === 3 ? 12 : selected_item?.ecr_date_change_status === 3 ? 16 : 4)
            if (selected_item?.type !== "OnHold" && passengerList.length == 0) {
                formData.append('FBRH_invoice_number', fieldValues.invoice_number)
                attachments.map((item, index) => {
                    formData.append(`file[${index}]`, item.file);
                })
            } else {
                if (passengerList.length > 0) {
                    passengerList.forEach((item, index) => {
                        formData.append(`invoice_data[invoice_number][${index}]`, item?.invoice_number);
                        formData.append(`invoice_data[passenger_id][${index}]`, item?.passenger_id);
                        formData.append(`invoice_data[file][${index}]`, item?.invoice_attachment?.file);
                    });
                    // formData.append('file',passengerList.map((item)=>{return {"file":item?.invoice_attachment?.file}}))
                }
            }
            formData.append('FBRH_review_comment', fieldValues.comment)
            formData.append('FBRH_review_date', formattedDate)

            setLoading(true)
            approve_or_reject_filght_ticket(formData).then((response) => {
                if (response.status === 200) {
                    if (selected_item?.type === "OnHold") {
                        SuccessToast('Sucessfully changes status to hold')
                    } else {
                        SuccessToast('Sucessfully verified')
                    }
                    modal_close(false)
                    resetStates()
                }
            }).catch((error) => {
                ErrorToast(error?.data?.message)
            }).finally(() => {
                setLoading(false)
            })

        } else {
            setShowError(errors)
        }


    }
    function handleFileSelect(event, index) {
        const fileInput = event.target;
        const file = fileInput?.files[0];
        const fileName = file?.name;
        const fileType = file?.type.split("/")[1];
        if (file) {

            setAttachments([...attachments, { file: file, fileName: fileName, fileType: fileType }])
            if (showError.attachment.length > 0) {
                setShowError({ ...showError, ['attachment']: '' })
            }
        }
    }
    function handleFileDateChangeAndCancelSelect(event, index) {
        const fileInput = event.target;
        const file = fileInput?.files[0];
        const fileName = file?.name;
        const fileType = file?.type.split("/")[1];
        if (file) {
            update_invoice_details(index, 'invoice_attachment', { file: file, fileName: fileName, fileType: fileType })
        }
    }
    const delete_particular_attachment = (index, item) => {
        attachments.splice(index, 1)
        setAttachments([...attachments])
    }
    const resetStates = () => {
        setAttachments([])
        setfieldValues({
            'invoice_number': '',
            'comment': ''
        })
        setShowError({
            'invoice_number': '',
            'comment': '',
            'attachment': ''
        })
        setPassengerList([])
        setPassengerFieldValidaion([])
    }
    const update_invoice_details = (row_index, name, value) => {
        const get_Passenger_list = passengerList.map((item, index) => {
            if (row_index === index) {
                return { ...item, [name]: value }
            } else {
                return { ...item }
            }

        })
        const removeErrormessage = passengerFieldValidation.filter((item) => item.index != row_index)
        setPassengerFieldValidaion(removeErrormessage)
        setPassengerList(get_Passenger_list)
    }

    const delete_invoice_details = (row_index) => {
        const get_Passenger_list = passengerList.map((item, index) => {
            if (row_index === index) {
                return { ...item, invoice_attachment: null }
            } else {
                return { ...item }
            }

        })
        setPassengerList(get_Passenger_list)

    }
    return (
        <div>
            <CustomModal
                maxWidth={passengerList.length > 0 && selected_item?.type !== "OnHold" ? "1200px" : '500px'}
                open={open_modal}
                handleClose={(e) => {
                    modal_close(e)
                    resetStates()
                }}
                body={
                    <div>
                        {selected_item?.type !== "OnHold" && (
                            <>
                                {passengerList && passengerList.length > 0 ?
                                    <>
                                        {passengerList.map((item, index) => {
                                            const matchingItemValidation = passengerFieldValidation?.find((val) => val.index === index);
                                            let attachment_input_id = "cancel_datechange_files" + index
                                            return (
                                                <div className='passenger_item_main_container'>
                                                    <div>
                                                        <p>{index + 1}. </p>
                                                    </div>
                                                    <div className='passenger_list_header_container'>
                                                        <div className='finance_invoice_row_alignment'>
                                                            {/* <p>Name : </p> */}
                                                            <p>{item.passenger_title + " " + item.passenger_name}</p>
                                                        </div>
                                                        {/* <div className='finance_invoice_row_alignment'>
                                                <p>Type : </p>
                                                <p>{item.passenger_type}</p>
                                            </div> */}
                                                        {/* <p>Name : {item.passenger_title + " " + item.passenger_name}</p>
                                            <p>Type : {item.passenger_type}</p> */}
                                                        <div className='finance_invoice_row_alignment'>
                                                            <p>Invoice#<span className='label_required_remark'> *</span> : </p>
                                                            <div className='finance_invoice_column_alignment'>
                                                                <CustomTextField
                                                                    value={item.invoice_number}
                                                                    name="invoice_number"
                                                                    placeholder={"Enter Invoice Number"}
                                                                    onChange={(e) => {
                                                                        const { name, value } = e.target
                                                                        update_invoice_details(index, name, value)
                                                                    }}
                                                                />
                                                                {matchingItemValidation?.invoice_number ? <p className='finance_comment_error_message_0' >{matchingItemValidation?.invoice_number}</p> : <></>}
                                                            </div>

                                                        </div>
                                                        <div className='finance_invoice_row_alignment'>
                                                            <p>Attachment<span className='label_required_remark'> *</span> : </p>
                                                            {item.invoice_attachment ? <>
                                                                <div className='date_change_cancel_invoice_attachment'>
                                                                    <p className='date_change_cancel_invoice_file' onClick={() => {
                                                                        CommonHelpers.DownloadFileObjectAssets(item?.invoice_attachment?.fileName, item?.invoice_attachment?.file)
                                                                    }}>{item?.invoice_attachment?.fileName}</p>
                                                                    <MdDeleteOutline className='flight_ticket_additional_info_attachment_item_delete' onClick={() => {
                                                                        delete_invoice_details(index)
                                                                    }} />
                                                                </div>
                                                            </> : <>
                                                                <div className='finance_invoice_column_alignment'>
                                                                    <div className="finance_invoice_choose_file_field">
                                                                        <label htmlFor={attachment_input_id} >
                                                                            <span id="fileInfo" className='finance_invoice_choose_file_text' >Choose File</span>
                                                                        </label>
                                                                        <input
                                                                            id={attachment_input_id}
                                                                            style={{ display: "none" }}
                                                                            type="file"
                                                                            accept="image/jpeg,image/png,application/pdf"
                                                                            onChange={(event) => { handleFileDateChangeAndCancelSelect(event, index) }}
                                                                        />
                                                                    </div>
                                                                    {matchingItemValidation?.invoice_attachment ? <p className='finance_comment_error_message_0' >{matchingItemValidation?.invoice_attachment}</p> : <></>}
                                                                </div>

                                                            </>}

                                                        </div>

                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </> : <>
                                        <div>
                                            <p className='finance_level_modal_label'>Invoice Number<span className='label_required_remark'>*</span></p>
                                            <CustomTextField
                                                autoFocus
                                                placeholder={"Enter a invoice number"}
                                                onChange={(e) => {
                                                    setfieldValues({ ...fieldValues, 'invoice_number': e.target.value })
                                                    if (showError.invoice_number.length > 0) {
                                                        setShowError({ ...showError, ['invoice_number']: '' })
                                                    }
                                                }}
                                            />
                                            {(selected_item?.type === "Verified" && showError.invoice_number.length > 0) && (<p className='comment_error_message'>{showError.invoice_number}</p>)}
                                        </div>
                                    </>}

                            </>

                        )}

                        <div>
                            <p className='finance_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                autoFocus={selected_item?.type === "OnHold"}
                                rows={5}
                                placeholder={"Enter a comment"}
                                onChange={(e) => {
                                    setfieldValues({ ...fieldValues, 'comment': e.target.value })
                                    if (showError.comment.length > 0) {
                                        setShowError({ ...showError, ['comment']: '' })
                                    }

                                }}
                            />
                            {showError.comment.length > 0 && (<p className='comment_error_message'>{showError.comment}</p>)}
                        </div>
                        {selected_item?.type !== "OnHold" && passengerList && passengerList.length === 0 && (
                            <div>
                                <p className='finance_level_modal_label'>Attachment<span className='label_required_remark'>*</span></p>
                                <div style={{ margin: '20px 0px' }}>
                                    <label htmlFor="files" className="flight_ticket_choose_file_field">
                                        <span id="fileInfo">Choose file</span>
                                    </label>
                                    <input
                                        id="files"
                                        style={{ display: "none" }}
                                        type="file"
                                        accept="image/jpeg,image/png,application/pdf"
                                        onChange={(event) => handleFileSelect(event)}
                                    />
                                </div>
                                {showError.attachment.length > 0 && (<p className='comment_error_message'>{showError.attachment}</p>)}
                                <div>
                                    {attachments && attachments.length > 0 && attachments.map((item, index) => (
                                        <div key={index} className='flight_ticket_additional_info_attchment_item'>
                                            <p className='flight_ticket_additional_info_attachment_item' onClick={() => {
                                                if (item.url) {
                                                    CommonHelpers.DownloadAssets(item.url)
                                                } else {
                                                    CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                }
                                            }} >{item.fileName ? item.fileName : item.FBPD_file}</p>
                                            <div>
                                                <MdDeleteOutline className='flight_ticket_additional_info_attachment_item_delete' onClick={() => {
                                                    delete_particular_attachment(index, item)
                                                }} />

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className='finance_level_modal_form_single_row_alignment'>
                            <button className='finance_level_modal_form_reset_button' onClick={() => {
                                modal_close(false)
                                resetStates()
                            }}>Cancel</button>

                            <button disabled={loading} className='finance_level_modal_form_update_button'
                                type="submit"
                                onClick={() => {
                                    onSubmit()
                                }}> {loading ? <div className='submit_button_loader'>
                                    <TailSpin
                                        visible={loading}
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div> : "Submit"}</button>
                        </div>
                    </div>
                }
                title_other={'PNR - ' + selected_item?.pnr}
                title={selected_item?.type === "OnHold" ? "Hold Ticket" : "Verify Ticket"}
            />
        </div>
    )
}

export default FlightTicketFinanceLevelModals