import React, { useEffect, useState } from 'react'
import { FaAngleDown } from "react-icons/fa6";
import { CiMenuBurger } from "react-icons/ci";
import './headerStyle.css'
import profile_icon from '../../../assets/profile_icon.png'
import change_password_icon from '../../../assets/change_password_icon.png'
import logout_icon from '../../../assets/logout_icon.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCollapsedState } from '../../../store/commonStore';
import { LocalStorageServices } from '../../../services/localStorageServices';
import { user_login, user_logout } from '../../../api/authApis';
import { Avatar, Badge, IconButton } from '@mui/material';
import { resetSearchFilter } from '../../../store/process_ecr/flightTicketStore';
import CustomModal from '../../../components/custom_modal/CustomModal';
import ChangePassword from '../../sub_pages/user_management/user/change_passoword/ChangePassword';
import UserProfile from '../../sub_pages/master_settings/general_settings/sub_components/user_profile/UserProfile';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { get_user_latest_notification, get_user_notification, user_details_by_user_id, user_notification_read } from '../../../api/userApis';
import { IoMdNotifications } from "react-icons/io";
import CustomNotificationContainer from '../../../components/custom_notification_container/CustomNotificationContainer';
import { SuccessToast } from '../../../toast/toasts';
function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const notification_state = useSelector((state) => state.commonStore.notificationUpdate)
  const [isShowProfileMenu, setIsShowProfileMenu] = useState(false)
  const [isShowNotification, setIsShowNotification] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [userPasswordChange, setUserPasswordChange] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [editUserProfile, setEditUserProfile] = useState(false);
  const [notificationList, setNotificationList] = useState([])
  const [notificationNotReadCount, setNotificationNotReadCount] = useState(0)
  useEffect(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    if (user_details) {
      const { UD_id } = JSON.parse(user_details)
      if (UD_id) {
        get_user_details_by_id(UD_id)
        get_all_notifications()
      }
    }
  }, [userProfile, notification_state])

  const get_user_details_by_id = (UD_id) => {
    user_details_by_user_id(UD_id).then((response) => {
      setUserDetails(response?.data?.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  window.addEventListener('click', function (e) {
    const popover = document.getElementById('header_profile_based_menus');
    const notificationBadge = document.getElementById('header_profile_notification_badge');
    const notificationListingContainer = document.getElementById('notification_listing_container');
    const notificationViewAllText = document.getElementById("notification_view_all_text")

    // Check if the click target is inside the notification listing container
    const isClickInsideNotificationListing = notificationListingContainer?.contains(e.target);

    // Check if the click is outside the popover and notification badge
    if (
      (popover !== e.target &&
        notificationBadge !== e.target &&
        !isClickInsideNotificationListing) || (notificationViewAllText == e.target)
    ) {
      if (isShowProfileMenu) {
        setIsShowProfileMenu(false);
      }
      if (isShowNotification) {
        setIsShowNotification(false);
      }
    }
  });

  const handlePasswordChangeClose = (is_close) => {
    setUserPasswordChange(is_close);
  };
  const handleUserDetailsChange = (is_close) => {
    setUserProfile(is_close);
    setEditUserProfile(false)
  };
  const get_all_notifications = () => {
    get_user_latest_notification().then((response) => {
      setNotificationNotReadCount(response.count)
      setNotificationList(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const update_notification_read = (notification_id) => {
    user_notification_read(notification_id).then((response) => {
      get_all_notifications()
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleLogOut = () => {
    user_logout()
      .then((response) => {
        // // Reset filters
        dispatch(resetSearchFilter());
        // // Clear local storage
        LocalStorageServices.deleteLocalStorageData();
        // // Navigate to login
        navigate('/ecrs_portal/login');
        SuccessToast(response?.data?.message)
      })
      .catch((error) => {
        console.log('Logout failed:', error);
      });
  };
  return (
    <>
      <div className='header_main_container'>
        <div>
          <CiMenuBurger className='header_hamburger_menu' onClick={() => {
            dispatch(updateCollapsedState())
          }} />
        </div>
        <div className='header_profile_main_container'>
          <div className='header_profile_notification_main_container'>
            <Badge id="header_profile_notification_badge" onClick={() => {
              setTimeout(() => {
                setIsShowNotification(!isShowNotification)
              }, [100])

            }} sx={{
              "& .MuiBadge-badge": {
                color: 'white',
                backgroundColor: "red",
                fontWeight: 'bold'
              }
            }}  showZero badgeContent={notificationNotReadCount} max={notificationNotReadCount} >
              <IoMdNotifications id="header_profile_notification_badge" className='notification_icon' />
            </Badge>
            {isShowNotification && (
              <div className='header_profile_notification_container' id='notification_listing_container'>
                <CustomNotificationContainer notificationList={notificationList} update_notification_read={update_notification_read} viewAllAction is_border />
              </div>

            )}

          </div>

          <div>
            <Avatar alt={userDetails?.username} src={userDetails?.profile_image_url} />
          </div>
          <div className='header_user_details' id="header_user_details" onClick={() => {
            setTimeout(() => {
              setIsShowProfileMenu(!isShowProfileMenu)
            }, [100])

          }}>
            <p className='header_user_name'>{userDetails?.username}</p>
            <div className='header_role_dropdown_container'>
              <p className='header_role'>{userDetails?.role_name}</p>
              <FaAngleDown className='header_dropdown' />
            </div>

          </div>

        </div>
        {isShowProfileMenu && (
          <div className='header_profile_based_menus' id="header_profile_based_menus">
            <div className='header_profile_based_menus_container'>
              <img src={profile_icon} className='header_profile_icon' />
              <p className='header_profile_text' onClick={() => {
                setUserProfile(true)
              }}>Edit Profile</p>
            </div>
            <div className='header_profile_based_menus_container'>
              <img src={change_password_icon} className='header_profile_icon' />
              <p className='header_profile_text' onClick={() => {
                setUserPasswordChange(true)
              }}>Change Password</p>
            </div>
            <div className='header_profile_based_menus_container'
              onClick={handleLogOut}
            >
              <img src={logout_icon} className='header_profile_icon' />
              <p className='header_profile_text'>Logout</p>
            </div>
          </div>

        )}
      </div>
      <CustomModal
        open={userPasswordChange}
        handleClose={handlePasswordChangeClose}
        body={
          <ChangePassword id={userDetails?.UD_id} onclose={handlePasswordChangeClose} isRedirect />
        }
        title={"Change password"}
      />
      <CustomModal
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleUserDetailsChange(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        open={userProfile}
        handleClose={handleUserDetailsChange}
        body={
          <UserProfile is_edit={editUserProfile} handle_modal_change={handleUserDetailsChange} />
        }
        title={<div className='user_profile_with_edit_actions'>
          <p>User Profile</p>
          {!editUserProfile && (
            <div className='user_profile_sub_icon_container'>
              <IconButton onClick={() => {
                setEditUserProfile(true)
              }} ><MdModeEditOutline className='user_profile_edit_icon' /></IconButton>
            </div>
          )}

        </div>}
      />
    </>
  )
}

export default Header