import React, { useEffect, useState } from 'react'
import './hotelBookingFormStyle.css'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { CgAddR } from "react-icons/cg";
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import { get_status } from '../../../../../api/statusApis';
import { get_services } from '../../../../../api/servicesApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import { IoArrowBackSharp } from "react-icons/io5";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { get_hotels } from '../../../../../api/hotelApis';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import HotelCustomerTable from '../hotel_customer_table/HotelCustomerTable';
import CustomDeleteConfirmModal from '../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import SupplierForm from '../../../master_settings/suppliers/supplier_form/SupplierForm';
import ClientForm from '../../../master_settings/clients/client_form/ClientForm';
import HotelForm from '../../hotel_or_resorts/hotel_form/HotelForm';
import { create_new_hotel_booking, get_hotel_bookings_details_by_id, update_hotel_booking } from '../../../../../api/process_ecr/hotelBookingApis';
import { CommonHelpers } from '../../../../../services/commonHelpers';
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { hasPrivilege } from '../../../../../common_privilige_methods/Privilege_Methods';
function HotelBookingForm() {
  const navigate = useNavigate()
  let { booking_id } = useParams();
  const location = useLocation()
  const [status, setstatus] = useState([])
  const [client, setClient] = useState([])
  const [supplier, setSupplier] = useState([])
  const [hotels, setHotels] = useState([])
  const [passengerCount, setPassengerCount] = useState(0)
  const [passengerList, setPassengerList] = useState([])
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletePassengerId, setDeletePassengerId] = useState(null)
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(true)
  const [client_creation_modal, set_client_creation_modal] = useState(false)
  const [supplier_creation_modal, set_supplier_creation_modal] = useState(false)
  const [hotel_creation_modal, set_hotel_creation_modal] = useState(false)
  const [isView, setIsView] = useState(null)
  const [fileAttachments, setFileAttachments] = useState([])
  const [passengerOtherCount, setPassengerOtherCount] = useState({
    'adult': 0,
    'child': 0,
    'infants': 0
  })

  useEffect(() => {
    if (location.pathname.split('/').pop() === "view_hotel_booking") {
      setIsView(true);
    } else if (location.pathname.split('/').pop() === "update_hotel_booking") {
      setIsView(false);
      // setIsUpdate(true);
    }
    getStatus()
    if (booking_id) {
      const payload = {
        booking_id
      }
      get_hotel_bookings_details_by_id(payload)
        .then((response) => {
          const bookingData = response?.data?.data
          const passengerData = bookingData?.passengers;
          setIsSaveAsDraft(bookingData?.status_id === 1 ? true : false)
          // console.log("bookingData",bookingData);

          formik.setValues({
            ...formik.values,
            enquiry_number: bookingData.enquiry_number, //
            issue_date: bookingData.issue_date, // 
            hotel: bookingData.hotel_id,
            checkIn: bookingData.checkIn, //
            checkOut: bookingData.checkOut, // 
            client: bookingData.client_id,
            client_amount: bookingData.client_amount,
            supplier: bookingData.supplier_id,
            supplier_amount: bookingData.supplier_amount,
            gst: bookingData.gst,
            tcs: bookingData.tcs,
            adult: bookingData.adult,
            child: bookingData.child,
            infant: bookingData.infant,
            reference: bookingData.reference,
            comments: bookingData.comments,
            narration: bookingData.narration,
          });
          setPassengerList(passengerData);
          setPassengerOtherCount({
            'adult': bookingData?.adult || 0,
            'child': bookingData?.child || 0,
            'infants': bookingData?.infant || 0
          })
          // const parsedPnrs = JSON.parse(bookingData?.pnr_refernce || '[]');
          // setChipData(parsedPnrs.map((item, index) => ({ key: index + 1, label: item })));

          if (bookingData?.attachments === null) {
            setFileAttachments([]);
          } else {
            setFileAttachments(bookingData?.attachments.map((item, index) => ({ url: item.attachment, fileName: item.name })));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [booking_id])
  useEffect(() => {
    getHotels()
    getSupplierList()
    getClientList()
  }, [hotel_creation_modal, client_creation_modal, supplier_creation_modal])
  //NEW SUBMIT FUNCTION
  const formik = useFormik({
    initialValues: {
      enquiry_number: '', //
      issue_date: null, // 
      hotel: '',
      checkIn: null, //
      checkOut: null, // 
      client: '',
      client_amount: 0.00,
      supplier: '',
      supplier_amount: 0.00,
      gst: 0.00,
      tcs: 0.00,
      adult: 0,
      child: 0,
      infant: 0,
      reference: '',
      comments: '',
      narration: '',
    },
    validationSchema: Yup.object({
      enquiry_number: Yup.string().required('Enquiry number is required'), //
      issue_date: Yup.string().required('Issue date is required'),//
      hotel: Yup.string().required('hotel name is required'),
      checkIn: Yup.string().required('Check in date is required'),///
      checkOut: Yup.string().required('Check out date is required'),//
      client: Yup.string().required('Client is required'),
      client_amount: Yup.string().required('Client amount is required'),
      supplier: Yup.string().required('Supplier is required'),
      supplier_amount: Yup.string().required('Supplier amount is required'),
    }),
    onSubmit: (values) => {
      let value = JSON.parse(JSON.stringify(values))
      value['status'] = isSaveAsDraft ? 1 : 2
      value['passengers'] = JSON.stringify(passengerList)
      const formData = new FormData();
      for (const key in value) {
        formData.append(key, value[key]);
      }
      const attachments = fileAttachments.map((item) => item.file)
      if (attachments.length > 0) {
        attachments.map((item, index) => {
          formData.append(`file[${index}]`, item);
        })
      }
      if (!booking_id) {
        create_new_hotel_booking(formData).then((response) => {
          if (response?.data?.code === 200 && response?.data?.status === true) {
            navigate(-1);
            SuccessToast(response.data.message);
          } else {
            ErrorToast(response.data.message);
          }
        }).catch((error) => {
          console.log("Error in form submission:", error);
          ErrorToast(`Something Went Wrong`);
        });
      } else {
        formData.append("booking_id", booking_id);
        update_hotel_booking(formData).then((response) => {
          if (response?.data?.code === 200) {
            navigate(-1);
            SuccessToast(response.data.message);
          } else {
            ErrorToast(response.data.message);
          }
        }).catch((error) => {
          console.log("Error in form submission:", error);
          ErrorToast(`Something Went Wrong`);
        });
      }
    }
  });

  const getStatus = () => {
    get_status().then((response) => {
      const data = response.map((item) => {
        return {
          label: item.status_name,
          value: item.id,
        };
      });
      setstatus(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getSupplierList = () => {
    get_supplier_without_pagination().then((response) => {
      const data = response?.data?.data.map((item) => {
        return {
          label: item.SD_name,
          value: item.SD_id,
        };
      });
      setSupplier(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getClientList = () => {
    get_client_lists_without_pagination().then((response) => {
      const data = response?.data?.map((item) => {
        return {
          label: item.CI_name,
          value: item.CI_id,
        };
      });
      setClient(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getHotels = () => {
    get_hotels().then((response) => {
      const data = response?.data?.map((item) => {
        return {
          label: item.hotelName,
          value: item.hotel_id,
        };
      });
      setHotels(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const set_passenger_list = (item) => {
    setPassengerList([...passengerList, item])
  }
  const update_passenger_list = (item) => {
    passengerList.splice(item.index, 1, item)
    setPassengerList([...passengerList])
  }
  const delete_particular_passenger_details = (index) => {
    setDeletePassengerId(index)
    setDeleteConfirmation(true)
  }
  const deletePassenger = () => {
    if (deletePassengerId !== null) {
      passengerList.splice(deletePassengerId, 1)
      setPassengerList([...passengerList])
      handleDeleteModalClose(false)
    }

  }
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setDeletePassengerId(null)
  };
  const handleSupplierCreationModal = (is_close) => {
    set_supplier_creation_modal(is_close);
  };
  const handleClientCreationModal = (is_close) => {
    set_client_creation_modal(is_close);
  };
  const handleHotelCreationModal = (is_close) => {
    set_hotel_creation_modal(is_close);
  };
  const set_attachment_list = (item) => {
    setFileAttachments([...fileAttachments, item])
  }
  const delete_particular_attachment_details = (index, item) => {
    fileAttachments.splice(index, 1)
    setFileAttachments([...fileAttachments])

  }
  function handleFileSelect(event) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    const fileName = file?.name;
    const fileType = file?.type?.split("/")[1];
    if (file === undefined || fileType === undefined || fileName === undefined) {
      return
    } else {
      // Add the new file details to the attachment list
      set_attachment_list({ file: file, fileName: fileName, fileType: fileType })
    }
  }

  const is_Show_Draft_Btn = hasPrivilege("Process ECR", "Hotel", "Draft");
  const is_show_Submit_Btn = hasPrivilege("Process ECR", "Hotel", "Submit");

  return (
    <div className='packages_form_super_container'>
      <div >
        <div className='packages_form_sub_container'>
          <div className='packages_form_single_row_alignment'>
            <IoArrowBackSharp className='packages_form_back' onClick={() => {
              navigate(-1)
            }} />
            <p className='flight_ticket_form_title'>{booking_id && isView ? 'View Hotel Booking Details' : booking_id ? 'Update Hotel Booking Details' : 'Hotel Booking Details'}</p>
          </div>
        </div>

        <div>
          <form id="hotelBookingForm" onSubmit={formik.handleSubmit}>

            <div className='packages_form_main_container'>
              <p className="packages_form_legend">General Info</p>
              <div className='packages_form_sub_container_0'>

                <div>
                  <p className='packages_form_label'>Enquirey Number<span className='label_required_remark'>*</span></p>
                  <CustomTextField
                    autoFocus
                    isDisabled={isView}
                    name="enquiry_number"
                    placeholder={"Enter enquirey number"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.enquiry_number}
                    error={formik.touched.enquiry_number && Boolean(formik.errors.enquiry_number)}
                    helperText={formik.touched.enquiry_number && formik.errors.enquiry_number}
                  />
                </div>
                <div>
                  <div className='packages_form_label_with_action_container'>
                    <p className='packages_form_label_with_action'>Hotel<span className='label_required_remark'>*</span></p>
                    {!isView && (
                      <CgAddR className='packages_booking_label_action' onClick={() => {
                        handleHotelCreationModal(true)
                      }} />
                    )}

                  </div>

                  <CustomAutoCompleteDropdown
                    name="hotel"
                    isDisabled={isView}
                    placeholder={"Select Hotel "}
                    options={hotels}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "hotel",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      hotels.find((type) =>
                        type.value ===
                        formik.values.hotel
                      ) || null
                    }
                    error={
                      formik.touched.hotel &&
                      Boolean(formik.errors.hotel)
                    }
                    helperText={
                      formik.touched.hotel &&
                      formik.errors.hotel
                    }
                  />
                </div>
                <div>
                  <p className='flight_ticket_form_label'>Issue Date<span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={isView}
                      name="issue_date"
                      onChange={(date) => {
                        formik.setFieldValue("issue_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.issue_date, "DD-MM-YYYY")}
                      error={
                        formik.touched.issue_date &&
                        Boolean(formik.errors.issue_date)
                      }
                      helperText={
                        formik.touched.issue_date && formik.errors.issue_date
                      } />

                  </div>

                </div>
                <div>
                  <p className='flight_ticket_form_label'>Check In <span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={isView}
                      name="checkIn"
                      onChange={(date) => {
                        formik.setFieldValue("checkIn", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.checkIn, "DD-MM-YYYY")}
                      error={
                        formik.touched.checkIn &&
                        Boolean(formik.errors.checkIn)
                      }
                      helperText={
                        formik.touched.checkIn && formik.errors.checkIn
                      } />

                  </div>

                </div>

                <div>
                  <p className='packages_form_label'>Check Out <span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={isView ? isView : formik.values.checkIn ? false : true}
                      minDate={dayjs(formik.values.checkIn, "DD-MM-YYYY")}
                      name="checkOut"
                      onChange={(date) => {
                        formik.setFieldValue("checkOut", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.checkOut, "DD-MM-YYYY")}
                      error={
                        formik.touched.checkOut &&
                        Boolean(formik.errors.checkOut)
                      }
                      helperText={
                        formik.touched.checkOut && formik.errors.checkOut
                      } />

                  </div>

                </div>
                <div>
                  <p className='flight_ticket_form_label'>Adult</p>
                  <CustomTextField
                    isDisabled={isView}
                    type="number"
                    name="adult"
                    placeholder="Enter adult number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      const adultCount = e.target.value !== '' ? Number(e.target.value) : 0;
                      const amount = adultCount + formik.values.child + formik.values.infant;
                      setPassengerCount(amount);
                      setPassengerOtherCount({ ...passengerOtherCount, adult: adultCount });
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.adult}
                    error={formik.touched.adult && Boolean(formik.errors.adult)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.adult && formik.errors.adult}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Child</p>
                  <CustomTextField
                    isDisabled={isView}
                    type="number"
                    name="child"
                    placeholder="Enter child number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      const childCount = e.target.value !== '' ? Number(e.target.value) : 0;
                      const amount = childCount + formik.values.adult + formik.values.infant
                      setPassengerCount(amount)
                      setPassengerOtherCount({ ...passengerOtherCount, ['child']: childCount })
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.child}
                    error={formik.touched.child && Boolean(formik.errors.child)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.child && formik.errors.child}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Infants</p>
                  <CustomTextField
                    isDisabled={isView}
                    type="number"
                    name="infant"
                    placeholder="Enter infants number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      const infantsCount = e.target.value !== '' ? Number(e.target.value) : 0;
                      const amount = infantsCount + formik.values.adult + formik.values.child
                      setPassengerCount(amount)
                      setPassengerOtherCount({ ...passengerOtherCount, ['infants']: infantsCount })
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.infant}
                    error={formik.touched.infant && Boolean(formik.errors.infant)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.infant && formik.errors.infant}
                  />
                </div>

                <div>
                  <p className='packages_form_label'>Reference</p>
                  <CustomTextField
                    isDisabled={isView}
                    name="reference"
                    placeholder={"Enter Reference"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reference}
                  />
                </div>
              </div>
            </div>

            <div className='flight_ticket_form_main_container'>
              <p className="flight_ticket_form_legend">Fair </p>
              <div className='packages_form_sub_container_0'>

                <div>
                  <div className='flight_ticket_form_label_with_action_container'>
                    <p className='flight_ticket_form_label_with_action'>Customer/Client<span className='label_required_remark'>*</span></p>
                    {!isView && (
                      <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                        set_client_creation_modal(true)
                      }} />)}
                  </div>
                  <CustomAutoCompleteDropdown
                    isDisabled={isView}
                    name="client"
                    placeholder={"Select a customer"}
                    options={client}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "client",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      client.find((type) =>
                        type.value ===
                        formik.values.client
                      ) || null
                    }
                    error={
                      formik.touched.client &&
                      Boolean(formik.errors.client)
                    }
                    helperText={
                      formik.touched.client &&
                      formik.errors.client
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Client Amount</p>
                  <CustomTextField
                    isDisabled={isView}
                    type={'number'}
                    name="client_amount"
                    placeholder={"Enter client amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("client_amount", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.client_amount}
                    error={formik.touched.client_amount &&
                      Boolean(formik.errors.client_amount)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.client_amount && formik.errors.client_amount
                    }
                  />
                </div>

                <div>
                  <div className='flight_ticket_form_label_with_action_container'>
                    <p className='flight_ticket_form_label_with_action'>Supplier<span className='label_required_remark'>*</span></p>
                    {!isView && (
                      <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                        handleSupplierCreationModal(true)
                      }} />)}
                  </div>
                  <CustomAutoCompleteDropdown
                    isDisabled={isView}
                    name="supplier"
                    placeholder={"Select a customer"}
                    options={supplier}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "supplier",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      supplier.find((type) =>
                        type.value ===
                        formik.values.supplier
                      ) || null
                    }
                    error={
                      formik.touched.supplier &&
                      Boolean(formik.errors.supplier)
                    }
                    helperText={
                      formik.touched.supplier &&
                      formik.errors.supplier
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Supplier Amount</p>
                  <CustomTextField
                    isDisabled={isView}
                    type={'number'}
                    name="supplier_amount"
                    placeholder={"Enter supplioer amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("supplier_amount", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.supplier_amount}
                    error={formik.touched.supplier_amount &&
                      Boolean(formik.errors.supplier_amount)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.supplier_amount && formik.errors.supplier_amount
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>GST</p>
                  <CustomTextField
                    isDisabled={isView}
                    type={'number'}
                    name="gst"
                    placeholder={"Enter client amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("gst", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.gst}
                    error={formik.touched.gst &&
                      Boolean(formik.errors.gst)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.gst && formik.errors.gst
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>TCS</p>
                  <CustomTextField
                    isDisabled={isView}
                    type={'number'}
                    name="tcs"
                    placeholder={"Enter tcs "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("tcs", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tcs}
                    error={formik.touched.tcs &&
                      Boolean(formik.errors.tcs)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.tcs && formik.errors.tcs
                    }
                  />
                </div>

              </div>

            </div>
            <div className='flight_ticket_form_main_container'>
              <p className="flight_ticket_form_legend">Passenger Info</p>
              <HotelCustomerTable isDisabled={false} passengerCount={passengerCount} passengerOtherCount={passengerOtherCount} passengerList={passengerList} setPassengerList={set_passenger_list} updatePassengerDetails={update_passenger_list} passengerDelete={delete_particular_passenger_details} />
            </div>
            <div className='packages_form_main_container'>
              <p className="packages_form_legend"> Miscellaneous</p>
              <div className='packages_form_sub_container_0'>
                <div>
                  {!isView && (
                    <>
                      <p className='flight_ticket_additional_info_main_text'>Browse File</p>
                      <div style={{ margin: '20px 0px' }}>
                        <label htmlFor="files" className="flight_ticket_choose_file_field">
                          <span id="fileInfo">Choose file</span>
                        </label>
                        <input
                          id="files"
                          style={{ display: "none" }}
                          type="file"
                          accept="image/jpeg,image/png,application/pdf"
                          multiple
                          onChange={(event) => handleFileSelect(event)}
                        />
                      </div>
                    </>
                  )}
                  {fileAttachments && fileAttachments.length > 0 && <p className='flight_ticket_additional_info_main_text'>Attachments</p>}
                  <div className='flight_ticket_additional_info_attchment_main_container'>
                    {fileAttachments && fileAttachments.length > 0 && fileAttachments.map((item, index) => (
                      <div className='flight_ticket_additional_info_attchment_item'>
                        <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                        <div className='flight_ticket_additional_info_attchment_item_action'>
                          <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                            if (item.url) {
                              CommonHelpers.DownloadAssets(item.url)
                            } else {
                              CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                            }
                          }} />
                          {!isView && (
                            <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                              delete_particular_attachment_details(index, item)
                            }} />
                          )}

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p className='packages_form_label'>Narration</p>
                  <CustomTextArea
                    rows={5}
                    name="narration"
                    placeholder={"Enter a narration"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.narration}
                  />
                </div>

                <div>
                  <p className='packages_form_label'>Comments</p>
                  <CustomTextArea
                    rows={5}
                    name="comments"
                    placeholder={"Enter Comment"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                  />
                </div>


                {/* <PackagesAdditionalI client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} /> */}
              </div>

            </div>

          </form>
          <div className='flight_ticket_form_footer_actions'>
            <div className='flight_ticket_form_single_row_alignment' >
              {!booking_id ? <button className='flight_ticket_form_reset_button' tabIndex='14' onClick={() => {
                formik.resetForm()
              }}>Reset</button> : <></>}
              {!isView && (
                <>
                  {is_Show_Draft_Btn && <button
                    className='flight_ticket_form_save_button'
                    type="submit"
                    form="hotelBookingForm" onClick={() => {
                      setIsSaveAsDraft(true)
                    }}>Save as Draft</button>}
                  {is_show_Submit_Btn && <button
                    className='flight_ticket_form_save_button'
                    type="submit"
                    form="hotelBookingForm" onClick={() => {
                      setIsSaveAsDraft(false)
                    }}>Submit</button>}

                </>
              )}
            </div>
          </div>

        </div>
      </div>
      {/* {Hotel creation modal} */}
      <CustomModal
        maxWidth={"1500px"}
        open={hotel_creation_modal}
        handleClose={handleHotelCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleHotelCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <HotelForm is_open_modal={true} close_modal={handleHotelCreationModal} />
        }
        title={"Hotel Registartion"}
      />
      {/* client registration modal */}
      <CustomModal
        maxWidth={"1500px"}
        open={client_creation_modal}
        handleClose={handleClientCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleClientCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <ClientForm is_open_modal={true} close_modal={handleClientCreationModal} />
        }
        title={"Client Registartion"}
      />
      {/* {supplier registration modal} */}
      <CustomModal
        maxWidth={"1500px"}
        open={supplier_creation_modal}
        handleClose={handleSupplierCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleSupplierCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <SupplierForm is_open_modal={true} close_modal={handleSupplierCreationModal} />
        }
        title={"Supplier Registartion"}
      />
      <CustomDeleteConfirmModal module_name={'customer'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deletePassenger} />
    </div>
  )
}

export default HotelBookingForm