import React, { useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import FlightPaymentInformation from '../flight_payment_information/FlightPaymentInformation'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { update_payment_details } from '../../../../../api/paymentApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { getPrivilegesDetailsLocalStorageData } from '../../../../../services/localStorageServices';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
function FlightPaymentInformationWithTransaction({ selected_item, open_modal, modal_close }) {
    const [paymentInfoAttachment, setPaymentInfoAttachment] = useState(null)
    const [client, setClient] = useState([])

    // Fetch privileges data once
    const privileges = getPrivilegesDetailsLocalStorageData();

    const formik = useFormik({
        initialValues: {
            'payment_status_id': '',
            'payment_method_id': '',
            'total_ticket_amount': 0,
            'outstanding_amount': 0,
            'enter_amount_to_pay': 0,
            'remark': ''
        },
        validationSchema: Yup.object({
            payment_status_id: Yup.string().required("Payment status is required"),
            payment_method_id: Yup.string().required("Payment mode is required"),
            total_ticket_amount: Yup.number()
                .required("Total ticket amount is required")
                .min(1, "Total ticket amount must be greater than 0"),
            outstanding_amount: Yup.number()
                .required("Total outstanding amount is required")
                .min(1, "Total outstanding amount must be greater than 0"),
            enter_amount_to_pay: Yup.number()
                .required("Amount to pay is required")
                .min(1, "Amount to pay must be greater than 0")
                .test(
                    'is-less-than-total',
                    'Amount to pay cannot be greater than the total amount',
                    function (value) {
                        const { outstanding_amount } = this.parent;
                        return outstanding_amount === undefined || value <= outstanding_amount;
                    }
                ),
            remark: Yup.string().required("Remark is required")
        }),

        onSubmit: (values, { resetForm }) => {
            const parseValue = JSON.parse(JSON.stringify(values))
            parseValue['total_amount'] = parseValue?.outstanding_amount
            parseValue['paid_amount'] = parseValue?.enter_amount_to_pay
            let check_client_credit_amount = client
                .filter((item) => item.value == selected_item?.FBD_client_id)
                .map((item) => ({ credit_limit_amount: item.credit }))[0]['credit_limit_amount'];
     
            if(Number(check_client_credit_amount) < 1 && parseValue?.payment_method_id === 5){
                return ErrorToast('The credit amount is not set for the selected client. Please update the client’s credit amount or choose another payment method.');
            }else if (
                Number(check_client_credit_amount) > 1 && // Ensure credit limit is above 1
                Number(values?.enter_amount_to_pay) > Number(check_client_credit_amount) && 
                // Number(check_client_credit_amount) < Number(parseValue?.total_ticket_amount) && // Credit is less than the ticket amount
                // (Number(parseValue?.outstanding_amount) - Number(parseValue?.enter_amount_to_pay)) > Number(check_client_credit_amount) && // Outstanding balance check
                parseValue?.payment_method_id === 5 // Payment method check
            ) {
                return ErrorToast('The ticket amount exceeds the credit limit of the selected client. Please choose another payment method.');
            } else {
                const formData = new FormData();
                for (const key in parseValue) {
                    formData.append(key, parseValue[key]);
                }

                formData.append('booking_id', selected_item?.FBD_Id)
                if (paymentInfoAttachment && !paymentInfoAttachment?.file_url) {
                    formData.append(`file_type`, 'payment');
                    formData.append(`payment_file`, paymentInfoAttachment?.file);
                }
                update_payment_details(formData).then((response) => {
                    SuccessToast(response.message)
                    modal_close(false)
                }).catch((error) => {
                    ErrorToast(error)
                    console.log(error)
                })

            }

        }
    })

    useState(() => {
        if (selected_item) {
            formik.setValues({
                ...formik.values,
                'total_ticket_amount': selected_item?.get_paymentinfo[0]?.EPH_total_amount,
                'outstanding_amount': selected_item?.get_paymentinfo[0]?.EPH_balance_paid_amount
            })
        }
        getClientList()
    }, [selected_item])

    const update_payment_attachment = (attachment) => {
        setPaymentInfoAttachment(attachment)
    }
    // Check if Flight Tickets has transaction update permission
    const isFlight_Tickets_Transaction_Update_Permission = privileges.some(item =>
        item.module_name === "Process ECR" &&
        item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === "Transaction update")
    );
    function getClientList(){
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                    credit: item.CI_credit_limit_amount
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <div>
            <CustomModal
                maxWidth={'1200px'}
                open={open_modal}
                handleClose={modal_close}
                close={<IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={() => {
                        modal_close(false)
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <div>
                        <FlightPaymentInformation formik={formik} table_data={selected_item?.get_paymentinfo} paymentAttachmnet={paymentInfoAttachment} updatePaymentAttachment={update_payment_attachment} inline_action form_visibility={isFlight_Tickets_Transaction_Update_Permission} />
                    </div>
                }
                title_other={'PNR - ' + selected_item?.FBD_PNR}
                title={"Payment Information"}
            />
        </div>
    )
}

export default FlightPaymentInformationWithTransaction