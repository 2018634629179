import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { get_status } from '../../../../../api/statusApis';
import { get_roles_with_out_pagination } from '../../../../../api/rolesApis';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './userFilterStyle.css'
import { get_locations_with_out_pagination } from '../../../../../api/locationApis';
function UserFilter({searchFilter}) {
    const [role, setRole] = useState([])
    const [state, setStatus] = useState([])
    const [locations, setlocation] = useState([])
    useEffect(() => {
        getStatus()
        getRolesWithOutPagination()
        getLocations()
    }, [])
    const formik = useFormik({
        initialValues: {
            'name': '',
            "location": '',
            "phone": "",
            'role_id': '',
            'status_id': ""
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getLocations = () => {
        get_locations_with_out_pagination().then((response) => {
            const data = response?.data.map((item) => {
                return {
                    label: item.ld_name,
                    value: item.ld_id,
                };
            });
            setlocation(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getRolesWithOutPagination = () => {
        get_roles_with_out_pagination().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.role_name,
                    value: item.role_id,
                };
            });
            setRole(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const resetFilter=()=>{
        formik.setValues({
            ...formik.values, ...{
                'name': '',
                "location": '',
                "phone": "",
                'role_id': '',
                'status_id': ""
            }
        })
        searchFilter(null)
    }
    return (
        <div className='users_filter_main_container'>
            <form id="userFilterform" onSubmit={formik.handleSubmit}>
                <div className='users_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="name"
                            placeholder={"Enter name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="location"
                            placeholder={"Select a location"}
                            options={locations}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "location",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                locations.find((location) =>
                                    location.value ===
                                    formik.values.location
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomTextField
                            name="phone"
                            placeholder={"Enter mobile"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="role_id"
                            placeholder={"Select a role"}
                            options={role}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "role_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                role.find((role) =>
                                    role.value ===
                                    formik.values.role_id
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="status_id"
                            placeholder={"Select a status"}
                            options={state}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "status_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                state.find((status) =>
                                    status.value ===
                                    formik.values.status_id
                                ) || null
                            }
                        />
                    </div>
                    <div className='user_filter_actions'>
                        <button className='user_filter_action_reset' onClick={()=>{
                            resetFilter()
                        }}>Reset</button>
                        <button className='user_filter_action_search'
                        type="submit"
                        form="userFilterform"
                        >Search</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default UserFilter