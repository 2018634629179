import { ApiConfig } from "./apiConfig";

export const get_dashboard_datas = async () => {
    try {
      const response = await ApiConfig.post(`dashboard`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};
