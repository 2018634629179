import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { IoArrowBackSharp } from "react-icons/io5";
import dayjs from "dayjs";
import './flightTicketFormStyle.css'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import FlightTicketPassengerTable from '../flight_ticket_passenger_table/FlightTicketPassengerTable';
import FlightTicketAdditionalInfo from '../flight_ticket_additional_info/FlightTicketAdditionalInfo';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { useState } from 'react';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import { get_ticket_type, get_trip_type } from '../../../../../api/commonLookUpApis';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { booking_document_delete, create_new_flight_booking, get_airline_names_list, get_flight_ticket_details_by_id, update_flight_booking, upload_document_for_booking } from '../../../../../api/process_ecr/flightTicketApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import ClientForm from '../../../master_settings/clients/client_form/ClientForm';
import SupplierForm from '../../../master_settings/suppliers/supplier_form/SupplierForm';
import { CgAddR } from "react-icons/cg";
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDeleteConfirmModal from '../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import FlightPaymentInformation from '../flight_payment_information/FlightPaymentInformation';
import { TailSpin } from 'react-loader-spinner';
import { GrSearchAdvanced } from 'react-icons/gr';
import AdvanceClientFilter from '../../../master_settings/clients/advance_filter/AdvanceClientFilter';
import AdvanceSupplierFilter from '../../../master_settings/suppliers/advance_filter/AdvanceSupplierFilter';
import { get_payment_status_list } from '../../../../../api/paymentApis';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
function FlightTicketForm() {
    const navigate = useNavigate()
    let { booking_id } = useParams();
    const location = useLocation()
    const [supplier, setSupplier] = useState([])
    const [client, setClient] = useState([])
    const [ticketType, setTicketType] = useState([])
    const [tripType, setTripType] = useState([])
    const [passengerList, setPassengerList] = useState([])
    const [fileAttachments, setFileAttachments] = useState([])
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(true)
    const [passengerCount, setPassengerCount] = useState(0)
    const [passengerOtherCount, setPassengerOtherCount] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })


    const [deletedAttachments, setDeletedAttachments] = useState([])
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false)
    const [isView, setIsView] = useState(null)
    const [isAmendment, setIsAmendment] = useState(false)
    const [client_creation_modal, set_client_creation_modal] = useState(false)
    const [supplier_creation_modal, set_supplier_creation_modal] = useState(false)
    const [deletePassengerId, setDeletePassengerId] = useState(null)
    const [airlineLists, setAirlineLists] = useState([])
    // const [paymentInfoAttachment, setPaymentInfoAttachment] = useState(null)
    // const [paymentInfoList, setPaymentInfoList] = useState([])

    const [loading, setLoading] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState([])
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        } else if (location.pathname.split('/').pop() === "amendment_flight_ticket") {
            setIsView(true)
            setIsAmendment(true)
        }
        getTicketTypeList()
        getTripTypeList()
        getAirlineList()
        getPaymentStatusList()
        if (booking_id) {
            const formData = new FormData();
            formData.append('bookingId', booking_id);
            get_flight_ticket_details_by_id(formData).then((response) => {
                const booking_datas = response?.data?.booking
                const passenger_datas = response?.data?.passengers
                const passenger_documents = response?.data?.documents
                // const payment_info = response?.data?.paymentinfo
                // const review_history = response?.data?.reviewHistory
                formik.setValues({
                    ...formik.values,
                    'FBD_PNR': booking_datas.FBD_PNR,
                    'FBD_ticket_type_id': booking_datas.FBD_ticket_type_id,
                    'FBD_trip_type': booking_datas.FBD_trip_type,
                    'FBD_FlightName': booking_datas.FBD_FlightName,
                    'FBD_issue_date': booking_datas.FBD_issue_date ? booking_datas.FBD_issue_date.split('T')[0] : booking_datas.FBD_issue_date,
                    'FBD_travel_date': booking_datas.FBD_travel_date ? booking_datas.FBD_travel_date.split('T')[0] : booking_datas.FBD_travel_date,
                    'FBD_return_date': booking_datas.FBD_return_date ? booking_datas.FBD_return_date.split('T')[0] : booking_datas.FBD_return_date,
                    'FBD_supplier_id': booking_datas.FBD_supplier_id,
                    'FBD_client_id': booking_datas.FBD_client_id,
                    'FBD_sector': booking_datas.FBD_sector,
                    'FBD_reference': booking_datas.FBD_reference,
                    'FBD_adult': booking_datas.FBD_adult,
                    'FBD_child': booking_datas.FBD_child,
                    'FBD_infant': booking_datas.FBD_infant,
                    'FBD_pax_count': booking_datas.FBD_pax_count,
                    'FBD_comment': booking_datas.FBD_comment,
                    'payment_status_id': booking_datas?.payment_status_id,
                    // 'payment_method_id':booking_datas?.FBD_ECRstatusID==1?payment_info[0]?.EPH_payment_method_id:'',
                    // 'total_amount':booking_datas?.FBD_ECRstatusID==1?payment_info[0]?.EPH_total_amount:'',
                    // 'paid_amount':booking_datas?.FBD_ECRstatusID==1?payment_info[0]?.EPH_balance_paid_amount:'',
                    'remark':booking_datas?.remark
                    // 'total_ticket_amount': booking_datas?.FBD_ECRstatusID == 1 || booking_datas?.FBD_ECRstatusID == 6 ? payment_info[0]?.EPH_total_amount ? payment_info[0]?.EPH_total_amount : 0 : 0,
                    // 'outstanding_amount': booking_datas?.FBD_ECRstatusID == 1 || booking_datas?.FBD_ECRstatusID == 6 ? payment_info[0]?.EPH_balance_paid_amount ? payment_info[0]?.EPH_balance_paid_amount : 0 : 0,
                })

                setPassengerCount(booking_datas.FBD_pax_count)
                setFileAttachments(passenger_documents)
                setPassengerList(passenger_datas)
                // setPaymentInfoList(payment_info)
                // setPaymentInfoAttachment(booking_datas?.FBD_ECRstatusID==1?{ file_url: payment_info[0].EPH_attchment_url, fileName: payment_info[0].EPH_attchment,attachment_id:payment_info[0].EPH_id }:null)
                // setReviewHistory(review_history)
                setPassengerOtherCount({
                    'adult': booking_datas.FBD_adult,
                    'child': booking_datas.FBD_child,
                    'infants': booking_datas.FBD_infant
                })
                //FOR SET ALREADY SUBMITED VALUES 
                setSelectedRowId(booking_datas?.FBD_client_id || ""); // Select
                setSelectedSupplierRowId(booking_datas?.FBD_supplier_id || "");
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [isUpdated])
    useEffect(() => {
        getSupplierList()
        getClientList()
    }, [client_creation_modal, supplier_creation_modal])
    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                    credit: item.CI_credit_limit_amount
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getTicketTypeList = () => {
        get_ticket_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TT_type,
                    value: item.TT_id,
                };
            });
            setTicketType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getTripTypeList = () => {
        get_trip_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TRIP_name,
                    value: item.TRIP_id,
                };
            });
            setTripType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getAirlineList = () => {
        get_airline_names_list().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.AirlineName,
                    value: item.AirlineID,
                };
            });
            setAirlineLists(data)
        }).catch((error) => {
            console.log(error)
        })
    }



    const set_passenger_list = (item) => {
        setPassengerList([...passengerList, item])
        update_passenger_total_amount([...passengerList, item])
    }
    const update_passenger_list = (item) => {
        passengerList.splice(item.index, 1, item)
        setPassengerList([...passengerList])
        update_passenger_total_amount([...passengerList])
    }
    const delete_particular_passenger_details = (index) => {
        setDeletePassengerId(index)
        setDeleteConfirmation(true)
    }
    const deletePassenger = () => {
        if (deletePassengerId !== null) {
            passengerList.splice(deletePassengerId, 1)
            setPassengerList([...passengerList])
            update_passenger_total_amount([...passengerList])
            handleDeleteModalClose(false)
        }

    }
    const handleDeleteModalClose = (is_close) => {
        setDeleteConfirmation(is_close);
        setDeletePassengerId(null)
    };
    const set_attachment_list = (item) => {
        setFileAttachments([...fileAttachments, item])
    }
    const delete_particular_attachment_details = (index, item) => {
        fileAttachments.splice(index, 1)
        setFileAttachments([...fileAttachments])
        if (item.FBDF_id) {
            setDeletedAttachments([...deletedAttachments, item.FBDF_id])
        }
    }
    // const update_payment_attachment = (attachment) => {
    //     setPaymentInfoAttachment(attachment)
    // }
    const update_passenger_total_amount = (passengerList) => {
        let total_passenger_amount = passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)
        formik.setValues({
            ...formik.values,
            'total_ticket_amount': total_passenger_amount,
            'outstanding_amount': total_passenger_amount
        })
    }
    const formik = useFormik({
        initialValues: {
            'FBD_PNR': '',
            'FBD_ticket_type_id': '',
            'FBD_trip_type': '',
            'FBD_FlightName': '',
            'FBD_issue_date': null,
            'FBD_travel_date': null,
            'FBD_return_date': null,
            'FBD_supplier_id': '',
            'FBD_client_id': '',
            'FBD_sector': '',
            'FBD_reference': '',
            'FBD_adult': 0,
            'FBD_child': 0,
            'FBD_infant': 0,
            'FBD_pax_count': 0,
            'FBD_comment': '',
            'payment_status_id': '',
            // 'payment_method_id': '',
            // 'total_ticket_amount': 0,
            // 'outstanding_amount': 0,
            // 'enter_amount_to_pay': 0,
            'remark': ''

            // 'FBD_Agent_fee': ''
        },

        validationSchema: Yup.object({
            FBD_PNR: Yup.string().required("Pnr number is required"),
            FBD_FlightName: Yup.string().required("Airline name is required"),
            FBD_ticket_type_id: Yup.string().required("Ticket type is required"),
            FBD_trip_type: Yup.string().required("Trip type is required"),
            FBD_issue_date: Yup.string().required("Issue date is required"),
            FBD_travel_date: Yup.string().required("Travel date is required"),
            FBD_supplier_id: Yup.string().required("Supplier is required"),
            FBD_client_id: Yup.string().required("Client/Customer is required"),
            FBD_sector: Yup.string().required("Sector is required"),
            payment_status_id: Yup.string().required("Payment status is required"),
            remark: Yup.string().required("Remark is required"),
            // payment_status_id: Yup.lazy(() =>
            //     paymentInfoList?.length === 0
            //         ? Yup.string().required("Payment status is required")
            //         : Yup.string()
            // ),
            // payment_method_id: Yup.lazy(() =>
            //     paymentInfoList?.length === 0
            //         ? Yup.string().required("Payment mode is required")
            //         : Yup.string()
            // ),
            // total_ticket_amount: Yup.lazy(() =>
            //     paymentInfoList?.length === 0
            //         ? Yup.number()
            //               .required("Ticket amount is required")
            //               .min(0, "Ticket amount must be greater than 0")
            //         : Yup.number()
            // ),
            // outstanding_amount: Yup.lazy(() =>
            //     paymentInfoList?.length === 0
            //         ? Yup.number()
            //               .required("Outstanding amount is required")
            //               .min(0, "Outstanding amount must be greater than 0")
            //         : Yup.number()
            // ),
            // enter_amount_to_pay: Yup.number()
            //     .required("Amount to pay is required")
            //     .min(0, "Amount to pay must be greater than 0")
            //     .test(
            //         'is-less-than-total',
            //         'Amount to pay cannot be greater than the total amount',
            //         function (value) {
            //             const { outstanding_amount } = this.parent;
            //             return outstanding_amount === undefined || value <= outstanding_amount;
            //         }
            //     ),
            //     remark: Yup.lazy(() =>
            //         paymentInfoList?.length === 0
            //             ? Yup.string().required("Remark is required")
            //             : Yup.string()
            //     ),
            FBD_adult: Yup.number()
                .required("Adult count is required")
                .min(0, "Adult count cannot be less than 0"),
            FBD_child: Yup.number()
                .required("child count is required")
                .min(0, "child count cannot be less than 0"),
            FBD_infant: Yup.number()
                .required("Infant count is required")
                .min(0, "Infant count cannot be less than 0")
                .test(
                    'infant-less-than-adult',
                    'Infant count cannot be greater than adult count',
                    function (value) {
                        return value <= this.parent.FBD_adult;
                    }
                ),
            FBD_pax_count: Yup.number()
                .required("Passenger count is required")
                .min(0, "Passenger count cannot be less than 0"),
            FBD_return_date: Yup.string()
                .when('FBD_trip_type', {
                    is: "2",
                    then: (schema) =>
                        schema.nullable(false).required('Return date is required'),
                    otherwise: (schema) => schema.nullable()
                })
        }),

        onSubmit: (values) => {
            if (passengerList.length === 0) return ErrorToast('Atleast 1 passenger is required')
            if (formik.values.FBD_pax_count !== passengerList.length) return ErrorToast(`${formik.values.FBD_pax_count - passengerList.length > 0 ? 'passenger details are missing' : 'mismatch in passenger count.'} `)
            const currentDate = dayjs();
            const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
            let value = JSON.parse(JSON.stringify(values))
            value.FBD_ECRstatusID = isSaveAsDraft ? 1 : 2
            value.passengerData = passengerList
            value['FBRH_review_date'] = formattedDate
            // if (value?.payment_status_id && value?.payment_method_id) {
            //     value['total_amount'] = value?.outstanding_amount
            //     value['paid_amount'] = value?.enter_amount_to_pay
            // }
            // let check_client_credit_amount = client
            //     .filter((item) => item.value == value?.FBD_client_id)
            //     .map((item) => ({ credit_limit_amount: item.credit }))[0]['credit_limit_amount'];
            // console.log(check_client_credit_amount)
            // if (Number(check_client_credit_amount) < 1 && value?.payment_method_id === 5) {
            //     return ErrorToast('The credit amount is not set for the selected client. Please update the client’s credit amount or choose another payment method.');
            // } else if (
            //     Number(check_client_credit_amount) > 1 && // Ensure credit limit is above 1
            //     Number(value?.enter_amount_to_pay) > Number(check_client_credit_amount) &&
            //     // Number(check_client_credit_amount) < Number(value?.total_ticket_amount) && // Credit is less than the ticket amount
            //     // (Number(value?.outstanding_amount) - Number(value?.enter_amount_to_pay)) > Number(check_client_credit_amount) && // Outstanding balance check
            //     value?.payment_method_id === 5 // Payment method check
            // ) {
            //     return ErrorToast('The amount to pay exceeds the credit limit of the selected client. Please choose another payment method.');
            // } else 
            
            if (!booking_id) {
                setLoading(true)
                create_new_flight_booking(value).then((response) => {
                    if (response.status == 200) {
                        const attachments = fileAttachments.map((item) => item.file)
                        if (attachments.length > 0) {
                            const formData = new FormData();
                            attachments.map((item, index) => {
                                formData.append(`file[${index}]`, item);
                            })
                            // if (paymentInfoAttachment && !paymentInfoAttachment?.file_url) {
                            //     formData.append(`file-type`, 'payment');
                            //     formData.append(`payment_file`, paymentInfoAttachment?.file);
                            // }
                            formData.append('bookingId', response?.data?.bookingId);
                            upload_document_for_booking(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        navigate(-1)
                        SuccessToast(response.data.message)
                        setIsUpdated(!isUpdated)
                    }
                }).catch((error) => {
                    let message = "";
                    if (error?.data?.message) {
                        message = error.data.message
                    } else {
                        for (const key in error?.data?.errors) {
                            if (error.data.errors.hasOwnProperty(key)) {
                                const keyMessages = error.data.errors[key].map(message => message);
                                message = message.concat(keyMessages);
                            }
                        }
                    }

                    ErrorToast(message)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                if (deletedAttachments) {
                    const formData = new FormData();
                    formData.append('bookingId', booking_id);
                    deletedAttachments.map((item) => {
                        formData.append('BookingDocumentid', item);
                        booking_document_delete(formData).then((response_1) => {
                            console.log(response_1)
                        }).catch((error) => {
                            console.log(error)
                        })
                    })
                }
                update_flight_booking(booking_id, value).then((response) => {
                    if (response.status == 200) {

                        const attachments = fileAttachments.filter((item) => item.file).map((item) => item.file)
                        if (attachments.length > 0) {
                            const formData = new FormData();
                            attachments.map((item, index) => {
                                formData.append(`file[${index}]`, item);
                            })
                            // if (paymentInfoAttachment && !paymentInfoAttachment?.file_url) {
                            //     formData.append(`file_type`, 'payment');
                            //     formData.append(`payment_file`, paymentInfoAttachment?.file);
                            // }
                            formData.append('bookingId', booking_id);
                            upload_document_for_booking(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        SuccessToast(response.data.message)
                        if (value.FBD_ECRstatusID == 2) {
                            navigate(-1)
                        }
                        setIsUpdated(!isUpdated)

                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                }).finally(() => {
                    setLoading(false)
                })
            }

        }
    })
    const resetState = () => {
        setPassengerList([])
        setFileAttachments([])
        setPassengerCount(0)
        setDeletedAttachments([])
    }

    const handleClientCreationModal = (is_close) => {
        set_client_creation_modal(is_close);
    };

    const handleSupplierCreationModal = (is_close) => {
        set_supplier_creation_modal(is_close);
    };

    // Get privileges details from local storage
    const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

    // Check if a specific privilege exists in User Management -> User
    const hasPrivilege = (privilegeName) =>
        getPrivilegesDetails().some(
            (item) =>
                item.module_name === "Process ECR" &&
                item.submodules?.["Flight Tickets"]?.privileges?.some((priv) => priv.name === privilegeName)
        );

    const Is_Flight_Tickets_Draft = hasPrivilege("Draft");
    const Is_Flight_Tickets_Submit = hasPrivilege("Submit");

    //FOR ADVANCE SEARCH FOR CLIENT STARTS
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null); // Tracks the selected row

    const handleAdvancedSearchOpen = () => { //CLEAR THE SELECTED ROW WHEN OPEN ADVANCE SEARCH MODAL BY SREERAJ 12-12-24
        setIsAdvancedSearchOpen(true);
        setSelectedRowId(null);
        formik.setFieldValue("FBD_client_id", "");
    }
    const handleAdvancedSearchClose = () => setIsAdvancedSearchOpen(false);

    // Handle select client from table
    const handleCheckboxChange = (row) => {
        // If the item is already selected,
        if (selectedRowId === row.id) {
            setSelectedRowId(null);
            formik.setFieldValue("FBD_client_id", "");
        } else {
            const selectedClient = client.find((type) => type.value === row.id);
            setSelectedRowId(selectedClient?.value || null); // Select
            formik.setFieldValue("FBD_client_id", selectedClient?.value || "");
            handleAdvancedSearchClose()//AUTO CLOSE THE  ADVANCE SEARCH MODAL BY SREERAJ 12-12-24
        }
    };

    // Dropdown change handler
    const handleDropdownChange = (event, newValue) => {
        const selectedClientValue = newValue?.value || "";
        formik.setFieldValue("FBD_client_id", selectedClientValue);
        // Update selectedRowId based on the dropdown value
        setSelectedRowId(selectedClientValue);
    };
    //FOR ADVANCE SEARCH FOR CLIENT ENDS


    //FOR ADVANCE SEARCH FOR SUPPLIER STARTS
    const [advanceSupplierSearchOpen, setIsadvanceSupplierSearchOpen] = useState(false);
    const [selectedSupplierRowId, setSelectedSupplierRowId] = useState(null); // Tracks the selected row

    const handleSupplierSearchOpen = () => {//CLEAR THE SELECTED ROW WHEN OPEN ADVANCE SEARCH MODAL BY SREERAJ 12-12-24
        setSelectedSupplierRowId(null);
        formik.setFieldValue("FBD_supplier_id", "");
        setIsadvanceSupplierSearchOpen(true);
    }
    const handleSupplierSearchClose = () => setIsadvanceSupplierSearchOpen(false);

    // Handle select client from table
    const handleSupplierCheckboxChange = (row) => {
        // If the item is already selected,
        if (selectedSupplierRowId === row.id) {
            setSelectedSupplierRowId(null);
            formik.setFieldValue("FBD_supplier_id", "");
        } else {
            const selectedItem = supplier.find((type) => type.value === row.id);
            setSelectedSupplierRowId(selectedItem?.value || null); // Select
            formik.setFieldValue("FBD_supplier_id", selectedItem?.value || "");
            handleSupplierSearchClose()//AUTO CLOSE THE  ADVANCE SEARCH MODAL BY SREERAJ 12-12-24
        }
    };

    // Dropdown change handler
    const handleSupplierDropdownChange = (event, newValue) => {
        const selectedValue = newValue?.value || "";
        formik.setFieldValue("FBD_supplier_id", selectedValue);
        // Update selectedRowId based on the dropdown value
        setSelectedSupplierRowId(selectedValue);
    };
    //FOR ADVANCE SEARCH FOR SUPPLIER ENDS
    const getPaymentStatusList = () => {
        get_payment_status_list().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.EPS_name,
                    value: item.EPS_id,
                };
            });
            setPaymentStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    return (

        <div className='flight_ticket_form_super_container'>
            <div >
                <div className='flight_ticket_form_sub_container'>
                    <div className='flight_ticket_form_single_row_alignment'>
                        <IoArrowBackSharp className='flight_ticket_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='flight_ticket_form_title'>{booking_id && (isView && !isAmendment) ? 'View Booking Details' : booking_id ? 'Update Booking Details' : 'Book Flight Ticket'}</p>
                    </div>
                </div>

                <div>
                    <form id="flightTicketForm" onSubmit={formik.handleSubmit}>
                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">General Info</p>
                            <div className='flight_ticket_form_sub_container_0'>
                                <div>
                                    <p className='flight_ticket_form_label'>PNR<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        autoFocus
                                        isDisabled={isView}
                                        name="FBD_PNR"
                                        placeholder={"Enter pnr number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_PNR}
                                        error={formik.touched.FBD_PNR &&
                                            Boolean(formik.errors.FBD_PNR)
                                        }
                                        helperText={
                                            formik.touched.FBD_PNR && formik.errors.FBD_PNR
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Airline Name<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_FlightName"
                                        placeholder={"Select a airline"}
                                        options={airlineLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_FlightName",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            airlineLists.find((type) =>
                                                type.value ===
                                                formik.values.FBD_FlightName
                                            ) || null
                                        }
                                        error={formik.touched.FBD_FlightName &&
                                            Boolean(formik.errors.FBD_FlightName)
                                        }
                                        helperText={
                                            formik.touched.FBD_FlightName && formik.errors.FBD_FlightName
                                        }
                                    />
                                    {/* <CustomTextField
                                        isDisabled={isView}
                                        name="FBD_FlightName"
                                        placeholder={"Enter Airline name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_FlightName}
                                        error={formik.touched.FBD_FlightName &&
                                            Boolean(formik.errors.FBD_FlightName)
                                        }
                                        helperText={
                                            formik.touched.FBD_FlightName && formik.errors.FBD_FlightName
                                        }
                                    /> */}
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Issue Date<span className='label_required_remark'>*</span></p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}
                                            name="FBD_issue_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_issue_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_issue_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_issue_date &&
                                                Boolean(formik.errors.FBD_issue_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_issue_date && formik.errors.FBD_issue_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Ticket Type<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_ticket_type_id"
                                        placeholder={"Select a ticket type"}
                                        options={ticketType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_ticket_type_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            ticketType.find((type) =>
                                                type.value ===
                                                formik.values.FBD_ticket_type_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_ticket_type_id &&
                                            Boolean(formik.errors.FBD_ticket_type_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_ticket_type_id &&
                                            formik.errors.FBD_ticket_type_id
                                        }
                                    />
                                </div>

                                <div>
                                    <div className='flight_ticket_form_label_with_action_container'>
                                        <p className='flight_ticket_form_label_with_action'>Customer/Client<span className='label_required_remark'>*</span></p>
                                        {!isView && (
                                            <>
                                                <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                                                    set_client_creation_modal(true)
                                                }} />
                                                <GrSearchAdvanced className="customSearchIcon" onClick={handleAdvancedSearchOpen} />
                                            </>
                                        )}

                                    </div>

                                    <CustomAutoCompleteDropdown
                                        isDisabled={isAmendment ? false : isView}
                                        name="FBD_client_id"
                                        placeholder={"Select a customer"}
                                        options={client}
                                        onChange={handleDropdownChange}
                                        // onChange={(event, newValue) => {
                                        //     formik.setFieldValue(
                                        //         "FBD_client_id",
                                        //         newValue ? newValue.value : ""
                                        //     );
                                        // }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            client.find((type) =>
                                                type.value ===
                                                formik.values.FBD_client_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_client_id &&
                                            Boolean(formik.errors.FBD_client_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_client_id &&
                                            formik.errors.FBD_client_id
                                        }
                                    />
                                </div>

                                <div>
                                    <div className='flight_ticket_form_label_with_action_container'>
                                        <p className='flight_ticket_form_label_with_action'>Supplier<span className='label_required_remark'>*</span></p>
                                        {!isView && (
                                            <>
                                                <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                                                    handleSupplierCreationModal(true)
                                                }} />

                                                <GrSearchAdvanced onClick={handleSupplierSearchOpen} className="customSearchIcon" />
                                            </>
                                        )}
                                    </div>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isAmendment ? false : isView}
                                        name="FBD_supplier_id"
                                        placeholder={"Select a supplier"}
                                        options={supplier}
                                        onChange={handleSupplierDropdownChange}
                                        // onChange={(event, newValue) => {
                                        //     formik.setFieldValue(
                                        //         "FBD_supplier_id",
                                        //         newValue ? newValue.value : ""
                                        //     );
                                        // }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            supplier.find((type) =>
                                                type.value ===
                                                formik.values.FBD_supplier_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_supplier_id &&
                                            Boolean(formik.errors.FBD_supplier_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_supplier_id &&
                                            formik.errors.FBD_supplier_id
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Sector<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="FBD_sector"
                                        placeholder={"Enter sector"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_sector}
                                        error={formik.touched.FBD_sector &&
                                            Boolean(formik.errors.FBD_sector)
                                        }
                                        helperText={
                                            formik.touched.FBD_sector && formik.errors.FBD_sector
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Trip Type<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_trip_type"
                                        placeholder={"Select a trip type"}
                                        options={tripType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_trip_type",
                                                newValue ? newValue.value : ""
                                            );
                                            if (newValue.value == '1') {
                                                formik.setFieldValue(
                                                    "FBD_return_date",
                                                    null
                                                );
                                            }

                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            tripType.find((type) =>
                                                type.value ===
                                                formik.values.FBD_trip_type
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_trip_type &&
                                            Boolean(formik.errors.FBD_trip_type)
                                        }
                                        helperText={
                                            formik.touched.FBD_trip_type &&
                                            formik.errors.FBD_trip_type
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Travel Date<span className='label_required_remark'>*</span></p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}

                                            name="FBD_travel_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_travel_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_travel_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_travel_date &&
                                                Boolean(formik.errors.FBD_travel_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_travel_date && formik.errors.FBD_travel_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Return Date</p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView ? isView : formik.values.FBD_travel_date && formik.values.FBD_trip_type == '1' ? true : formik.values.FBD_travel_date ? false : true}
                                            minDate={dayjs(formik.values.FBD_travel_date, "DD-MM-YYYY")}
                                            name="FBD_return_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_return_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_return_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_return_date &&
                                                Boolean(formik.errors.FBD_return_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_return_date && formik.errors.FBD_return_date
                                            } />

                                    </div>

                                </div>
                                <div>
                                    <p className='flight_ticket_form_label'>Adult<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_adult"
                                        placeholder={"Enter adult number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const adultCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = adultCount + formik.values.FBD_child + formik.values.FBD_infant;

                                            formik.setFieldValue("FBD_pax_count", amount);
                                            setPassengerCount(amount);
                                            setPassengerOtherCount({ ...passengerOtherCount, adult: adultCount });
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_adult}
                                        error={formik.touched.FBD_adult &&
                                            Boolean(formik.errors.FBD_adult)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.FBD_adult && formik.errors.FBD_adult
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Child</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_child"
                                        placeholder={"Enter child number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const childCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = childCount + formik.values.FBD_adult + formik.values.FBD_infant
                                            formik.setFieldValue("FBD_pax_count", amount);
                                            setPassengerCount(amount)
                                            setPassengerOtherCount({ ...passengerOtherCount, ['child']: childCount })
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_child}
                                        error={formik.touched.FBD_child &&
                                            Boolean(formik.errors.FBD_child)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.FBD_child && formik.errors.FBD_child
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Infants</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_infant"
                                        placeholder={"Enter infants number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const infantsCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = infantsCount + formik.values.FBD_adult + formik.values.FBD_child
                                            formik.setFieldValue("FBD_pax_count", amount);
                                            setPassengerCount(amount)
                                            setPassengerOtherCount({ ...passengerOtherCount, ['infants']: infantsCount })
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_infant}
                                        error={formik.touched.FBD_infant &&
                                            Boolean(formik.errors.FBD_infant)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.FBD_infant && formik.errors.FBD_infant
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>PAX Count</p>
                                    <CustomTextField
                                        isDisabled={true}
                                        name="FBD_pax_count"
                                        placeholder={"pax count"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_pax_count}
                                        error={formik.touched.FBD_pax_count &&
                                            Boolean(formik.errors.FBD_pax_count)
                                        }
                                        helperText={
                                            formik.touched.FBD_pax_count && formik.errors.FBD_pax_count
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Reference</p>
                                    <CustomTextField
                                        name="FBD_reference"
                                        isDisabled={isView}
                                        placeholder={"Enter reference"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_reference}
                                        error={formik.touched.FBD_reference &&
                                            Boolean(formik.errors.FBD_reference)
                                        }
                                        helperText={
                                            formik.touched.FBD_reference && formik.errors.FBD_reference
                                        }
                                    />
                                </div>

                                {/* <div>
                                    <p className='flight_ticket_form_label'>Agent Fee</p>
                                    <CustomTextField
                                        name="FBD_Agent_fee"
                                        isDisabled={isView}
                                        placeholder={"Enter reference"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_Agent_fee}
                                        error={formik.touched.FBD_Agent_fee &&
                                            Boolean(formik.errors.FBD_Agent_fee)
                                        }
                                        helperText={
                                            formik.touched.FBD_Agent_fee && formik.errors.FBD_Agent_fee
                                        }
                                    />
                                </div> */}
                            </div>
                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Passenger Info</p>
                            <FlightTicketPassengerTable passengerCount={passengerCount} passengerOtherCount={passengerOtherCount} passengerList={passengerList} setPassengerList={set_passenger_list} updatePassengerDetails={update_passenger_list} passengerDelete={delete_particular_passenger_details} />
                        </div>

                        {/* {passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.length > 0 ? 
                        <div className='flight_ticket_form_main_container_with_split'>
                            <div className='flight_ticket_form_main_container'>
                                <p className="flight_ticket_form_legend">Cancelled Fair Info</p>
                                <div className='flight_ticket_form_fair_main_container_column_template'>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Base Fare : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.reduce((prev, current) => prev + (current.base_fare ? current.base_fare : Number(current.FBPD_base_fare)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Total tax : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.reduce((prev, current) => prev + (Number(current.FBPD_jn_tax) + Number(current.FBPD_oc_tax) + Number(current.FBPD_yq_tax) + Number(current.FBPD_other_tax)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Airline/Supplier : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Service Fee : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Customer Net : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.filter((item) => item.FBPD_cancel_status == 1)?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                                    </div>

                                </div>
                            </div>
                            <div className='flight_ticket_form_main_container'>
                                <p className="flight_ticket_form_legend">Total Fair Info</p>
                                <div className='flight_ticket_form_fair_main_container_column_template'>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Base Fare : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.base_fare ? current.base_fare : Number(current.FBPD_base_fare)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Total tax : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (Number(current.FBPD_jn_tax) + Number(current.FBPD_oc_tax) + Number(current.FBPD_yq_tax) + Number(current.FBPD_other_tax)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Airline/Supplier : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Service Fee : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid'>
                                        <p className='flight_ticket_form_fair_text'>Customer Net : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                                    </div>

                                </div>
                            </div>
                                                        <div className='flight_ticket_form_main_container'>
                                <p className="flight_ticket_form_legend">Fair Info</p>
                                <div className='flight_ticket_form_fair_main_container_column_template'>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Base Fare : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.base_fare ? current.base_fare : Number(current.FBPD_base_fare)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Total tax : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (Number(current.FBPD_jn_tax) + Number(current.FBPD_oc_tax) + Number(current.FBPD_yq_tax) + Number(current.FBPD_other_tax)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Airline/Supplier : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Service Fee : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Customer Net : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                                    </div>

                                </div>
                            </div>
                        </div> : <div>
                            <div className='flight_ticket_form_main_container'>
                                <p className="flight_ticket_form_legend">Fair Info</p>
                                <div className='flight_ticket_form_fair_main_container_column_template'>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Base Fare : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.base_fare ? current.base_fare : Number(current.FBPD_base_fare)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Total tax : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (Number(current.FBPD_jn_tax) + Number(current.FBPD_oc_tax) + Number(current.FBPD_yq_tax) + Number(current.FBPD_other_tax)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Airline/Supplier : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Service Fee : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                    </div>
                                    <div className='flight_ticket_form_fair_grid_3'>
                                        <p className='flight_ticket_form_fair_text'>Customer Net : </p>
                                        <p className='flight_ticket_form_fair_text'>{passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                                    </div>

                                </div>
                            </div>
                        </div>} */}
                        {/* <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Fair Info</p>
                            <div className='flight_ticket_form_fair_main_container_column_template'>
                                <div className='flight_ticket_form_fair_grid_3'>
                                    <p className='flight_ticket_form_fair_text'>Base Fare : </p>
                                    <p className='flight_ticket_form_fair_text_value'>{passengerList?.reduce((prev, current) => prev + (current.base_fare ? current.base_fare : Number(current.FBPD_base_fare)), 0)}</p>
                                </div>
                                <div className='flight_ticket_form_fair_grid_3'>
                                    <p className='flight_ticket_form_fair_text'>Total tax : </p>
                                    <p className='flight_ticket_form_fair_text_value'>{passengerList?.reduce((prev, current) => prev + (Number(current.FBPD_jn_tax) + Number(current.FBPD_oc_tax) + Number(current.FBPD_yq_tax) + Number(current.FBPD_other_tax)), 0)}</p>
                                </div>
                                <div className='flight_ticket_form_fair_grid_3'>
                                    <p className='flight_ticket_form_fair_text'>Airline/Supplier : </p>
                                    <p className='flight_ticket_form_fair_text_value'>{passengerList?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                </div>
                                <div className='flight_ticket_form_fair_grid_3'>
                                    <p className='flight_ticket_form_fair_text'>Service Fee : </p>
                                    <p className='flight_ticket_form_fair_text_value'>{passengerList?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                </div>
                                <div className='flight_ticket_form_fair_grid_3'>
                                    <p className='flight_ticket_form_fair_text'>Customer Net : </p>
                                    <p className='flight_ticket_form_fair_text_value'>{passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                                </div>

                            </div>
                        </div> */}

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Additional Information</p>
                            <FlightTicketAdditionalInfo client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} />
                        </div>
                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Payment Information</p>
                            <div className='flight_ticket_form_sub_container_0'>
                                <div>
                                    <p className='flight_ticket_form_label'>Payment Status<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="payment_status_id"
                                        placeholder={"Select a payment status"}
                                        options={paymentStatus}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "payment_status_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            paymentStatus.find((type) =>
                                                type.value ===
                                                formik.values.payment_status_id
                                            ) || null
                                        }
                                        error={formik.touched.payment_status_id &&
                                            Boolean(formik.errors.payment_status_id)
                                        }
                                        helperText={
                                            formik.touched.payment_status_id && formik.errors.payment_status_id
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='flight_ticket_form_label'>Remark<span className='label_required_remark'>*</span></p>
                                    <CustomTextArea
                                        isDisabled={isView}
                                        rows={4}
                                        name="remark"
                                        placeholder={"Enter a remark"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.remark}
                                        error={formik.touched.remark &&
                                            Boolean(formik.errors.remark)
                                        }
                                        helperText={
                                            formik.touched.remark && formik.errors.remark
                                        }
                                    />
                                </div>
                            </div>
                            {/* <FlightPaymentInformation table_data={paymentInfoList} formik={formik} paymentAttachmnet={paymentInfoAttachment} updatePaymentAttachment={update_payment_attachment} no_form_privilage /> */}
                        </div>
                    </form>
                    <div className='flight_ticket_form_footer_actions'>
                        {!isView && (
                            <div className='flight_ticket_form_single_row_alignment' >
                                {!booking_id ? <button className='flight_ticket_form_reset_button' tabIndex='14' onClick={() => {
                                    formik.resetForm()
                                    resetState()
                                }}>Reset</button> : <></>}
                                {/* {Is_Flight_Tickets_Draft && <button disabled={loading}
                                    className='flight_ticket_form_save_button'
                                    type="submit"
                                    form="flightTicketForm" onClick={() => {
                                        setIsSaveAsDraft(true)
                                    }}>Save as Draft</button>}
                                {Is_Flight_Tickets_Submit && <button disabled={loading}
                                    className='flight_ticket_form_save_button'
                                    type="submit"
                                    form="flightTicketForm" onClick={() => {
                                        setIsSaveAsDraft(false)
                                    }}>Submit</button>} */}

                                {/* NEW CODE WITH LOADER */}
                                {Is_Flight_Tickets_Draft && (
                                    <button
                                        disabled={loading}
                                        className="flight_ticket_form_save_button"
                                        type="submit"
                                        form="flightTicketForm"
                                        onClick={() => {
                                            setIsSaveAsDraft(true); // Save as Draft action
                                        }}
                                    >
                                        {loading && isSaveAsDraft ? (
                                            <div className="submit_button_loader">
                                                <TailSpin
                                                    visible={loading}
                                                    height="20"
                                                    width="20"
                                                    color="white"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                />
                                            </div>
                                        ) : (
                                            "Save as Draft"
                                        )}
                                    </button>
                                )}

                                {Is_Flight_Tickets_Submit && (
                                    <button
                                        disabled={loading}
                                        className="flight_ticket_form_save_button"
                                        type="submit"
                                        form="flightTicketForm"
                                        onClick={() => {
                                            setIsSaveAsDraft(false); // Submit action
                                        }}
                                    >
                                        {loading && !isSaveAsDraft ? (
                                            <div className="submit_button_loader">
                                                <TailSpin
                                                    visible={loading}
                                                    height="20"
                                                    width="20"
                                                    color="white"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                />
                                            </div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                )}

                            </div>
                        )}
                    </div>


                </div>
            </div>
            {/* client registration modal */}
            <CustomModal
                maxWidth={"1500px"}
                open={client_creation_modal}
                handleClose={handleClientCreationModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClientCreationModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <ClientForm is_open_modal={true} close_modal={handleClientCreationModal} />
                }
                title={"Client Registartion"}
            />
            {/* {supplier registration modal} */}
            <CustomModal
                maxWidth={"1500px"}
                open={supplier_creation_modal}
                handleClose={handleSupplierCreationModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleSupplierCreationModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <SupplierForm is_open_modal={true} close_modal={handleSupplierCreationModal} />
                }
                title={"Supplier Registartion"}
            />
            <CustomDeleteConfirmModal module_name={'passenger'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deletePassenger} />

            {/* Advanced Search Modal */}
            {isAdvancedSearchOpen && <CustomModal
                maxWidth={"1500px"}
                open={isAdvancedSearchOpen}
                handleClose={handleAdvancedSearchClose}
                close={<IconButton
                    aria-label="close"
                    onClick={handleAdvancedSearchClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <AdvanceClientFilter is_open_modal={true} close_modal={handleAdvancedSearchClose} selectedRowId={selectedRowId} handleCheckboxChange={handleCheckboxChange} />
                }
                title={"Advance Filter"}
            />
            }
            {advanceSupplierSearchOpen && <CustomModal
                maxWidth={"1500px"}
                open={advanceSupplierSearchOpen}
                handleClose={handleSupplierSearchClose}
                close={<IconButton
                    aria-label="close"
                    onClick={handleSupplierSearchClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <AdvanceSupplierFilter is_open_modal={true} close_modal={handleSupplierSearchClose} selectedRowId={selectedSupplierRowId} handleCheckboxChange={handleSupplierCheckboxChange} />
                }
                title={"Advance Filter"}
            />}
        </div>
    )
}

export default FlightTicketForm