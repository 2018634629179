import { ApiConfig } from "./apiConfig";

export const get_email_context_lists = async () => {
    try {
      const response = await ApiConfig.post(`getEmailContext`);
      return response?.data;
    } catch (error) {
      throw error;
    }
};
export const get_email_with_context_lists=async ()=>{
  try {
    const response = await ApiConfig.post(`getEmailContextList`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}
export const create_new_email_with_context = async (data) => {
    try {
      const response = await ApiConfig.post(`saveMailContext`,data,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const delete_email_with_context=async(id)=>{
  try {
    const response = await ApiConfig.post(`deleteMailContext`,{context_id:id});
    return response;
  } catch (error) {
    throw error;
  }
}

export const get_tax_settings_lists=async()=>{
  try {
    const response = await ApiConfig.post(`taxSettingsList`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const update_tax_settings_details=async(data)=>{
  try {
    const response = await ApiConfig.post(`taxSettingsSave`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

///////////////////////////////

// Updated By - Sajin S Kumar
// Date - 25-11-2024
// Pupose - Airline CRUD Apis - >Master settings ->General settings

export const create_new_airline=async(data)=>{
  try {
    const response = await ApiConfig.post(`Create_airline`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const get_airline_list=async(data)=>{
  try {
    const response = await ApiConfig.post(`airline_list`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const get_airline_by_id=async(airline_id)=>{
  try {
    const response = await ApiConfig.post(`airlineViewByid`,{airline_id});
    return response;
  } catch (error) {
    throw error;
  }
}

export const update_airline_by_id=async (data)=>{
  try {
    const response = await ApiConfig.post(`updateAirline`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const delete_airline_by_id=async (airline_id)=>{
  try {
    const response = await ApiConfig.post(`deleteAirline`,{airline_id});
    return response;
  } catch (error) {
    throw error;
  }
}

////////////////////////