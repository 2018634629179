import { ApiConfig } from "./apiConfig";

export const get_payment_mode_list = async () => {
    try {
      const response = await ApiConfig.post(`EcrPaymentMethods`);
      return response.data?.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const get_payment_status_list = async () => {
    try {
      const response = await ApiConfig.post(`getPaymentStatus`);
      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }

  export const update_payment_details = async (data) => {
    try {
      const response = await ApiConfig.post(`paymnetUpdatesbybooking `,data,{ headers: { "Content-Type": "multipart/form-data" } });
      return response.data;
    } catch (error) {
      throw error;
    }
  }