import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../../components/custom_table/CustomTable';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../../api/lookupApis';
import './ticketDateChangeSummary.css'

function TicketDateChangeSummary({ datas }) {
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [cabinType, setCabinType] = useState([])
    useEffect(() => {
        if (datas) {
            getPassengerList()
            getPassengerTitle()
            getPassengerCabin()
        }
    }, [datas])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerCabin = () => {
        get_cabin_types().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CTL_name,
                    value: item.CTL_id,
                };
            });
            setCabinType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const Columns = [
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "New Ticket Number",
            dataIndex: "new_ticket_number",
            key: "new_ticket_number",
        },
        {
            title: "New Pnr Number",
            dataIndex: "new_pnr_number",
            key: "new_pnr_number",
        },
        {
            title: "New Travel Date",
            dataIndex: "new_travel_date",
            key: "new_travel_date",

        },
        {
            title: "New Return Date",
            dataIndex: "new_return_date",
            key: "new_return_date",

        },
        {
            title: "Date change Supplier Fee",
            dataIndex: "date_change_supplier_fee",
            key: "date_change_supplier_fee",
        },
        {
            title: "Date Change Service Fee",
            dataIndex: "date_change_service_fee",
            key: "date_change_service_fee",
        },
        {
            title: "Total Customer Net",
            dataIndex: "total_customer_net",
            key: "total_customer_net",
        },
    ];
    const row_data = () => {
        const result =
            datas &&
            datas.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.FBPD_Passanger_type)?.label;
                const passengerCabinType = cabinType.find(pt => pt.value == item.FBPD_cabin_type)?.label;
                const passengerTitleLable = passengerTitle.find(pt => pt.value == item.FBPD_title_id)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.FBPD_Passanger_type,
                    type_name: passengerTypeItem,
                    title: item.FBPD_title_id,
                    title_name: passengerTitleLable,
                    ticket_number: item.FBPD_ticket_number,
                    full_name: item.FBPD_FullName,
                    new_ticket_number:item?.date_changes[0]?.FDCD_ticketnumber,
                    new_pnr_number:item?.date_changes[0]?.FDCD_changed_PNR,
                    new_travel_date:item?.date_changes[0]?.FDCD_changed_travel_date,
                    new_return_date:item?.date_changes[0]?.FDCD_changed_return_date?datas?.date_changes[0]?.FDCD_changed_return_date:'-',
                    date_change_supplier_fee:item?.date_changes[0]?.FDCD_supplier_fee,
                    date_change_service_fee:item?.date_changes[0]?.FDCD_service_fee,
                    total_customer_net:item?.date_changes[0]?.Total_customer_net,
                };
            });
        return result;
    };
  return (
    <div>
        <CustomTable columns={Columns} rows={row_data()}/>
        <div>
                <div className='flight_ticket_date_change_summary_main_container'>
                    <p className="flight_ticket_date_change_summary_legend">Total Fair Info</p>
                    <div className='flight_ticket_date_change_summary_fair_grid'>
                        <p className='flight_ticket_date_change_summary_text'>Total Customer Net : </p>
                        <p className='flight_ticket_date_change_summary_text'>{datas?.reduce((prev, current) => prev + (Number(current?.date_changes[0]?.Total_customer_net)), 0)}</p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default TicketDateChangeSummary