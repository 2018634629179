import React, { useEffect } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './HotelFormStyle.css'
import { get_status } from '../../../../../api/statusApis';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useState } from 'react';
import { get_location_by_id, location_registation, update_location_by_id } from '../../../../../api/locationApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { get_country_lists, get_state_lists } from '../../../../../api/lookupApis';
import { get_hotels, get_hotels_details, hotel_registation, update_hotel_by_id } from '../../../../../api/hotelApis';
function HotelForm({ is_open_modal, close_modal }) {
    const navigate = useNavigate()
    let { hotel_id } = useParams();
    const location = useLocation()
    const [countryLists, setCountryLists] = useState([])
    const [stateLists, setStateLists] = useState([])

    const [isView, setIsView] = useState(null)
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_hotel") {
            setIsView(true)
        }
        if (hotel_id) {
            const payload = {
                hotel_id
            }
            get_hotels_details(payload).then((response) => {
                const response_data = response
                getStateLists(response_data.country_id)
                formik.setValues({
                    ...formik.values,
                    'hotel_name': response_data.hotelName,
                    'email': response_data.email,
                    'phone': response_data.phone,
                    'address': response_data.address,
                    'hotel_id': response_data.hotel_id,
                    'city': response_data.city_id,
                    'state': response_data.state_id,
                    'country': response_data.country_id,

                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [hotel_id])

    const getCountryLists = () => {
        get_country_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.country_name,
                    value: item.id,
                };
            });
            setCountryLists(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStateLists = (country_id) => {

        if (country_id) {
            get_state_lists(country_id).then((response) => {
                const data = response?.data?.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
                setStateLists(data)
            }).catch((error) => {
                setStateLists([])
                console.log(error)
            })
        } else {
            setStateLists([])
        }

    }

    useEffect(() => {
        getCountryLists()
    }, [])

    const formik = useFormik({

        initialValues: {
            'hotel_name': '',
            'address': '',
            'city': '',
            "state": "",
            "country": "",
            "email": "",
            "phone": "",
        },
        validationSchema: Yup.object({
            hotel_name: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            // address: Yup.string().required("Address is required"),
            // city: Yup.string()
            //     .required("City is required"),
            // state: Yup.string()
            //     .required("State is required"),
            // country: Yup.string()
            //     .required("Country is required"),
            phone: Yup.string()
                .trim()
                .nullable()
                .required("Phone is required")
                .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),

        }),
        onSubmit: (values) => {
            const formData = new FormData();
            // Set empty strings for null or undefined values
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    formData.append(key, values[key] ?? '');
                }
            }

            if (hotel_id) {
                update_hotel_by_id(hotel_id, formData).then((response) => {

                    if (response?.data?.code === 200) {
                        navigate(-1)
                        SuccessToast(response.data.message)
                    } else {
                        ErrorToast(response.data.message)
                    }
                }).catch((error) => {
                    ErrorToast(`Something went wrong`)
                    // let message = "";
                    // for (const key in error?.data?.errors) {
                    //     if (error.data.errors.hasOwnProperty(key)) {
                    //         const keyMessages = error.data.errors[key].map(message => message);
                    //         message = message.concat(keyMessages);
                    //     }
                    // }
                    // ErrorToast(message)
                })
            } else {
                hotel_registation(formData).then((response) => {
                    if (response?.data?.code === 200) {
                        if (is_open_modal) {
                            close_modal(false)
                        } else {
                            navigate(-1)
                        }
                        SuccessToast(response.data.message)
                    } else {
                        ErrorToast(`Something went wrong`)
                    }
                }).catch((error) => {
                    ErrorToast(`Something went wrong`)
                    // debugger
                    // let message = "";
                    // for (const key in error?.data?.errors) {
                    //     if (error.data.errors.hasOwnProperty(key)) {
                    //         const keyMessages = error.data.errors[key].map(message => message);
                    //         message = message.concat(keyMessages);
                    //     }
                    // }
                    // ErrorToast(message)
                })
            }
        }
    });

    // Reset form values and errors
    const ResetForm = () => {
        formik.resetForm();
    };

    return (
        <div className='locations_form_super_container'>
            <div >
                <div className='locations_form_sub_container'>
                    <div className='locations_form_single_row_alignment'>
                        <IoArrowBackSharp className='locations_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='locations_form_title'>{hotel_id && isView ? "View" : hotel_id ? 'Update' : "Add New"} Hotel</p>
                    </div>
                    {!isView && (
                        <div className='locations_form_single_row_alignment'>
                            {!hotel_id && (
                                <button className='locations_form_reset_button' onClick={() => {
                                    ResetForm()
                                }}>Reset</button>
                            )}

                            <button className='locations_form_save_button'
                                type="submit"
                                form="locationsform"
                            >Save</button>
                        </div>)}
                </div>

                <div>
                    <form id="locationsform" onSubmit={formik.handleSubmit}>
                        <div className='locations_form_main_container'>
                            <p className="locations_form_legend">Details</p>
                            <div>
                                <p className='locations_form_label'>Hotel Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="hotel_name"
                                    placeholder={"Enter office name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.hotel_name}
                                    error={formik.touched.hotel_name &&
                                        Boolean(formik.errors.hotel_name)
                                    }
                                    helperText={
                                        formik.touched.hotel_name && formik.errors.hotel_name
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Address</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="address"
                                    placeholder={"Enter address"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                // error={formik.touched.address &&
                                //     Boolean(formik.errors.address)
                                // }
                                // helperText={
                                //     formik.touched.address && formik.errors.address
                                // }
                                />
                            </div>

                            <div>
                                <p className='clients_form_label'>Country</p>
                                <CustomAutoCompleteDropdown
                                    isDisabled={isView}
                                    name="country"
                                    placeholder={"Select a country"}
                                    options={countryLists}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "country",
                                            newValue ? newValue.value : ""
                                        );
                                        getStateLists(newValue?.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        countryLists.find((country) =>
                                            country.value ===
                                            formik.values.country
                                        ) || null
                                    }
                                // error={
                                //     formik.touched.country &&
                                //     Boolean(formik.errors.country)
                                // }
                                // helperText={
                                //     formik.touched.country &&
                                //     formik.errors.country
                                // }
                                />
                            </div>
                            <div>
                                <p className='clients_form_label'>State</p>
                                <CustomAutoCompleteDropdown
                                    isDisabled={isView}
                                    name="state"
                                    placeholder={"Select a state"}
                                    options={stateLists}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "state",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        stateLists.find((state) =>
                                            state.value ===
                                            formik.values.state
                                        ) || null
                                    }
                                // error={
                                //     formik.touched.state &&
                                //     Boolean(formik.errors.state)
                                // }
                                // helperText={
                                //     formik.touched.state &&
                                //     formik.errors.state
                                // }
                                />
                            </div>
                            <div>
                                <p className='clients_form_label'>City</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="city"
                                    placeholder={"Enter city"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                // error={formik.touched.city &&
                                //     Boolean(formik.errors.city)
                                // }
                                // helperText={
                                //     formik.touched.city && formik.errors.city
                                // }
                                />
                            </div>


                            <div>
                                <p className='locations_form_label'>Email<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="email"
                                    placeholder={"Enter email"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    error={formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email && formik.errors.email
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Phone Number<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="phone"
                                    placeholder={"Enter phone number"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    error={formik.touched.phone &&
                                        Boolean(formik.errors.phone)
                                    }
                                    helperText={
                                        formik.touched.phone && formik.errors.phone
                                    }
                                />
                            </div>
                            {/* <div>
                                <p className='locations_form_label'>Status</p>
                                <CustomAutoCompleteDropdown
                                    isDisabled={isView}
                                    name="ld_statusId"
                                    placeholder={"Select a status"}
                                    options={state}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "ld_statusId",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        state.find((status) =>
                                            status.value ===
                                            formik.values.ld_statusId
                                        ) || null
                                    }
                                    error={
                                        formik.touched.ld_statusId &&
                                        Boolean(formik.errors.ld_statusId)
                                    }
                                    helperText={
                                        formik.touched.ld_statusId &&
                                        formik.errors.ld_statusId
                                    }
                                />
                            </div> */}
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default HotelForm