import React, { useEffect, useState } from 'react'
import './AuditLogFilterStyle.css'
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import CustomTextField from '../../../../components/custom_text_field/CustomTextField';
import CustomDatePicker from '../../../../components/custom_date_picker/CustomDatePicker';
import { RiFilterFill } from 'react-icons/ri';

function AuditLogFilter({ searchFilter }) {
    const [advanceFilter, setAdvanceFilter] = useState(false)

    const formik = useFormik({
        initialValues: {
            'event': '',
            'start_date': '',
            'end_date': '',
            'activity': '',
            'affected': '',
            'ip': '',
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'event': '',
                'start_date': '',
                'end_date': '',
                'activity': '',
                'affected': '',
                'ip': '',

            }
        })
        // searchFilter(null)
    }

    return (
        <div className='auditlog_filter_main_container'>
            <form id="auditlogFilterform" onSubmit={formik.handleSubmit}>
                <div className='auditlog_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="event"
                            placeholder={"Enter a event"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.event}
                        />
                    </div>

                    <div>
                        <CustomTextField
                            name="activity"
                            placeholder={"Enter a activity"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.activity}
                        />
                    </div>

                    <div>
                        <CustomTextField
                            name="affected"
                            placeholder={"Enter a affected area"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.affected}
                        />
                    </div>

                    <div>
                        <CustomTextField
                            name="ip"
                            placeholder={"Enter  IP"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ip}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className='client_filter_actions'>
                        <button className='client_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='client_filter_action_search'
                            type="submit"
                            form="auditlogFilterform"
                        >Search</button>
                        <div>
                            <RiFilterFill className='filter_action_icons' onClick={() => {
                                setAdvanceFilter(!advanceFilter)
                            }} />
                        </div>
                    </div>

                    {/* Date Range Selection */}
                    {advanceFilter && (
                        <div className='auditlog_filter_sub_date_selection_container'>
                            <div className='auditlog_filter_style_1'>
                                <p className='auditlog_filter_legent_style_1'> Start Date</p>
                                <CustomDatePicker
                                    no_border
                                    name="start_date"
                                    onChange={(date) => {
                                        formik.setFieldValue("start_date", date);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                    error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                    helperText={formik.touched.start_date && formik.errors.start_date}
                                />
                            </div>

                            <div className='auditlog_filter_date_separator'>
                                <p>--</p>
                            </div>

                            <div className='auditlog_filter_style_1'>
                                <p className='auditlog_filter_legent_style_1'> End Date</p>
                                <CustomDatePicker
                                    no_border
                                    name="end_date"
                                    disabled={!formik.values.start_date}
                                    minDate={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                    onChange={(date) => {
                                        formik.setFieldValue("end_date", date);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={dayjs(formik.values.end_date, "DD-MM-YYYY")}
                                    error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                    helperText={formik.touched.end_date && formik.errors.end_date}
                                />
                            </div>
                        </div>)}


                </div>

            </form>
        </div>
    )
}

export default AuditLogFilter