import { ApiConfig } from "./apiConfig";


export const get_cab_types = async () => {
    try {
        const response = await ApiConfig.post(`getCabTypes`);
        return response;
    } catch (error) {
        throw error;
    }
};
export const get_service_types = async () => {
    try {
        const response = await ApiConfig.post(`getCabServices`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const create_new_cab = async (data) => {
    try {
      const response = await ApiConfig.post(`addCabBooking`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  export const update_new_cab = async (data) => {
    try {
      const response = await ApiConfig.post(`updateCabBooking`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const getCabBooking_lists_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`getCabBookingsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
      return response;
    } catch (error) {
      throw error;
    }
  };
  export const get_CabBooking_details_by_id = async (data) => {
    try {
      const response = await ApiConfig.post(`getCabBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const delete_cab_by_id = async (id) => {
    try {
      const response = await ApiConfig.post(`deleteCabBooking`, id);
      return response.data;
  
    } catch (error) {
      throw error;
    }
  };

  export const cab_cancel = async (data) => {
    try {
      const response = await ApiConfig.post(`changeCabBookingStatus`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  export const approve_or_reject_cab = async (data) => {
    try {
      const response = await ApiConfig.post(`changeCabBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  export const cab_review_comment = async (booking_id) => {
    try {
      const response = await ApiConfig.post(`getCabBookingHistory`, booking_id);
      return response;
    } catch (error) {
      throw error;
    }
  };