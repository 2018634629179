import React from 'react'
import './loginPageStyle.css'
import LoginForm from './sub_components/login_form/LoginForm'
function LoginPage() {
  return (
    <div className='login_main_container'>
       <div className='login_sub_container'>
       <div className=''>
         <LoginForm/>
       </div>
       </div>
  </div>
  )
}

export default LoginPage