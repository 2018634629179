import { ApiConfig } from "./apiConfig";


export const create_new_Visa_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`addVisaBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const update_Visa_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`updateVisaBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_Visa_Booking_lists_with_pagination = async (data) => {
    try {
        const response = await ApiConfig.post(`getVisaBookingsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};
export const get_Visa_Booking_details_by_id = async (data) => {
    try {
        const response = await ApiConfig.post(`getVisaBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const delete_Visa_Booking_by_id = async (id) => {
    try {
        const response = await ApiConfig.post(`deleteVisaBooking`, id);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const Visa_Booking_cancel = async (data) => {
    try {
        const response = await ApiConfig.post(`changeVisaBookingStatus`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const approve_or_reject_Visa_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`changeVisaBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const Visa_Booking_review_comment = async (booking_id) => {
    try {
        const response = await ApiConfig.post(`getVisaBookingHistory`, booking_id);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_Visa_status = async () => {
    try {
        const response = await ApiConfig.post(`getStatusList`);
        return response;
    } catch (error) {
        throw error;
    }
};