import React from 'react'
import { IoMdArrowRoundForward } from "react-icons/io";
import user_sitting from '../assets/404_page_icon.png'
import './notFoundStyle.css'
import { useNavigate } from 'react-router-dom';
function NotFound() {
    const navigate=useNavigate()
    return (
        <div className='not_found_page_main_container'>
            <div className='not_found_page_sub_container'>
                <div className='not_found_page_sub_container_1'>
                    <div>
                        <img src={user_sitting} />
                    </div>
                    <div className='not_found_page_sub_container_2'>
                        <p className='not_fount_text_1'>404!</p>
                        <p className='not_fount_text_2'>404!</p>
                    </div>
                </div>
                <div>
                    <p className='not_fount_text_3'>OOPS....The page you are looking for is not found!!</p>
                    <div className='not_fount_text_with_back_container'>
                        <div className='not_fount_text_with_back' onClick={()=>{
                            navigate(-2)
                        }}>
                            <p className='not_found_go_back_text'>Go Back To Home</p>
                            <IoMdArrowRoundForward className='not_found_go_back_icon' />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NotFound