import { ApiConfig } from "../apiConfig";


export const create_new_Train_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`addTrainBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const update_Train_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`updateTrainBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_Train_Booking_lists_with_pagination = async (data) => {
    try {
        const response = await ApiConfig.post(`getTrainBookingsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};
export const get_Train_Booking_details_by_id = async (data) => {
    try {
        const response = await ApiConfig.post(`getTrainBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const delete_Train_Booking_by_id = async (id) => {
    try {
        const response = await ApiConfig.post(`deleteTrainBooking`, id);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const Train_Booking_cancel = async (data) => {
    try {
        const response = await ApiConfig.post(`changeTrainBookingStatus`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const approve_or_reject_Train_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`changeTrainBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const Train_Booking_review_comment = async (booking_id) => {
    try {
        const response = await ApiConfig.post(`getTrainBookingHistory`, booking_id);
        return response;
    } catch (error) {
        throw error;
    }
};
