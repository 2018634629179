import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    InputAdornment,
    IconButton
} from "@mui/material";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import CustomTextField from "../../../../../components/custom_text_field/CustomTextField";
import './changePasswordStyle.css'
import { user_update_password } from "../../../../../api/userApis";
import { ErrorToast, SuccessToast } from "../../../../../toast/toasts";
import { useNavigate } from "react-router-dom";
function ChangePassword({ id, onclose,isRedirect }) {
    const navigate=useNavigate()
    const handleMouseDownPassword = (event, fieldID, icon1Id, icon2Id) => {
        event.preventDefault();
        const passwordField = document.getElementById(fieldID);
        const passwordInVisiIcon = document.getElementById(icon2Id);
        const passwordVisiIcon = document.getElementById(icon1Id);
        if (passwordField && passwordInVisiIcon && passwordVisiIcon) {
            if (passwordField.type === "password") {
                passwordField.type = "text";
                passwordInVisiIcon.style.display = "none";
                passwordVisiIcon.style.display = "block";
            } else {
                passwordField.type = "password";
                passwordInVisiIcon.style.display = "block";
                passwordVisiIcon.style.display = "none";
            }
        }
    };
    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_new_password: "",
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required("New Password is required")
                .min(8, "Password must be at least 8 characters"),
            confirm_new_password: Yup.string()
                .required("Confirm Password is required")
                .oneOf([Yup.ref("new_password"), null], "New password and confirm password do not match."),
        }),
        onSubmit: (values) => {
            if(id){
                const formData = new FormData();
                for (const key in values) {
                    formData.append(key, values[key]);
                }
                formData.append('user_id', id);
                user_update_password(formData).then((response) => {
                    if (response.status == 200) {
                        onclose(false)
                        SuccessToast(response.data.message)
                        if(isRedirect){
                            navigate('/ecrs_portal/login')
                        }
                    }
                }).catch((error) => {
                    ErrorToast(error?.data?.message)
                })
            }
        },
    });
    return (
        <div className="update_password_form">
            <form id="update_password_form" onSubmit={formik.handleSubmit}>
                <p className="update_password_form_label">New Password<span className='label_required_remark'>*</span></p>
                <CustomTextField
                    autoFocus
                    id="new_password"
                    name="new_password"
                    type="password"
                    placeholder={"Enter password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.new_password}
                    error={formik.touched.new_password &&
                        Boolean(formik.errors.new_password)
                    }
                    helperText={
                        formik.touched.new_password && formik.errors.new_password
                    }
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end" >
                                <IconButton
                                    onMouseDown={(e) => {
                                        handleMouseDownPassword(
                                            e,
                                            "new_password",
                                            "np_visible",
                                            "np_not_visible"
                                        );
                                    }}
                                >
                                    <MdOutlineVisibilityOff
                                        id="np_visible"
                                        style={{ display: "none" }}
                                    />
                                    <MdOutlineVisibility id="np_not_visible" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <p className="update_password_form_label">Confirm New Password<span className='label_required_remark'>*</span></p>
                <CustomTextField
                    name="confirm_new_password"
                    type="password"
                    placeholder={"Enter password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_new_password}
                    error={formik.touched.confirm_new_password &&
                        Boolean(formik.errors.confirm_new_password)
                    }
                    helperText={
                        formik.touched.confirm_new_password && formik.errors.confirm_new_password
                    }
                />
                <div className='update_password_form_single_row_alignment'>
                    <button className='update_password_form_reset_button' onClick={()=>{
                        onclose(false)
                    }}>Cancel</button>

                    <button
                        className='update_password_form_update_button'
                        type="submit"
                        form="update_password_form">Update</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword