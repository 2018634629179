import React, { useEffect, useState } from 'react'
import './taxSettingsStyle.css'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { MdModeEdit } from "react-icons/md";
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField';
import { IconButton } from '@mui/material';
import { TiTickOutline } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import { get_tax_settings_lists, update_tax_settings_details } from '../../../../../../api/generalSettingApis';
import { ErrorToast, SuccessToast } from '../../../../../../toast/toasts';
function TaxSettings({ isSavePermission }) {
    const [taxSettingValues, setTaxSettingValues] = useState({
        'tax_rate': 0.00,
        'cgst': 0.00,
        'igst': 0.00,
        'sgst': 0.00,
        'tcs': 0.00,
        'tds': 0.00
    }
    )
    const [taxSettingValuesReset, setTaxSettingValuesReset] = useState({
        'tax_rate': 0.00,
        'cgst': 0.00,
        'igst': 0.00,
        'sgst': 0.00,
        'tcs': 0.00,
        'tds': 0.00
    }
    )
    const [taxSettingIsEdit, setTaxSettingIsEdit] = useState({
        'tax_rate': false,
        'cgst': false,
        'igst': false,
        'sgst': false,
        'tcs': false,
        'tds': false
    }
    )
    useEffect(() => {
        get_tax_settings_list()
    }, [])
    const get_tax_settings_list = () => {
        get_tax_settings_lists().then((response) => {
            if (response?.data?.code == 200) {
                if (response?.data?.data) {
                    const { tax_rate, cgst, igst, sgst, tcs, tds } = response?.data?.data
                    setTaxSettingValues({ ...taxSettingValues, tax_rate, cgst, igst, sgst, tcs, tds })
                    setTaxSettingValuesReset({ ...taxSettingValuesReset, tax_rate, cgst, igst, sgst, tcs, tds })
                    setTaxSettingIsEdit({ 'tax_rate': false, 'cgst': false, 'igst': false, 'sgst': false, 'tcs': false, 'tds': false })
                } else {
                    ErrorToast(response?.data?.message)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const updateTaxSettings = (name, value) => {
        if (name == 'tax_rate') {
            setTaxSettingValues({ ...taxSettingValues, 'tax_rate': value ? Number(value) : 0.00, 'cgst': value ? Number(value) / 2 : 0.00, 'sgst': value ? Number(value) / 2 : 0.00 })
        } else {
            setTaxSettingValues({ ...taxSettingValues, [name]: value ? Number(value) : 0.00 })
        }

    }
    const updateTaxSettingsEdit = (name) => {
        const hasAnyTrueValue = Object.values(taxSettingIsEdit).some(value => value === true);
        if (hasAnyTrueValue) {
            ErrorToast('Please save the changes.')
        } else {
            setTaxSettingIsEdit({ ...taxSettingIsEdit, [name]: true })
        }

    }
    const updateTaxSettingsCancelEdit = (name) => {
        updateTaxSettings(name, taxSettingValuesReset[name])
        setTaxSettingIsEdit({ 'tax_rate': false, 'cgst': false, 'igst': false, 'sgst': false, 'tcs': false, 'tds': false })
    }

    const saveTaxSettings = () => {
        update_tax_settings_details(taxSettingValues).then((response) => {
            if (response?.data?.code == 200) {
                SuccessToast('Sucessfully updated')
                get_tax_settings_list()
            } else {
                ErrorToast(response?.data?.message)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <div>
            <div className='tax_settings_form_main_container'>
                <p className="tax_settings_form_legend">Tax Details</p>
                <div>

                    <div className='tax_details_grid_row_alignment'>
                        <p>TAX Rate(%) : </p>
                        <CustomTextField
                            type={'number'}
                            isDisabled={!taxSettingIsEdit.tax_rate}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="tax_rate"
                            placeholder={"Enter tax rates"}
                            onChange={(e) => {
                                const { value, name } = e.target
                                updateTaxSettings(name, Number(value))
                            }}
                            value={taxSettingValues.tax_rate}
                        />
                        <div className='tax_details_field_action_row_alignment' style={{ display: !isSavePermission ? 'none' : '' }}>

                            {taxSettingIsEdit.tax_rate ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={() => {
                                            saveTaxSettings("tax_rate")
                                        }} />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={() => {
                                            updateTaxSettingsCancelEdit("tax_rate")
                                        }} />
                                    </IconButton>
                                </> : <IconButton>
                                    <MdModeEdit className='tax_details_edit_icon' onClick={() => {
                                        updateTaxSettingsEdit("tax_rate")
                                    }} />
                                </IconButton>
                            }

                        </div>


                    </div>

                    <div className='tax_details_grid_row_alignment'>
                        <p>CGST(%) : </p>
                        <CustomTextField
                            type={'number'}
                            isDisabled={true}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="cgst"
                            placeholder={"Enter cgst"}
                            // onChange={(e) => {
                            //     const { value, name } = e.target
                            //     updateTaxSettings(name, value)
                            // }}
                            value={taxSettingValues.cgst}
                        />
                        {/* <div className='tax_details_field_action_row_alignment'>

                            {taxSettingIsEdit.cgst ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={()=>{
                                            saveTaxSettings("cgst")
                                        }} />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={()=>{
                                            updateTaxSettingsCancelEdit("cgst")
                                        }} />
                                    </IconButton>
                                </>
                                : <> <IconButton>
                                    <MdModeEdit className='tax_details_edit_icon'  onClick={() => {
                                        updateTaxSettingsEdit("cgst")
                                    }} />
                                </IconButton></>}
                        </div> */}

                    </div>

                    <div className='tax_details_grid_row_alignment'>
                        <p>SGST(%) : </p>
                        <CustomTextField
                            isDisabled={true}
                            type={"number"}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="sgst"
                            placeholder={"Enter sgst"}
                            // onChange={(e) => {
                            //     const { value, name } = e.target
                            //     updateTaxSettings(name, value)
                            // }}
                            value={taxSettingValues.sgst}
                        />
                        {/* <div className='tax_details_field_action_row_alignment'>

                            {taxSettingIsEdit.sgst ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={()=>{
                                            saveTaxSettings("sgst")
                                        }}  />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={()=>{
                                            updateTaxSettingsCancelEdit("sgst")
                                        }}  />
                                    </IconButton>
                                </>
                                : <>
                                    <IconButton>
                                        <MdModeEdit className='tax_details_edit_icon'  onClick={() => {
                                            updateTaxSettingsEdit("sgst")
                                        }} />
                                    </IconButton></>}
                        </div> */}

                    </div>

                    <div className='tax_details_grid_row_alignment'>
                        <p>IGST(%) : </p>
                        <CustomTextField
                            isDisabled={!taxSettingIsEdit.igst}
                            type={"number"}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="igst"
                            placeholder={"Enter igst"}
                            onChange={(e) => {
                                const { value, name } = e.target
                                updateTaxSettings(name, Number(value))
                            }}
                            value={taxSettingValues.igst}
                        />
                        <div className='tax_details_field_action_row_alignment' style={{ display: !isSavePermission ? 'none' : '' }}>

                            {taxSettingIsEdit.igst ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={() => {
                                            saveTaxSettings("igst")
                                        }} />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={() => {
                                            updateTaxSettingsCancelEdit("igst")
                                        }} />
                                    </IconButton>
                                </>
                                : <>
                                    <IconButton>
                                        <MdModeEdit className='tax_details_edit_icon' onClick={() => {
                                            updateTaxSettingsEdit("igst")
                                        }} />
                                    </IconButton>
                                </>}
                        </div>

                    </div>

                    <div className='tax_details_grid_row_alignment'>
                        <p>TCS(%) : </p>
                        <CustomTextField
                            isDisabled={!taxSettingIsEdit.tcs}
                            type={"number"}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="tcs"
                            placeholder={"Enter tcs"}
                            onChange={(e) => {
                                const { value, name } = e.target
                                updateTaxSettings(name, Number(value))
                            }}
                            value={taxSettingValues.tcs}
                        />
                        <div className='tax_details_field_action_row_alignment' style={{ display: !isSavePermission ? 'none' : '' }}>

                            {taxSettingIsEdit.tcs ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={() => {
                                            saveTaxSettings("tcs")
                                        }} />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={() => {
                                            updateTaxSettingsCancelEdit("tcs")
                                        }} />
                                    </IconButton>
                                </>
                                : <>
                                    <IconButton>
                                        <MdModeEdit className='tax_details_edit_icon' onClick={() => {
                                            updateTaxSettingsEdit("tcs")
                                        }} />
                                    </IconButton>
                                </>}
                        </div>

                    </div>

                    <div className='tax_details_grid_row_alignment' >
                        <p>TDS(%) : </p>
                        <CustomTextField
                            isDisabled={!taxSettingIsEdit.tds}
                            type={"number"}
                            inputProps={{ inputProps: { min: 0.00, step: ".01" } }}
                            name="tds"
                            placeholder={"Enter tds"}
                            onChange={(e) => {
                                const { value, name } = e.target
                                updateTaxSettings(name, Number(value))
                            }}
                            value={taxSettingValues.tds}
                        />
                        {/* // Logic added By Sajin (22-11-2024) */}
                        <div className='tax_details_field_action_row_alignment' style={{ display: !isSavePermission ? 'none' : '' }}>  

                            {taxSettingIsEdit.tds ?
                                <>
                                    <IconButton>
                                        <TiTickOutline className='tax_details_save_icon' onClick={() => {
                                            saveTaxSettings("tds")
                                        }} />
                                    </IconButton>
                                    <IconButton>
                                        <MdOutlineCancel className='tax_details_cancel_icon' onClick={() => {
                                            updateTaxSettingsCancelEdit("tds")
                                        }} />
                                    </IconButton>
                                </>
                                : <>
                                    <IconButton>
                                        <MdModeEdit className='tax_details_edit_icon' onClick={() => {
                                            updateTaxSettingsEdit("tds")
                                        }} />
                                    </IconButton>
                                </>}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default TaxSettings