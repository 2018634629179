import React, { useEffect } from 'react'
import './locationStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import { delete_location_by_id, get_locations_with_pagination } from '../../../../api/locationApis';
import { useState } from 'react';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import LocationFilter from './location_filter/LocationFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';
import CustomDeleteConfirmModal from '../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';

function Locations() {
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings`
  const navigate = useNavigate()
  const [locationListing, setLocationListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const [searchFilter, setSearchFilter] = useState(null)
  const [selectedId, setSelectedId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  useEffect(() => {
    getLocations(pageNumber, searchFilter)
  }, [pageNumber, totalItemPerPage])
  const getLocations = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key]);
      }
    }
    get_locations_with_pagination(formData).then((response) => {
      setLocationListing(response.data.data)
      setTotalPages(response.data.last_page)
    }).catch((error) => {
      console.log(error)
    })
  }

  // Get privileges details from local storage
  const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // Check if a specific privilege exists in"Master Settings" -> Locations 
  const hasPrivilege = (privilegeName) =>
    getPrivilegesDetails().some(
      (item) =>
        item.module_name === "Master Settings" &&
        item.submodules?.["Locations"]?.privileges?.some((priv) => priv.name === privilegeName)
    );
  const Is_Locations_View = hasPrivilege("View");
  const Is_Locations_Add = hasPrivilege("Add");
  const Is_Locations_Edit = hasPrivilege("Edit");
  const Is_Locations_Delete = hasPrivilege("Delete");

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact Person Name",
      dataIndex: "contact_person_name",
      key: "contact_person_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      wrapper: (row) => (
        <div >
          <p style={{ color: row.status == 'Active' ? '#0A9E02' : "#FF0000" }}>{row.status}</p>
        </div>
      ),
    },
    (Is_Locations_View || Is_Locations_Edit || Is_Locations_Delete) && {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }} >
          {Is_Locations_View && <PiEye className='locations_action_view' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/view_location`)
          }} />}
          {Is_Locations_Edit && <BiEditAlt className='locations_action_edit' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/update_location`)
          }} />}
          {Is_Locations_Delete && <MdOutlineDeleteOutline className='locations_action_delete' onClick={() => {
            setSelectedId(row.id)
            setDeleteConfirmation(true)
          }} />}

        </div>
      ),
    },
  ];
  const deleteLocation = () => {
    if (selectedId) {
      delete_location_by_id(selectedId).then((response) => {
        if (response.status == 200) {
          handleDeleteModalClose(false)
          SuccessToast(response.data.message)
          getLocations(pageNumber)
        }
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
      })
    }
  }
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };

  const datas = () => {
    const result =
      locationListing &&
      locationListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.ld_id,
          name: item.ld_name,
          contact_person_name: item.ld_contact_person_name,
          email: item.ld_email_id,
          phone: item.ld_phone,
          address: item.ld_address,
          status: item.status_name,
        };
      });
    return result;
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }
  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      getLocations(pageNumber, value)
    } else {
      getLocations(1, value)
      setPageNumber(1)
    }
  }
  return (
    <div className='locations_module_main_container'>
      <p className='locations_title'>Locations</p>
      <div className='locations_module_filter'>
        <LocationFilter searchFilter={searchfilter} />
        {Is_Locations_Add && <button className='locations_add_button' onClick={() => {
          navigate(baseRouteUrl + '/create_new_location')
        }}> Add New</button>}
      </div>
      <div className='locations_table_main_container'>
        <CustomTable columns={Columns} rows={datas()} />
      </div>
      <div>
        <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
      </div>
      <CustomDeleteConfirmModal module_name={'location'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deleteLocation} />
    </div>
  )
}

export default Locations