import React, { useEffect, useState } from 'react'
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination'
import CustomTable from '../../../../components/custom_table/CustomTable'
import CustomDeleteConfirmModal from '../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal'
import { useNavigate } from 'react-router-dom'
import { LocalStorageServices } from '../../../../services/localStorageServices'
import { PiEye } from 'react-icons/pi'
import { BiEditAlt } from 'react-icons/bi'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { ErrorToast, SuccessToast } from '../../../../toast/toasts'
import { delete_hotel_by_id, get_hotel_lists_with_pagination, get_hotels } from '../../../../api/hotelApis'
import './HotelOrResortsStyle.css'
import HotelResortFilter from './hotel_resort_filter/HotelResortFilter'

function HotelOrResorts() {
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/process_ecr`
  const navigate = useNavigate()

  const [hotelListing, setHotelListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)

  const [searchFilter, setSearchFilter] = useState(null)
  const [selectedId, setSelectedId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    getHotels(pageNumber, searchFilter)
  }, [pageNumber, totalItemPerPage, deleteConfirmation])

  const getHotels = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key]);
      }
    }
    get_hotel_lists_with_pagination(formData).then((response) => {
      if (response?.data.data.data.length > 0) {
        setHotelListing(response.data.data.data)
        setTotalPages(response.data.data.last_page)
      } else {
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1)
        }
        setHotelListing([])
      }
    }).catch((error) => {
      console.log(error)
    })
    //   get_hotels(formData).then((response) => {
    //     if (response?.length > 0) {
    //       setHotelListing(response)
    //       // setTotalPages(response)
    //     } else {
    //       setHotelListing([])
    //       if (pageNumber > 1) {
    //         setPageNumber(pageNumber - 1)
    //       }
    //     }
    //   }).catch((error) => {
    //     console.log(error)
    //   })
  }

  // Get privileges details from local storage
  const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // Check if a specific privilege exists in"Process ecr" -> Hotels/resorts
  const hasPrivilege = (privilegeName) =>
    getPrivilegesDetails().some(
      (item) =>
        item.module_name === "Process ECR" &&
        item.submodules?.["Hotel/Resorts"]?.privileges?.some((priv) => priv.name === privilegeName)
    );
  const is_Hotel_view = hasPrivilege("View");
  const is_Hotel_add = hasPrivilege("Add");
  const is_Hotel_Edit = hasPrivilege("Edit");
  const is_Hotel_Delete = hasPrivilege("Delete");

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },


    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   key: "actions",
    //   action: (row) => (

    //     <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }} >
    //       {is_Hotel_view && <PiEye className='locations_action_view' onClick={() => {
    //         navigate(baseRouteUrl + `/${row.id}/view_hotel`)
    //       }} />}
    //       {is_Hotel_Edit && <BiEditAlt className='locations_action_edit' onClick={() => {
    //         navigate(baseRouteUrl + `/${row.id}/update_hotel`)
    //       }} />}
    //       {is_Hotel_Delete && <MdOutlineDeleteOutline className='hotel_action_delete' onClick={() => {
    //         setSelectedId(row.id)
    //         setDeleteConfirmation(true)
    //       }} />}

    //     </div>

    //   ),
    // },
  ];
  // Conditionally add the "Actions" column if at least one of the conditions is true
  if (is_Hotel_view || is_Hotel_Edit || is_Hotel_Delete) {
    Columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {is_Hotel_view && (
            <PiEye
              className="locations_action_view"
              onClick={() => navigate(baseRouteUrl + `/${row.id}/view_hotel`)}
            />
          )}
          {is_Hotel_Edit && (
            <BiEditAlt
              className="locations_action_edit"
              onClick={() => navigate(baseRouteUrl + `/${row.id}/update_hotel`)}
            />
          )}
          {is_Hotel_Delete && (
            <MdOutlineDeleteOutline
              className="hotel_action_delete"
              onClick={() => {
                setSelectedId(row.id);
                setDeleteConfirmation(true);
              }}
            />
          )}
        </div>
      ),
    });
  }
  const deleteHotel = () => {
    if (selectedId) {
      const payload = {
        hotel_id: selectedId
      }
      delete_hotel_by_id(payload).then((response) => {
        if (response.status === true) {
          handleDeleteModalClose(false)
          SuccessToast(response.data.message)
          // get_hotels()
          //   .then((hotelsResponse) => {
          //     if (hotelsResponse.length > 0) {
          //       setHotelListing(hotelsResponse);
          //     } else {
          //       setPageNumber(prevPage => Math.max(1, prevPage - 1));
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("Error fetching hotels:", error);
          //   });
        }
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
      })
    }
  }
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };

  const datas = () => {
    const result =
      hotelListing &&
      hotelListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.hotel_id,
          hotelName: item.hotelName,
          email: item.email,
          phone: item.phone,
          address: item.address,
          city: item.city,
          state: item.state,
          country: item.country,
        };
      });
    return result;
  };

  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }

  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      getHotels(pageNumber, value)
    } else {
      getHotels(1, value)
      setPageNumber(1)
    }
  }


  return (
    <div className='hotel_module_main_container'>
      <p className='hotel_title'>Hotel / Resorts</p>
      <div>
        <div className='hotel_module_header'>
          <div className='hotel_module_filter'>
            <HotelResortFilter searchFilter={searchfilter} />
          </div>
          {is_Hotel_add && <button className='hotel_add_button' onClick={() => {
            navigate(baseRouteUrl + `/create_new_hotel`)
          }}> Add New</button>}
        </div>
        <div className='hotel_table_main_container'>
          <CustomTable columns={Columns} rows={datas()} />
        </div>
        <div>
          <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
        </div>
      </div>
      <CustomDeleteConfirmModal module_name={'HotelOrResorts'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deleteHotel} />
    </div>
  )
}

export default HotelOrResorts
