import { ApiConfig } from "./apiConfig";

//LOCAL url 1=modulePrivilegeMapList
//LOCAL url 2=getRolePrivileMapList

export const get_privilages_list = async (payload) => {
  try {
    const response = await ApiConfig.post(`getRolePrivileMapList`, payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const update_role_privilages = async (data) => {
  try {
    const response = await ApiConfig.post(`rolePrivilegeMap`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};
//FRO GET PRIVILEGES BY ROLE ID
export const get_privilages = async () => {
  try {
    const response = await ApiConfig.post(`getUserPrivileges`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};