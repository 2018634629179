import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../../components/custom_table/CustomTable';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../../api/lookupApis';
import './ticketCancellationSummary.css'
function TicketCancellationSummary({ datas }) {
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [cabinType, setCabinType] = useState([])
    useEffect(() => {
        if (datas) {
            getPassengerList()
            getPassengerTitle()
            getPassengerCabin()
        }
    }, [datas])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerCabin = () => {
        get_cabin_types().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CTL_name,
                    value: item.CTL_id,
                };
            });
            setCabinType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    console.log(datas)
    const Columns = [
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Cabin",
            dataIndex: "cabin_name",
            key: "cabin_name",

        },
        {
            title: "Total Customer Net",
            dataIndex: "total_customer_net",
            key: "total_customer_net",
        },
        {
            title: "Cancellation Supplier Fee",
            dataIndex: "cancellation_supplier_fee",
            key: "cancellation_supplier_fee",
        },
        {
            title: "Cancellation Service Fee",
            dataIndex: "cancellation_service_fee",
            key: "cancellation_service_fee",
        },
        {
            title: "Customer Refund amount",
            dataIndex: "customer_refund_amount",
            key: "customer_refund_amount",
        },
    ];

    const row_data = () => {
        const result =
            datas &&
            datas.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.FBPD_Passanger_type)?.label;
                const passengerCabinType = cabinType.find(pt => pt.value == item.FBPD_cabin_type)?.label;
                const passengerTitleLable = passengerTitle.find(pt => pt.value == item.FBPD_title_id)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.FBPD_Passanger_type,
                    type_name: passengerTypeItem,
                    title: item.FBPD_title_id,
                    title_name: passengerTitleLable,
                    ticket_number: item.FBPD_ticket_number,
                    full_name: item.FBPD_FullName,
                    base_fare: Number(item.FBPD_base_fare),
                    total_customer_net: Number(item.FBPD_customer_net),
                    cabin_name: passengerCabinType,
                    cancellation_comment: item?.cancellation_comment,
                    cancellation_reason: item?.cancellation_reason,
                    cancellation_supplier_fee: item?.cancellations[0]?.refund_supplier_amount,
                    cancellation_service_fee: item?.cancellations[0]?.refund_service_fee,
                    customer_refund_amount: item?.cancellations[0]?.refund_customer_net,
                };
            });
        return result;
    };

    return (
        <div>
            <CustomTable columns={Columns} rows={row_data()} />
            <div>
                <div className='flight_ticket_cancellation_summary_main_container'>
                    <p className="flight_ticket_cancellation_summary_legend">Total Fair Info</p>
                    <div className='flight_ticket_cancellation_summary_fair_grid'>
                        <p className='flight_ticket_cancellation_summary_text'>Total Cutomer Net : </p>
                        <p className='flight_ticket_cancellation_summary_text'>{datas?.reduce((prev, current) => prev + (Number(current?.FBPD_customer_net)), 0)}</p>
                    </div>
                    <div className='flight_ticket_cancellation_summary_fair_grid'>
                        <p className='flight_ticket_cancellation_summary_text'>Total Refund Amount : </p>
                        <p className='flight_ticket_cancellation_summary_text'>{datas?.reduce((prev, current) => prev + (Number(current?.cancellations[0]?.refund_customer_net)), 0)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketCancellationSummary