import { ApiConfig } from "./apiConfig";

export const get_users_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`user_list`,data,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const get_user_by_id = async (user_id) => {
    try {
      const response = await ApiConfig.post(`userByid`,{id:user_id}, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};
export const user_registration = async (data) => {
    try {
      const response = await ApiConfig.post(`user_register`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_user_details = async (data) => {
    try {
      const response = await ApiConfig.post(`usersupdate`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const user_delete = async (user_id) => {
    try {
      const response = await ApiConfig.delete(`usersDelete/${user_id}`);
      return response;
    } catch (error) {
      throw error;
    }
};

export const user_update_password = async (data) => {
    try {
        const response = await ApiConfig.post(`update-password`,data, { headers: { "Content-Type": "multipart/form-data" }});
        return response;
      } catch (error) {
        throw error;
      }
};

export const user_search_filter = async (data) => {
    try {
        const response = await ApiConfig.post(`user_list`,data, { headers: { "Content-Type": "multipart/form-data" }});
        return response;
      } catch (error) {
        throw error;
      }
};

export const user_details_by_user_id=async(user_id)=>{
  try {
    const response = await ApiConfig.post(`userByid`,{id:user_id}, { headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
}

export const user_details_update_by_user_id=async(data)=>{
  try {
    const response = await ApiConfig.post(`Profile_update`,data, { headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
}

export const get_user_notification=async()=>{
  try {
    const response = await ApiConfig.post(`notifications`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const get_user_latest_notification=async()=>{
  try {
    const response = await ApiConfig.post(`latestNotifications`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const user_notification_read=async(notification_id)=>{
  try {
    const response = await ApiConfig.post(`readNotification`,{notification_id:notification_id},{ headers: { "Content-Type": "multipart/form-data" }});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const user_forget_password_email=async(data)=>{
  try {
    const response = await ApiConfig.post(`forgortPassword`,data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const user_reset_password=async(data)=>{
  try {
    const response = await ApiConfig.post(`resetPassword`,data);
    return response.data;
  } catch (error) {
    throw error;
  }
}