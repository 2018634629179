import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './AdvanceSupplierFilterStyle.css'
import { useFormik } from 'formik';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import CustomBottomPagination from '../../../../../components/custom_bottom_pagination/CustomBottomPagination';
import AdvanceFilterSupplierTable from './AdvanceFilterSupplierTable';
import { get_supplier_with_pagination } from '../../../../../api/supplierApis';

function AdvanceSupplierFilter({ is_open_modal, close_modal, selectedRowId, handleCheckboxChange }) {

    const navigate = useNavigate()
    const { client_id } = useParams()
    const [isView, setIsView] = useState(false)
    const [listing, setListing] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItemPerPage, setTotalItemPerPage] = useState(10)

    // useEffect(() => {
    //     getDataList(pageNumber, null)
    // }, [pageNumber, totalItemPerPage])

    const getDataList = (pageNumber, filterValue) => {
        const formData = new FormData();
        formData.append('page', pageNumber);
        formData.append('pageItem_count', totalItemPerPage);
        if (filterValue) {
            for (const key in filterValue) {
                formData.append(key, filterValue[key]);
            }
        }
        get_supplier_with_pagination(formData).then((response) => {
            setListing(response.data.data)
            setTotalPages(response.data.last_page)
        }).catch((error) => {
            let message = "";
            for (const key in error?.data?.errors) {
                if (error?.data?.errors.hasOwnProperty(key)) {
                    const keyMessages = error?.data?.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }

    const formik = useFormik({
        initialValues: {
            'name': '',
            'supplier_email': '',
            'supplier_phone': '',

        },
        onSubmit: (values) => {
            if (values) {
                getDataList(pageNumber > 1 ? 1 : pageNumber, values)
                setPageNumber(pageNumber > 1 ? 1 : pageNumber)
            } else {
                getDataList(1, values)
                setPageNumber(1)
            }
        }
    });

    useEffect(() => {
        //CHECK THE SEARCH FILED HAVE ATEAST ONE VALUE
        const hasAtLeastOneValue =
            !!formik.values?.name ||
            !!formik.values?.supplier_email ||
            !!formik.values?.supplier_phone;

        if (hasAtLeastOneValue) { //"At least one field has a value
            getDataList(pageNumber > 1 ? 1 : pageNumber, formik.values);
            setPageNumber(pageNumber > 1 ? 1 : pageNumber)
        } else {
            getDataList(pageNumber, null)
        }

    }, [pageNumber, totalItemPerPage])


    const ResetForm = () => {
        formik.setValues({
            ...formik.values, ...{
                'name': '',
                'supplier_email': '',
                'supplier_phone': '',
            }
        })
        getDataList(1, null)
        setPageNumber(1)
        close_modal(false)
    }

    const updatePagination = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const updateItemPageCount = (pageItemCount) => {
        setTotalItemPerPage(pageItemCount)
    }


    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Supplier Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "supplier_email",
            key: "supplier_email",
        },
        {
            title: "Phone Number",
            dataIndex: "supplier_phone",
            key: "supplier_phone",
        },


    ];

    const datas = () => {
        const result =
            listing &&
            listing?.map((item, index) => {
                return {
                    key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
                    id: item.SD_id,
                    name: item.SD_name,
                    supplier_email: item.SD_email,
                    supplier_phone: item.SD_phone,
                };
            });
        return result;
    };
    return (
        <div className={is_open_modal ? 'clients_form_super_container_modal' : 'clients_form_super_container'}>
            <div >
                {!is_open_modal && (
                    <div className='clients_form_action_sub_container'>
                        <div className='clients_form_single_row_alignment'>
                            <IoArrowBackSharp className='clients_form_back' onClick={() => {
                                navigate(-1)
                            }} />
                        </div>
                    </div>
                )}


                <div>
                    <form id="supplierForm" onSubmit={formik.handleSubmit}>
                        <div className='clients_form_main_container'>
                            <p className="clients_form_legend">Details</p>
                            <div className='clients_form_sub_container_0'>
                                <div>
                                    <p className='clients_form_label'>Supplier Name</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="name"
                                        placeholder={"Enter supplier name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}

                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Email</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="supplier_email"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.supplier_email}

                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Phone Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="supplier_phone"
                                        type={"number"}
                                        placeholder={"Enter phone number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.supplier_phone}

                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {is_open_modal && (
                    <div>
                        <div className='clients_form_single_row_alignment_modal'>
                            {/* 
                            <button className='clients_form_reset_button' onClick={() => {
                                // close_modal(false);
                                ResetForm()
                            }}>Cancel</button> */}


                            <button className='clients_form_reset_button' onClick={() => {
                                ResetForm()
                            }}>Close</button>
                            <button className='clients_form_save_button'
                                type="submit"
                                form="supplierForm"
                            >Search</button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                <AdvanceFilterSupplierTable columns={Columns} rows={datas()} selectedRowId={selectedRowId} handleCheckboxChange={handleCheckboxChange} />
            </div>
            <div>
                <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
            </div>
        </div>
    )
}

export default AdvanceSupplierFilter
