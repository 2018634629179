import React from 'react'
import './flightCancellationChangeDetails.css'
function FlightCancellationChangeDetails({ datas }) {
  return (
    <div className='flight_cancel_details_main_container'>
    <div className='flight_cancel_details_main_legent_container'>
        <p className="flight_cancel_details_legend">Ticket details</p>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Booking Id</p>
            <p className='flight_cancel_details_label'>{datas?.cancellation_details[0]?.FBCD_booking_id}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Passenger Name</p>
            <p className='flight_cancel_details_label'>{datas?.title_name +" " + datas?.full_name}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Passenger Type</p>
            <p className='flight_cancel_details_label'>{datas?.type_name}</p>
        </div >
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Trip Type</p>
            <p className='flight_cancel_details_label'>{datas?.cancellation_details[0]?.trip_type}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Ticket Type</p>
            <p className='flight_cancel_details_label'>{datas?.cancellation_details[0]?.ticket_type}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Cabin Name</p>
            <p className='flight_cancel_details_label'>{datas?.cabin_name}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Reason</p>
            <p className='flight_cancel_details_label'>{datas?.cancellation_details[0]?.cancellation_reason}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Comment</p>
            <p className='flight_cancel_details_label'>{datas?.cancellation_details[0]?.FBCD_comment}</p>
        </div>
    </div>
    <div className='flight_cancel_details_main_legent_container'>
    <p className="flight_cancel_details_legend">Ticket Cancellation fare details</p>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Service Fee</p>
            <p className='flight_cancel_details_label_price'>{datas?.cancellation_details[0]?.refund_service_fee}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'>Supplier Fee</p>
            <p className='flight_cancel_details_label_price'>{datas?.cancellation_details[0]?.refund_supplier_amount}</p>
        </div>
        <div className='flight_cancel_details_sub_container'>
            <p className='flight_cancel_details_label'> Customer Refund Amount</p>
            <p className='flight_cancel_details_label_price'>{datas?.cancellation_details[0]?.refund_customer_net}</p>
        </div>
    </div>
</div>
  )
}

export default FlightCancellationChangeDetails