import React, { useEffect, useState } from 'react'
import CustomAutoCompleteDropdown from '../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import './privilageStyles.css'
import { get_privilages_list, update_role_privilages } from '../../../../api/privilageApis'
import { object } from 'yup'
import { get_roles_with_out_pagination } from '../../../../api/rolesApis'
import { ErrorToast, SuccessToast } from '../../../../toast/toasts'
import { LocalStorageServices } from '../../../../services/localStorageServices'
function Permissions() {

  const [selectedRole, setSelectedRole] = useState(null);
  const [role, setRole] = useState([]) //FOR ROLES LISTING TO THE DDL
  const [modulesPrivilages, setModulePrivilages] = useState([])
  const [refresh, setRefresh] = useState(false)

  //GET ROLES
  const getRolesWithOutPagination = () => {
    get_roles_with_out_pagination().then((response) => {
      const data = response.map((item) => {
        return {
          label: item.role_name,
          value: item.role_id,
        };
      });
      setRole(data)
      // console.log(data[0].value)
      // setSelectedRole(data.length > 0 ? {
      //   label: data[0].label,
      //   value: data[0].value
      // } : null)

    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getRolesWithOutPagination()
  }, [])


  useEffect(() => {

    if (selectedRole) {
      const payload = {
        role_id: selectedRole?.value
      }
      get_privilages_list(payload).then((response) => {
        let privilagearray = []
        privilagearray = response?.map((module) => ({
          ...module,
          submodules: module.submodules.map((submodule) => ({
            ...submodule,
            // privileges: JSON.parse(submodule.privileges),
            privileges: submodule.privileges,
          })),
        }))
        setModulePrivilages(privilagearray)
      }).catch((error) => {
        console.log(error)
      })
    } else {
      setModulePrivilages([])
    }

  }, [selectedRole, refresh])


  // Handle Privilages checkbox btn checkbox click
  const handlePrivilegeChange = (moduleIndex, submoduleIndex, privilegeIndex) => {
    const updatedModules = [...modulesPrivilages];
    const currentPrivilege = updatedModules[moduleIndex].submodules[submoduleIndex].privileges[privilegeIndex];
    // Toggle the 'checked' state of the privilege
    currentPrivilege.checked = currentPrivilege.checked === 0 ? 1 : 0;

    setModulePrivilages(updatedModules);
  };

  // Handle change for role choose
  const handleRoleChange = (event, value) => {
    setSelectedRole(value);
  };

  // Function to get Privileges for save
  const getSubmodulePrivileges = (modules) => {
    return modules.flatMap(module =>
      module.submodules.map(submodule => ({
        submodule_id: submodule.submodule_id,
        // submodule_name: submodule.submodule_name,
        privileges_id: submodule.privileges
          .filter(privilege => privilege.checked === 1)
          .map(privilege => privilege.id)
      }))
    );
  };

  const handleSave = () => {
    const Privileges = getSubmodulePrivileges(modulesPrivilages);

    const payload = {
      role_id: Number(selectedRole?.value) || '',
      privileges: JSON.stringify(Privileges),
    }
    update_role_privilages(payload).then((response) => {
      if (response.data.status) {
        SuccessToast(response.data.message)
        setRefresh(!refresh)
      } else {
        // SuccessToast(response.data.message)
        setRefresh(!refresh)
      }
    }).catch((error) => {
      console.log(error)
      ErrorToast(`Something went wrong`)
    })
  };

  const getPrivilegesDetailsLocalStorageData = function () {
    const data = localStorage.getItem("privileges_details");
    return data ? JSON.parse(data) : []; // Parse the JSON string or return an empty array if null
  };

  // Fetch privileges data once
  const privileges = getPrivilegesDetailsLocalStorageData();

  //Privileges LIST
  const isShowPrivilegeManagementAdd = privileges?.some(item =>
    item.module_name === "User Management" &&
    item.submodules?.["Privileges"]?.privileges?.some(priv => priv?.name === "Add")
  );

  //FOR INITIALLY SET THE THE ROLE THE PRIVILEGES PAGES ROLES
  useEffect(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData();
    const parsedUserDetails = user_details ? JSON.parse(user_details) : null;
    if (parsedUserDetails) {
      const foundMatch = role?.find((val) => Number(val.value) === Number(parsedUserDetails.UD_roleID));
      setSelectedRole(
        foundMatch
          ? { label: foundMatch.label, value: foundMatch.value }
          : null
      );
    }
  }, [role]);

  // Handle "Select All" button click
  // const handleSelectAllPrivileges = () => {
  //   const updatedModules = modulesPrivilages.map((module) => ({
  //     ...module,
  //     submodules: module.submodules.map((submodule) => ({
  //       ...submodule,
  //       privileges: submodule.privileges.map((privilege) => ({
  //         ...privilege,
  //         checked: 1, // Set all privileges to checked
  //       })),
  //     })),
  //   }));
  //   setModulePrivilages(updatedModules);
  // };

  return (
    <div className='privilages_module_main_container'>
      <div className='privilages_module_sub_container_0'>
        <p className='privilages_title'>Privileges</p>
        <div className='privilage_form_single_row_alignment'>
          {/* <button className='privilage_form_reset_button'>Reset</button> */}
          {isShowPrivilegeManagementAdd && <button onClick={handleSave} className='privilage_form_save_button'>Save</button>}
        </div>
      </div>
      <div className='privilages_module_sub_container_1'>
        <p className="privilage_form_main_legend">General</p>

        <div className='privilages_module_sub_container_2'>
          <p className='privilage_form_label'>Role<span className='label_required_remark'>*</span></p>
          <CustomAutoCompleteDropdown
            name="role"
            placeholder={"Select a role"}
            options={role}
            value={selectedRole}
            onChange={handleRoleChange}
          />
          {/* <button className='privilage_form_reset_button' onClick={handleSelectAllPrivileges}>Select All</button> */}
        </div>

        <div className='privilage_module_wise_main_container'>

          {modulesPrivilages?.map((module, moduleIndex) => (
            <div className='privilage_module_wise_container' key={module.module_name}>
              <p className='privilage_sub_legend'><b>{module.module_name}</b></p>
              {module?.submodules?.length > 0 ? (
                module.submodules.map((submodule, submoduleIndex) => (
                  <div className='privilage_module_wise_container' key={submodule.submodule_id}>
                    <p className='privilage_sub_legend'><b>{submodule.submodule_name}</b></p>

                    <div className='privilage_actions_container'>
                      {submodule?.privileges.map((privilege, privilegeIndex) => (
                        <div className='privilage_actions_sub_container' key={privilege.id}>
                          <input
                            type="checkbox"
                            checked={privilege.checked === 1}
                            onChange={() =>
                              handlePrivilegeChange(moduleIndex, submoduleIndex, privilegeIndex)
                            }
                          />
                          <p>{privilege.name}</p>
                        </div>
                      ))}
                    </div>

                  </div>
                ))
              ) : (
                <p>No submodules available</p>
              )}
            </div>
          ))}
        </div>

        {/* OLD CODE  */}
        {/* {modulesPrivilages.map((item1, index1) => (
                        <div className='privilage_module_wise_container'>
                            <p className='privilage_sub_legend'>{item1.module}</p>
                            {item1?.subModule?.map((item2, index2) => (
                                <div className='privilage_module_wise_container'>
                                    <p className='privilage_sub_legend'>{item2?.submodule_name}</p>
                                    <div className='privilage_actions_container'>
                                        {item2?.privileges.map((item) => (
                                            <div className='privilage_actions_sub_container'>
                                                <input type="checkbox" id="" name="" />
                                                <p>{item}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    ))} */}
        {/* <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Process Ecr</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>User Management</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Reports</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Payment</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Address Book</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Services</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Supplier</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Tax-GST</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div>
          <div className='privilage_module_wise_container'>
            <p className='privilage_sub_legend'>Tax-TCS</p>
            <div className='privilage_actions_container'>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>View</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Add</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Edit</p>
              </div>
              <div className='privilage_actions_sub_container'>
                <input type="checkbox" id="" name="" />
                <p>Delete</p>
              </div>
            </div>
          </div> */}
        {/* </div> */}


        {/* <div className='privilage_module_wise_main_container'>
                    {modulesPrivilages?.map((module, moduleIndex) => (
                        <div className='privilage_module_wise_container' key={module.module_name}>
                            <p className='privilage_sub_legend'>{module.module_name}</p>
                            {module?.submodules?.length > 0 ? (
                                module?.submodules?.map((submodule, submoduleIndex) => (
                                    <div className='privilage_module_wise_container' key={submodule.submodule_id}>
                                        <p className='privilage_sub_legend'>{submodule.submodule_name}</p>
                                        <div className='privilage_actions_container'>
                                            {submodule?.privileges.map((privilege, privilegeIndex) => (
                                                <div className='privilage_actions_sub_container' key={privilege.id}>
                                                    <input
                                                        type="checkbox"
                                                        checked={privilege.checked === 1}
                                                        onChange={() =>
                                                            handlePrivilegeChange(moduleIndex, submoduleIndex, privilegeIndex)
                                                        }
                                                    />
                                                    <p>{privilege.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No submodules available</p>
                            )}
                        </div>
                    ))}
                </div> */}

      </div>
    </div>
  )
}

export default Permissions