
/*
 * Purpose: This file handles the functionality of Create the Train ticket creation/view/updation
 * Created By: Sreeraj
 * Created On: [ 04/12/24]
 * Modified By: []
 * Last Modified On: []
 */

import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { IoArrowBackSharp } from "react-icons/io5";
import dayjs from "dayjs";
// import './flightTicketFormStyle.css'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { useState } from 'react';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ClientForm from '../../../master_settings/clients/client_form/ClientForm';
import SupplierForm from '../../../master_settings/suppliers/supplier_form/SupplierForm';
import { CgAddR } from "react-icons/cg";
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { Chip, IconButton, Paper } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDeleteConfirmModal from '../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { hasPrivilege } from '../../../../../common_privilige_methods/Privilege_Methods';
import TrainAdditionalInfo from '../train_additional_info/TrainAdditionalInfo';
import TrainPassengerTable from '../train_passenger_table/TrainPassengerTable';
import { get_trip_type } from '../../../../../api/commonLookUpApis';
import { create_new_Train_Booking, get_Train_Booking_details_by_id, update_Train_Booking } from '../../../../../api/process_ecr/TrainTicketApis';
function TrainForm() {
    const navigate = useNavigate()
    let { booking_id } = useParams();
    const location = useLocation()
    const [supplier, setSupplier] = useState([])
    const [client, setClient] = useState([])
    const [passengerList, setPassengerList] = useState([])
    const [fileAttachments, setFileAttachments] = useState([])
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(true)
    const [passengerCount, setPassengerCount] = useState(0)
    const [passengerOtherCount, setPassengerOtherCount] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })

    const [deletedAttachments, setDeletedAttachments] = useState([])
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false)
    const [isView, setIsView] = useState(false)
    const [client_creation_modal, set_client_creation_modal] = useState(false)
    const [supplier_creation_modal, set_supplier_creation_modal] = useState(false)
    const [deletePassengerId, setDeletePassengerId] = useState(null)

    const [tripType, setTripType] = useState([])


    useEffect(() => {
        getSupplierList()
        getClientList()
    }, [client_creation_modal, supplier_creation_modal])

    useEffect(() => {

        if (location.pathname.split('/').pop() === "view_train") {
            setIsView(true);
        } else if (location.pathname.split('/').pop() === "update_train") {
            setIsView(false);
            // setIsAmendment(true);
        }

        if (booking_id) {
            const payload = {
                booking_id
            }
            get_Train_Booking_details_by_id(payload)
                .then((response) => {
                    const bookingData = response?.data?.data
                    const passengerData = bookingData?.passengers;
                    setIsSaveAsDraft(bookingData?.status_id === 1 ? true : false)
                    formik.setValues({
                        ...formik.values,
                        train_pnr: bookingData.train_pnr,
                        trip_type: bookingData.trip_type_id,
                        issue_date: bookingData.issue_date ? bookingData.issue_date.split('T')[0] : null,
                        travel_date: bookingData?.travel_date ? bookingData.travel_date.split('T')[0] : null,
                        return_date: bookingData?.return_date ? bookingData?.return_date?.split('T')[0] : "",

                        cab_type: bookingData.cab_type_id,

                        adult: bookingData.adult,
                        child: bookingData.child,
                        days: bookingData.days,

                        supplier: bookingData.supplier_id,
                        supplier_amount: bookingData.supplier_amount,
                        client: bookingData.client_id,
                        client_amount: bookingData.client_amount,

                        service_fee: bookingData.service_fee,
                        sector: bookingData.sector,
                        tcs: bookingData?.tcs || "",
                        train_name: bookingData?.train_name || "",
                        attachment: bookingData.attachment,
                        narration: bookingData?.narration || "",
                        reference: bookingData?.reference || "",
                        comments: bookingData?.comments || "",
                        ticket_type: bookingData.ticket_type_id,
                        pax_count: bookingData.pax_count,

                    });
                    setPassengerList(passengerData);

                    setPassengerOtherCount({
                        'adult': bookingData?.adult || 0,
                        'child': bookingData?.child || 0,
                        'infants': bookingData?.infant || 0
                    })
                    if (bookingData?.attachments === null) {
                        setFileAttachments([]);
                    } else {
                        setFileAttachments(bookingData?.attachments?.map((item, index) => ({ url: item.attachment, fileName: item.name })));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [isUpdated, booking_id]);

    useEffect(() => {
        getTripTypeList()
    }, [])

    const getTripTypeList = () => {
        get_trip_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TRIP_name,
                    value: item.TRIP_id,
                };
            });
            setTripType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const set_passenger_list = (item) => {
        setPassengerList([...passengerList, item])
    }
    const update_passenger_list = (item) => {
        passengerList.splice(item.index, 1, item)
        setPassengerList([...passengerList])
    }
    const delete_particular_passenger_details = (index) => {
        setDeletePassengerId(index)
        setDeleteConfirmation(true)
    }
    const deletePassenger = () => {
        if (deletePassengerId !== null) {
            passengerList.splice(deletePassengerId, 1)
            setPassengerList([...passengerList])
            handleDeleteModalClose(false)
        }

    }
    const handleDeleteModalClose = (is_close) => {
        setDeleteConfirmation(is_close);
        setDeletePassengerId(null)
    };
    const set_attachment_list = (item) => {
        setFileAttachments([...fileAttachments, item])
    }
    const delete_particular_attachment_details = (index, item) => {
        fileAttachments.splice(index, 1)
        setFileAttachments([...fileAttachments])

    }
    const formik = useFormik({

        initialValues: {
            train_pnr: '',
            issue_date: null,
            trip_type: '',
            adult: 0,
            child: 0,
            infant: 0,
            pax_count: 0,
            sector: '',
            supplier: '',
            client: '',
            client_amount: 0,
            supplier_amount: 0,
            comments: '',
            reference: '',
            narration: '',
            tcs: 0,
            travel_date: "",
            return_date: "",
            train_name: ''

        },
        validationSchema: Yup.object({
            train_pnr: Yup.string().required("Pnr number is required"),
            issue_date: Yup.string().required("Issue  date is required"),
            // service: Yup.string().required("Service is required"),
            sector: Yup.string().required("Sector is required"),
            trip_type: Yup.string().required("Trip type is required"),
            supplier: Yup.string().required("Supplier is required"),
            client: Yup.string().required("Client/Customer is required"),
            adult: Yup.number()
                .required("Adult count is required")
                .min(1, "Adult count cannot be less than 1"),
            child: Yup.number()
                .required("child count is required")
                .min(0, "child count cannot be less than 0"),
            infant: Yup.number()
                .required("Infant count is required")
                .min(0, "Infant count cannot be less than 0")
                .test(
                    'infant-less-than-adult',
                    'Infant count cannot be greater than adult count',
                    function (value) {
                        return value <= this.parent.adult;
                    }
                ),
            pax_count: Yup.number()
                .required("Passenger count is required")
                .min(0, "Passenger count cannot be less than 0"),
            client_amount: Yup.number().min(0, 'Must be greater than or equal to 0')
                .required('Client amount is required'),
            supplier_amount: Yup.number().min(0, 'Must be greater than or equal to 0')
                .required('Supplier amount is required'),
            travel_date: Yup.string().required("Travel date is required"),
            return_date: Yup.string()
                .when('trip_type', {
                    is: "2",
                    then: (schema) =>
                        schema.nullable(false).required('Return date is required'),
                    otherwise: (schema) => schema.nullable()
                })
        }),

        onSubmit: (values) => {

            const formData = new FormData();
            // Check if the value is undefined or null, and if so, assign an empty string|| ""
            for (const key in values) {
                formData.append(key, values[key]);
            }

            if (passengerList.length === 0) return ErrorToast('Atleast 1 passenger is required')
            if (formik.values.pax_count !== passengerList.length) return ErrorToast(`${formik.values.pax_count - passengerList.length > 0 ? 'passenger details are missing' : 'mismatch in passenger count.'} `)

            let value = JSON.parse(JSON.stringify(values))

            value.status = isSaveAsDraft ? 1 : 2;

            value.passengers = JSON.stringify(passengerList)
            formData.append("passengers", value.passengers);
            formData.append("status", value.status);

            if (booking_id) {
                formData.append("booking_id", booking_id);
                if (fileAttachments?.length > 0) {
                    fileAttachments.forEach((fileAttachment, index) => {
                        if (fileAttachment?.file) {
                            // Append the file to formData with the file key
                            formData.append(`file[${index}]`, fileAttachment.file);
                        }
                    });
                }

                update_Train_Booking(formData).then((response) => {
                    if (response?.data?.code === 200) {
                        navigate(-1);
                        SuccessToast(response.data.message);
                        setIsUpdated(!isUpdated);
                    } else {
                        ErrorToast(response.data.message);
                    }
                }).catch((error) => {
                    console.log("Error in form submission:", error);
                    ErrorToast(`Something Went Wrong`);
                });

            } else {
                if (fileAttachments?.length > 0) {
                    fileAttachments.forEach((fileAttachment, index) => {
                        formData.append(`file[${index}]`, fileAttachment.file);
                    });
                }
                create_new_Train_Booking(formData).then((response) => {
                    if (response?.data?.code === 200 && response?.data?.status === true) {
                        navigate(-1);
                        SuccessToast(response.data.message);
                        setIsUpdated(!isUpdated);
                    } else {
                        ErrorToast(response.data.message);
                    }
                }).catch((error) => {
                    console.log("Error in form submission:", error);
                    ErrorToast(`Something Went Wrong`);
                });

            }

        }
    })

    const resetState = () => {
        setPassengerList([])
        setFileAttachments([])
        setPassengerCount(0)
        setDeletedAttachments([])
    }

    const handleClientCreationModal = (is_close) => {
        set_client_creation_modal(is_close);
    };

    const handleSupplierCreationModal = (is_close) => {
        set_supplier_creation_modal(is_close);
    };

    const is_Show_Draft_Btn = hasPrivilege("Process ECR", "Train", "Draft");
    const is_show_Submit_Btn = hasPrivilege("Process ECR", "Train", "Submit");

    return (
        <div className='flight_ticket_form_super_container'>
            <div >
                <div className='flight_ticket_form_sub_container'>
                    <div className='flight_ticket_form_single_row_alignment'>
                        <IoArrowBackSharp className='flight_ticket_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='flight_ticket_form_title'>{booking_id && (isView) ? 'View Train Ticket' : booking_id ? 'Update Train' : 'Book Train'}</p>
                    </div>
                </div>

                <div>
                    <form id="travelInsuranceForm" onSubmit={formik.handleSubmit}>
                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">General Info</p>
                            <div className='flight_ticket_form_sub_container_0'>

                                <div>
                                    <p className='flight_ticket_form_label'>PNR <span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        autoFocus
                                        isDisabled={isView}
                                        name="train_pnr"
                                        placeholder={"Enter File Name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.train_pnr}
                                        error={formik.touched.train_pnr &&
                                            Boolean(formik.errors.train_pnr)
                                        }
                                        helperText={
                                            formik.touched.train_pnr && formik.errors.train_pnr
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Train Name</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="train_name"
                                        placeholder={"Enter Train name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.train_name}
                                        helperText={
                                            formik.touched.train_name && formik.errors.train_name
                                        }
                                    />
                                </div>


                                <div>
                                    <p className='flight_ticket_form_label'>Issue Date<span className='label_required_remark'>*</span></p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}
                                            name="issue_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("issue_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.issue_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.issue_date &&
                                                Boolean(formik.errors.issue_date)
                                            }
                                            helperText={
                                                formik.touched.issue_date && formik.errors.issue_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Trip Type<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="trip_type"
                                        placeholder={"Select a trip type"}
                                        options={tripType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "trip_type",
                                                newValue ? newValue.value : ""
                                            );
                                            if (newValue.value == '1') {
                                                formik.setFieldValue(
                                                    "return_date", ""
                                                );
                                            }

                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            tripType.find((type) =>
                                                type.value ===
                                                formik.values.trip_type
                                            ) || null
                                        }
                                        error={
                                            formik.touched.trip_type &&
                                            Boolean(formik.errors.trip_type)
                                        }
                                        helperText={
                                            formik.touched.trip_type &&
                                            formik.errors.trip_type
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Sector<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="sector"
                                        placeholder={"Enter sector"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.sector}
                                        error={formik.touched.sector &&
                                            Boolean(formik.errors.sector)
                                        }
                                        helperText={
                                            formik.touched.sector && formik.errors.sector
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Travel Date<span className='label_required_remark'>*</span></p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}

                                            name="travel_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("travel_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.travel_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.travel_date &&
                                                Boolean(formik.errors.travel_date)
                                            }
                                            helperText={
                                                formik.touched.travel_date && formik.errors.travel_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Return Date</p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView ? isView : formik.values.travel_date && formik.values.trip_type == '1' ? true : formik.values.travel_date ? false : true}
                                            minDate={dayjs(formik.values.travel_date, "DD-MM-YYYY")}
                                            name="return_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("return_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.return_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.return_date &&
                                                Boolean(formik.errors.return_date)
                                            }
                                            helperText={
                                                formik.touched.return_date && formik.errors.return_date
                                            } />

                                    </div>

                                </div>


                                <div>
                                    <p className='flight_ticket_form_label'>Adult<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="adult"
                                        placeholder={"Enter adult number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const adultCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = adultCount + formik.values.child + formik.values.infant;

                                            formik.setFieldValue("pax_count", amount);
                                            setPassengerCount(amount);
                                            setPassengerOtherCount({ ...passengerOtherCount, adult: adultCount });
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adult}
                                        error={formik.touched.adult &&
                                            Boolean(formik.errors.adult)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.adult && formik.errors.adult
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Child</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="child"
                                        placeholder={"Enter child number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const childCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = childCount + formik.values.adult + formik.values.infant
                                            formik.setFieldValue("pax_count", amount);
                                            setPassengerCount(amount)
                                            setPassengerOtherCount({ ...passengerOtherCount, ['child']: childCount })
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.child}
                                        error={formik.touched.child &&
                                            Boolean(formik.errors.child)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.child && formik.errors.child
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Infants</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="infant"
                                        placeholder={"Enter infants number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            const infantsCount = e.target.value !== '' ? Number(e.target.value) : 0;
                                            const amount = infantsCount + formik.values.adult + formik.values.child
                                            formik.setFieldValue("pax_count", amount);
                                            setPassengerCount(amount)
                                            setPassengerOtherCount({ ...passengerOtherCount, ['infants']: infantsCount })
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.infant}
                                        error={formik.touched.infant &&
                                            Boolean(formik.errors.infant)
                                        }
                                        inputProps={{ inputProps: { min: 0 } }}
                                        helperText={
                                            formik.touched.infant && formik.errors.infant
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>PAX Count</p>
                                    <CustomTextField
                                        isDisabled={true}
                                        name="pax_count"
                                        placeholder={"pax count"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pax_count}
                                        error={formik.touched.pax_count &&
                                            Boolean(formik.errors.pax_count)
                                        }
                                        helperText={
                                            formik.touched.pax_count && formik.errors.pax_count
                                        }
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Fair </p>
                            <div className='packages_form_sub_container_0'>

                                <div>
                                    <div className='flight_ticket_form_label_with_action_container'>
                                        <p className='flight_ticket_form_label_with_action'>Supplier<span className='label_required_remark'>*</span></p>
                                        {!isView && (
                                            <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                                                handleSupplierCreationModal(true)
                                            }} />
                                        )}
                                    </div>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="supplier"
                                        placeholder={"Select a supplier"}
                                        options={supplier}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "supplier",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            supplier.find((type) =>
                                                type.value ===
                                                formik.values.supplier
                                            ) || null
                                        }
                                        error={
                                            formik.touched.supplier &&
                                            Boolean(formik.errors.supplier)
                                        }
                                        helperText={
                                            formik.touched.supplier &&
                                            formik.errors.supplier
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Supplier Amount<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="supplier_amount"
                                        placeholder={"Enter client amount "}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value)
                                                formik.setFieldValue("supplier_amount", amount);
                                                // setClientAmount(amount)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.supplier_amount}
                                        error={formik.touched.supplier_amount &&
                                            Boolean(formik.errors.supplier_amount)
                                        }
                                        inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                        helperText={
                                            formik.touched.supplier_amount && formik.errors.supplier_amount
                                        }
                                    />
                                </div>

                                <div>
                                    <div className='flight_ticket_form_label_with_action_container'>
                                        <p className='flight_ticket_form_label_with_action'>Customer/Client<span className='label_required_remark'>*</span></p>
                                        {!isView && (
                                            <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                                                set_client_creation_modal(true)
                                            }} />
                                        )}
                                    </div>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="client"
                                        placeholder={"Select a customer"}
                                        options={client}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "client",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            client.find((type) =>
                                                type.value ===
                                                formik.values.client
                                            ) || null
                                        }
                                        error={
                                            formik.touched.client &&
                                            Boolean(formik.errors.client)
                                        }
                                        helperText={
                                            formik.touched.client &&
                                            formik.errors.client
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Client Amount<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="client_amount"
                                        placeholder={"Enter client amount "}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value)
                                                formik.setFieldValue("client_amount", amount);
                                                // setClientAmount(amount)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.client_amount}
                                        error={formik.touched.client_amount &&
                                            Boolean(formik.errors.client_amount)
                                        }
                                        inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                        helperText={
                                            formik.touched.client_amount && formik.errors.client_amount
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>TCS</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="tcs"
                                        placeholder={"Enter tcs "}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value)
                                                formik.setFieldValue("tcs", amount);
                                                // setClientAmount(amount)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tcs}
                                        error={formik.touched.tcs &&
                                            Boolean(formik.errors.tcs)
                                        }
                                        inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                        helperText={
                                            formik.touched.tcs && formik.errors.tcs
                                        }
                                    />
                                </div>

                                {/* <div>
                                    <p className='flight_ticket_form_label'>GST</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="gst"
                                        placeholder={"Enter client amount "}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value)
                                                formik.setFieldValue("gst", amount);
                                                // setClientAmount(amount)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.gst}
                                        error={formik.touched.gst &&
                                            Boolean(formik.errors.gst)
                                        }
                                        inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                        helperText={
                                            formik.touched.gst && formik.errors.gst
                                        }
                                    />
                                </div> */}

                                {/* <div>
                                    <p className='flight_ticket_form_label'>TCS</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="tcs"
                                        placeholder={"Enter tcs "}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value)
                                                formik.setFieldValue("tcs", amount);
                                                // setClientAmount(amount)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tcs}
                                        error={formik.touched.tcs &&
                                            Boolean(formik.errors.tcs)
                                        }
                                        inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                        helperText={
                                            formik.touched.tcs && formik.errors.tcs
                                        }
                                    />
                                </div> */}

                            </div>

                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Passenger Info</p>
                            <TrainPassengerTable isDisabled={booking_id && (isView)} passengerCount={passengerCount} passengerOtherCount={passengerOtherCount} passengerList={passengerList} setPassengerList={set_passenger_list} updatePassengerDetails={update_passenger_list} passengerDelete={delete_particular_passenger_details} />
                        </div>

                        <div className='packages_form_main_container'>
                            <p className="packages_form_legend"> Miscellaneous</p>
                            <div className='packages_form_sub_container_0'>

                                <div>
                                    <p className='packages_form_label'>Narration</p>
                                    <CustomTextArea
                                        rows={5}
                                        isDisabled={booking_id && (isView)}
                                        name="narration"
                                        placeholder={"Enter a narration"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik?.values?.narration}
                                    />
                                </div>

                                <div>
                                    <p className='packages_form_label'>Comments</p>
                                    <CustomTextArea
                                        isDisabled={booking_id && (isView)}
                                        rows={5}
                                        name="comments"
                                        placeholder={"Enter Comment"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.comments}
                                    />
                                </div>

                                <div>
                                    <p className='packages_form_label'>Reference</p>
                                    <CustomTextField
                                        isDisabled={booking_id && (isView)}
                                        name="reference"
                                        placeholder={"Enter Reference"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.reference}
                                    />
                                </div>

                            </div>

                            {/* <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginTop: 10 }}>
                                <div>
                                    <div className='m-4' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}   >
                                        {booking_id && (isView) ? "" : (<>
                                            <CustomTextField
                                                isDisabled={booking_id && (isView)}
                                                name="pnr_refernce"
                                                placeholder="Enter PNR"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    const values = e.target.value;
                                                    formik.setFieldValue("pnr_refernce", values);
                                                    setPnr(values);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pnr_refernce}
                                            />

                                            <button
                                                hidden={booking_id && (isView)}
                                                className="flight_ticket_form_save_button"
                                                type="button"
                                                onClick={handleAdd}
                                            >
                                                Add
                                            </button>
                                        </>)}


                                    </div>
                                    {chipData && chipData.length > 0 && (
                                        <>
                                            {booking_id && (isView) ? (<>
                                                <Paper
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 1,
                                                        padding: '8px',
                                                        flexWrap: 'wrap',
                                                        width: 500,
                                                        overflowY: chipData.length > 3 ? 'auto' : 'visible',
                                                    }}
                                                >

                                                    {chipData?.map((data) => (
                                                        <Chip
                                                            key={data.key}
                                                            label={data.label}
                                                        />
                                                    ))}
                                                </Paper>
                                            </>) : (<>
                                                <Paper
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 1,
                                                        padding: '8px',
                                                        flexWrap: 'wrap',
                                                        width: 500,
                                                        overflowY: chipData.length > 3 ? 'auto' : 'visible',
                                                    }}
                                                >

                                                    {chipData?.map((data) => (
                                                        <Chip
                                                            key={data.key}
                                                            label={data.label}
                                                            onDelete={handleDelete(data)}
                                                        />
                                                    ))}
                                                </Paper>
                                            </>)}

                                        </>
                                    )}
                                </div>
                                <PassportAdditionalInfo isDisabled={booking_id && (isView)} client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} />
                            </div> */}
                            <TrainAdditionalInfo isDisabled={booking_id && (isView)} client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} />

                        </div>
                    </form>
                    <div className='flight_ticket_form_footer_actions'>
                        {!isView && (
                            <div className='flight_ticket_form_single_row_alignment' >
                                {!booking_id ? <button className='flight_ticket_form_reset_button' tabIndex='14' onClick={() => {
                                    formik.resetForm()
                                    resetState()
                                }}>Reset</button> : <></>}
                                {is_Show_Draft_Btn && <button
                                    className='flight_ticket_form_save_button'
                                    type="submit"
                                    form="travelInsuranceForm" onClick={() => {
                                        setIsSaveAsDraft(true)
                                    }}>Save as Draft</button>}

                                {is_show_Submit_Btn && <button
                                    className='flight_ticket_form_save_button'
                                    type="submit"
                                    form="travelInsuranceForm" onClick={() => {
                                        setIsSaveAsDraft(false)
                                    }}>Submit</button>}

                            </div>
                        )}
                    </div>


                </div>
            </div>
            {/* client registration modal */}
            <CustomModal
                maxWidth={"1500px"}
                open={client_creation_modal}
                handleClose={handleClientCreationModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClientCreationModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <ClientForm is_open_modal={true} close_modal={handleClientCreationModal} />
                }
                title={"Client Registartion"}
            />
            {/* {supplier registration modal} */}
            <CustomModal
                maxWidth={"1500px"}
                open={supplier_creation_modal}
                handleClose={handleSupplierCreationModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleSupplierCreationModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <SupplierForm is_open_modal={true} close_modal={handleSupplierCreationModal} />
                }
                title={"Supplier Registartion"}
            />
            <CustomDeleteConfirmModal module_name={'passenger'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deletePassenger} />
        </div>
    )
}

export default TrainForm