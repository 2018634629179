import { ApiConfig } from "./apiConfig";

export const get_ticket_type = async () => {
    try {
      const response = await ApiConfig.post(`ticketType`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const get_trip_type = async () => {
    try {
      const response = await ApiConfig.post(`tripType`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const get_ecr_status = async () => {
  try {
    const response = await ApiConfig.post(`EcrStatus`,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_package_status = async () => {
  try {
    const response = await ApiConfig.post(`getPackageStatusList`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_status_List_without_cancelation = async () => {
  try {
    const response = await ApiConfig.post(`getPackageStatusList`);
    return response;
  } catch (error) {
    throw error;
  }
};