
import React, { useEffect, useState } from 'react'
// import './VisaAdditionalInfo.css'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { CommonHelpers } from '../../../../../services/commonHelpers';
import { useLocation } from 'react-router-dom';
function VisaAdditionalInfo({ isDisabled, attachmnetList, setAttachmentList, attachmentDelete, client_creation_modal }) {
    const location = useLocation()

    function handleFileSelect(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file?.name;
        const fileType = file?.type?.split("/")[1];
        if (file === undefined || fileType === undefined || fileName === undefined) {
            return
        } else {
            // Add the new file details to the attachment list
            setAttachmentList({ file: file, fileName: fileName, fileType: fileType })
        }
    }

    return (
        <div className='flight_ticket_additional_info_main_container'>
            <div>
                {!isDisabled && (
                    <>
                        <p className='flight_ticket_additional_info_main_text'>Browse File</p>
                        <div style={{ margin: '20px 0px' }}>
                            <label htmlFor="files" className="flight_ticket_choose_file_field">
                                <span id="fileInfo">Choose file</span>
                            </label>
                            <input
                                id="files"
                                style={{ display: "none" }}
                                type="file"
                                accept="image/jpeg,image/png,application/pdf"
                                multiple
                                onChange={(event) => handleFileSelect(event)}
                            />
                        </div>
                    </>
                )}
                {attachmnetList && attachmnetList.length > 0 && <p className='flight_ticket_additional_info_main_text'>Attachments</p>}
                <div className='flight_ticket_additional_info_attchment_main_container'>
                    {attachmnetList && attachmnetList.length > 0 && attachmnetList.map((item, index) => (
                        <div className='flight_ticket_additional_info_attchment_item'>
                            <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                            <div className='flight_ticket_additional_info_attchment_item_action'>
                                <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                    if (item.url) {
                                        CommonHelpers.DownloadAssets(item.url)
                                    } else {
                                        CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                    }
                                }} />
                                {!isDisabled && (
                                    <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                        attachmentDelete(index, item)
                                    }} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default VisaAdditionalInfo