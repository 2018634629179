import { ApiConfig } from "./apiConfig";

export const get_roles_with_out_pagination = async () => {
    try {
      const response = await ApiConfig.post(`roles`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
};
export const get_roles_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`roles`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const get_role_by_id = async (role_id) => {
    try {
      const response = await ApiConfig.post(`rolesByid`,{id:role_id}, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const role_registration = async (data) => {
    try {
      const response = await ApiConfig.post(`roles_register`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_role_details = async (data) => {
    try {
      const response = await ApiConfig.post(`rolesUpdate`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const role_deletion = async (role_id) => {
    try {
      const response = await ApiConfig.delete(`rolesDelte/${role_id}`);
      return response;
    } catch (error) {
      throw error;
    }
};