import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './InsuranceFilterStyles.css'
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import { RiFilterFill } from "react-icons/ri";
import dayjs from "dayjs";
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { resetNotificationRedirect } from '../../../../../store/commonStore';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_passport_status } from '../../../../../api/PassportApi';

function InsuranceFilter({ searchFilter }) {
    const dispatch = useDispatch()

    const [client, setClient] = useState([])

    const [advanceFilter, setAdvanceFilter] = useState(false)
    const [ecrStatus, setEcrStatus] = useState([])
    const [supplier, setSupplier] = useState([])


    const getEcrStatus = () => {
        get_passport_status().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.ESL_name,
                    value: item.ESL_id,
                };
            });
            setEcrStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    // const getServiceTypes = () => {
    //     get_service_types().then((response) => {
    //         const data = response?.data?.data?.map((item) => {
    //             return {
    //                 label: item.cab_service,
    //                 value: item.cab_service_id,
    //             };
    //         });
    //         setServicesList(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }
    // const getServices = () => {
    //     get_services_list().then((response) => {
    //         const data = response?.data?.data?.map((item) => {
    //             return {
    //                 label: item.ES_services_name,
    //                 value: item.ES_id,
    //             };
    //         });
    //         setServicesList(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    // const getCabTypes = () => {
    //     get_cab_types().then((response) => {
    //         const data = response?.data?.data?.map((item) => {
    //             return {
    //                 label: item.cab_type,
    //                 value: item.cab_type_id,
    //             };
    //         });
    //         setTypes(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getSupplierList()
        getEcrStatus()
        getClientList()
    }, [])


    const formik = useFormik({
        initialValues: {
            'insurance_pnr': '',
            'client': "",
            'supplier': "",
            'start_date': '',
            'end_date': '',
            'status': '',
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });

    // const getStatus = () => {
    //     get_status().then((response) => {
    //         const data = response.map((item) => {
    //             return {
    //                 label: item.status_name,
    //                 value: item.id,
    //             };
    //         });
    //         setStatus(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    const resetFilter = () => {
        dispatch(resetNotificationRedirect())
        formik.setValues({
            ...formik.values, ...{
                'insurance_pnr': '',
                'client': "",
                'supplier': "",
                'start_date': '',
                'end_date': '',
                'status': '',
            }
        })
    }


    return (
        <div className='users_filter_main_container'>
            <form id="passportfilterform" onSubmit={formik.handleSubmit}>
                <div className='passport_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="insurance_pnr"
                            placeholder={"Enter file name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.insurance_pnr}
                        />
                    </div>

                    {/* <div>
                        <CustomTextField
                            name="Sector"
                            placeholder={"Enter Sector"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Sector}
                        />
                    </div> */}

                    {/* <div>
                        <CustomAutoCompleteDropdown
                            name="cab_service"
                            placeholder={"Select a service "}
                            options={servicesList}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "cab_service",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                servicesList.find((type) =>
                                    type.value ===
                                    formik.values.cab_service
                                ) || null
                            }
                        />
                    </div> */}

                    {/* <div >
                        <CustomAutoCompleteDropdown
                            name="cab_type"
                            placeholder={"Select a cab type"}
                            options={types}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "cab_type",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                types.find((type) =>
                                    type.value ===
                                    formik.values.cab_type
                                ) || null
                            }
                        />
                    </div> */}

                    <div>
                        <CustomAutoCompleteDropdown
                            name="client"
                            placeholder={"Select a client"}
                            options={client}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "client",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                client.find((type) =>
                                    type.value ===
                                    formik.values.client
                                ) || null
                            }

                        />
                    </div>

                    <div>
                        <CustomAutoCompleteDropdown
                            name="supplier"
                            placeholder={"Select a supplier"}
                            options={supplier}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "supplier",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                supplier.find((type) =>
                                    type.value ===
                                    formik.values.supplier
                                ) || null
                            }

                        />
                    </div>


                    <div>
                        <CustomAutoCompleteDropdown
                            name="status"
                            placeholder={"Select a status"}
                            options={ecrStatus}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "status",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                ecrStatus.find((type) =>
                                    type.value ===
                                    formik.values.status
                                ) || null
                            }
                        />
                    </div>

                    <div className='user_filter_actions'>
                        <button className='user_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='user_filter_action_search'
                            type="submit"
                            form="passportfilterform"
                        >Search</button>
                        <div>
                            <RiFilterFill className='filter_action_icons' onClick={() => {
                                setAdvanceFilter(!advanceFilter)
                            }} />
                        </div>
                    </div>

                </div>
                {advanceFilter && (
                    <>
                        <div className='flight_ticket_filter_sub_container'>
                            {/* <div className='flight_ticket_filter_style_1'>
                                <CustomAutoCompleteDropdown
                                    name="service"
                                    placeholder={"Select a service"}
                                    options={servicesList}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "service",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        servicesList.find((status) =>
                                            status.value ===
                                            formik.values.service
                                        ) || null
                                    }
                                />
                            </div> */}

                            <div className='flight_ticket_filter_sub_date_selection_container'>
                                <div className='flight_ticket_filter_style_1'>
                                    <p className='flight_ticket_filter_legent_style_1'>Issue start to end date</p>
                                    <CustomDatePicker
                                        no_border
                                        name="start_date"
                                        onChange={(date) => {
                                            formik.setFieldValue("start_date", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.start_date &&
                                            Boolean(formik.errors.start_date)
                                        }
                                        helperText={
                                            formik.touched.start_date && formik.errors.start_date
                                        } />
                                </div>
                                <div>
                                    <p>--</p>
                                </div>
                                <div className='flight_ticket_filter_style_1'>
                                    {/* <p className='flight_ticket_filter_legent_style_2'>End date</p> */}
                                    <CustomDatePicker
                                        no_border
                                        disabled={formik.values.start_date ? false : true}
                                        minDate={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                        name="end_date"
                                        onChange={(date) => {
                                            formik.setFieldValue("end_date", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.end_date, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.end_date &&
                                            Boolean(formik.errors.end_date)
                                        }
                                        helperText={
                                            formik.touched.end_date && formik.errors.end_date
                                        } />
                                </div>
                            </div>
                        </div>

                    </>
                )}


            </form>
        </div>
    )
}

export default InsuranceFilter