import React, { useState } from 'react'
import { PiEye } from "react-icons/pi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import CustomTable from '../../../../../components/custom_table/CustomTable';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import CustomDeleteConfirmModal from '../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
function SupplierBankTable({ rows, onEditDetails, onDeleteItemIndex }) {
  const location = useLocation()
  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    if (location.pathname.split('/').pop() === "view_supplier") {
      setIsView(true)
    }
  }, [])
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };
  const setdeletesupplier = () => {
    onDeleteItemIndex(selectedId)
    setDeleteConfirmation(false);
  }
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      hide: false

    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      hide: false
    },

    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      hide: false
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
      hide: false
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      hide: false
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      hide: false
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hide: isView,
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
          <PiEye className='user_action_view' onClick={() => {
            onEditDetails(row)
          }} />
          <MdOutlineDeleteOutline className='user_action_delete' onClick={() => {
            setSelectedId(row.id)
            setDeleteConfirmation(true)
          }} />
        </div>
      ),
    },
  ];

  const datas = () => {
    const result =
      rows &&
      rows.map((item, index) => {
        return {
          key: index + 1,
          id: index,
          bank_name: item.SBD_bank,
          account_name: item.SBD_account_name,
          account_number: item.SBD_account_num,
          branch_name: item.SBD_branch_name,
          ifsc_code: item.SBD_ifsc_code,
        };
      });
    return result;
  };
  return (
    <div>
      <CustomTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} />
      <CustomDeleteConfirmModal module_name={'Supplier Bank Detail'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={setdeletesupplier} />
    </div>
  )
}

export default SupplierBankTable