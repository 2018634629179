import { ApiConfig } from "./apiConfig";


export const create_new_Insurance_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`addInsuranceBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const update_Insurance_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`updateInsuranceBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_Insurance_Booking_lists_with_pagination = async (data) => {
    try {
        const response = await ApiConfig.post(`getInsuranceBookingsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};
export const get_Insurance_Booking_details_by_id = async (data) => {
    try {
        const response = await ApiConfig.post(`getInsuranceBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const delete_Insurance_Booking_by_id = async (id) => {
    try {
        const response = await ApiConfig.post(`deleteInsuranceBooking`, id);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const Insurance_Booking_cancel = async (data) => {
    try {
        const response = await ApiConfig.post(`changeInsuranceBookingStatus`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const approve_or_reject_Insurance_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`changeInsuranceBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const Insurance_Booking_review_comment = async (booking_id) => {
    try {
        const response = await ApiConfig.post(`getInsuranceBookingHistory`, booking_id);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_Insurance_status = async () => {
    try {
        const response = await ApiConfig.post(`getStatusList`);
        return response;
    } catch (error) {
        throw error;
    }
};