import React, { useEffect } from 'react'
import SliderMenu from './sub_components/slider_menu/SliderMenu'
import Header from './sub_components/header/Header'
import { Outlet, useNavigate } from 'react-router-dom'
import './homePageStyle.css'
import { LocalStorageServices } from '../services/localStorageServices'
import { useSelector } from 'react-redux'
function HomePage() {
  const navigate=useNavigate()
  const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
  useEffect(()=>{
    
    let data = LocalStorageServices.getAccessTokenLocalStorageData()
    if(!data){
      navigate('/ecrs_portal/login')
    }
  },[])
  return (
    <div className='home_page_main_container'>
        <div className='home_page_sub_container'>
          <div style={{width: isCollapsed ?"75px":'250px'}}>
            <SliderMenu/>
          </div>
            <div className='home_page_sub_header_and_component_container'>
              <Header/>
              <div className='home_page_components_loading_container'>
               <Outlet/>
              </div>
            </div>
        </div>
       
    </div>
  )
}

export default HomePage