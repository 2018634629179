import React, { useEffect, useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustomTable from '../../../../../components/custom_table/CustomTable';
// import './CabPassengerTable.css'
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation, useParams } from 'react-router-dom';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { GoDotFill } from "react-icons/go";
import CustomExpandableTable from '../../../../../components/custom_expandable_table/CustomExpandableTable';
import HotelCustomerForm from '../hotel_customer_form/HotelCustomerForm';

function HotelCustomerTable({ isDisabled, passengerCount, passengerOtherCount, passengerList, setPassengerList, updatePassengerDetails, passengerDelete }) {
    let { booking_id } = useParams();
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [open, setOpen] = useState(false);
    const [passengerDetails, setPassengerDetails] = useState(null)
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_cab") {
            setIsView(true)
        } else if (location.pathname.split('/').pop() === "update_cab") {
            setIsView(false)
            setIsUpdate(true)
        }
        getPassengerList()
        getPassengerTitle()

    }, [passengerList])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const Columns = [
        {
            title: "",
            dataIndex: "Expand",
            key: "expand",

        },
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "Room Number",
            dataIndex: "room_number",
            key: "room_number",
        },
        {
            title: "Base Fare",
            dataIndex: "base_fare",
            key: "base_fare",
        },

        {
            title: "Supplier Amount",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
        },
        {
            title: "Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            hide: isView,
            action: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
                    <BiEditAlt className='flight_ticket_passenger_table_action_edit' onClick={() => {
                        setPassengerDetails(row)
                        setOpen(true)
                    }} />
                    {/* {!isUpdate && ( */}
                    <MdOutlineDeleteOutline className='flight_ticket_passenger_table_action_delete' onClick={() => {
                        passengerDelete(row.index)
                    }} />
                    {/* )
                    } */}
                </div>
            ),
        },
    ];


    const handleClose = (is_close) => {
        if (!is_close) {
            setPassengerDetails(null)
        }
        setOpen(is_close);
    };
    const set_passenger_details = (details) => {
        setPassengerList(details)
    }
    const update_passenger_details = (details) => {
        updatePassengerDetails(details)
    }

    const datas = () => {
        const result =
            passengerList &&
            passengerList.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.passenger_type)?.label;
                const passengerTitleLable = passengerTitle.find(pt => pt.value == item.title)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.passenger_type,
                    title: item.title,
                    title_name: passengerTitleLable,
                    room_number: item.room_number,
                    full_name: item.name,
                    base_fare: Number(item.base_fare),
                    service_fee: Number(item.service_fee),
                    supplier_amount: Number(item.supplier_amount),
                    customer_net: Number(item.customer_net),
                    type_name: passengerTypeItem,
                };
            });
        return result;
    };


  return (
    <div>
    <CustomModal
        maxWidth={"1500px"}
        open={open}
        handleClose={handleClose}
        close={<IconButton
            aria-label="close"
            onClick={() => {
                handleClose(false)
            }}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
            <HotelCustomerForm totalPassenger={passengerList} passengerOtherCounts={passengerOtherCount} existingPassengerDetails={passengerDetails} onClose={handleClose} setPassengerDetails={set_passenger_details} updatePassengerDetails={update_passenger_details} />
        }
        title={!passengerDetails ? "Add Customer" : "Update Customer"}
    />
    <div className='flight_ticket_passenger_add_passenger_action_container' >
        <div>
            {true && passengerCount != 0 && passengerCount > 0 && passengerList.length != passengerCount && (
                <div className='flight_ticket_passenger_table_header_action_container' tabindex="0" onClick={() => {
                    setOpen(true)
                }}>
                    <IoAddOutline className='flight_ticket_passenger_table_action_add' />
                    <p className='flight_ticket_passenger_table_action_text'>Add Customer</p>
                </div>
            )}
        </div>

    </div>

    <div className='flight_ticket_passenger_table_header_component'>
        <p className='flight_ticket_passenger_table_header_text'>Customer Details</p>
    </div>
    <div>
        <CustomExpandableTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} />
    </div>
</div>
  )
}

export default HotelCustomerTable