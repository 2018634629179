import React, { useEffect, useState } from 'react'
import './transactionReportStyle.css'
import { useSelector } from 'react-redux'
import CustomTable from '../../../../../components/custom_table/CustomTable'
import TransactionReportFilter from './sub_components/TransactionReportFilter'
import { get_transaction_report } from '../../../../../api/transactionApis'
import { ErrorToast } from '../../../../../toast/toasts'

function TransactionReports() {
    const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
    const [searchFilter, setSearchFilter] = useState(null)
    const [transactionReport, setTransactionReport] = useState(null)
   
    useEffect(() => {
       
        if (searchFilter) {
            get_transaction_report(searchFilter).then((response) => {
                if (response.code == 200) {
                    setTransactionReport(response.data)
                } else {
                    ErrorToast(response?.message)
                    setTransactionReport(null)
                }

            }).catch((error) => {
                console.log(error)
                setTransactionReport(null)
            })
        } else {
            setTransactionReport(null)
        }
    }, [searchFilter])
    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            key: "transaction_date",
        },
        {
            title: "Service",
            dataIndex: "service",
            key: "service",
        },
        {
            title: "Invoice #",
            dataIndex: "invoice_number",
            key: "invoice_number",
        },
        {
            title: "Payment",
            dataIndex: "payment",
            key: "payment",
        },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Debit",
            dataIndex: "debit",
            key: "debit",
        },
        {
            title: "Outstanding Amount",
            dataIndex: "outstanding_amount",
            key: "outstanding_amount",
        }
    ]
    const searchfilter = (value) => {
        setSearchFilter({...value})
    }
    const datas = () => {
        const result =
            transactionReport?.transactions &&
            transactionReport?.transactions.map((item, index) => {
                return {
                    key: index + 1,
                    id: index,
                    transaction_date: item.date,
                    service: item.service,
                    client: item.client,
                    invoice_number: item.invoice,
                    payment: item.method,
                    credit: item.credit,
                    debit: item.debit,
                    outstanding_amount: item.outstand,
                };
            });
        if (result?.length > 0) {
            result.push({
                is_highlight_total: true,
                key: '',
                id: result.length,
                transaction_date: '',
                service:'',
                client: '',
                invoice_number:"Total",
                payment: '',
                credit: transactionReport?.credit_total,
                debit: transactionReport?.debit_total,
                outstanding_amount: '',
            })
        }
        return result;
    };
    return (
        <div className='transaction_report_main_container' style={{ width: isCollapsed ? '90vw' : '82vw' }}>
            <div className='transaction_report_main_heading'>
                <p>Transaction Report</p>
            </div>
            <div>
                <TransactionReportFilter searchFilter={searchfilter} />
            </div>
            {transactionReport ? <>
                <div>
                    <p className='transaction_report_main_heading'>Transaction Statement - {transactionReport?.client} </p>
                </div>
                <div>
                    <div className='transaction_report_grid_container'>
                        <p className='transaction_report_label'>Customer Name</p>
                        <p>: {transactionReport?.client}</p>
                    </div>
                    <div className='transaction_report_grid_container'>
                        <p className='transaction_report_label'>Email Id</p>
                        <p>: {transactionReport?.email}</p>
                    </div>
                    <div className='transaction_report_grid_container'>
                        <p className='transaction_report_label'>Phone Number</p>
                        <p>: {transactionReport?.phone}</p>
                    </div>
                    <div className='transaction_report_grid_container'>
                        <p className='transaction_report_label'>Outstanding Amount</p>
                        <p className='transaction_report_label'>: {transactionReport?.amount}</p>
                    </div>
                </div>
                <div className='transaction_report_period_container'>
                    <p className='transaction_report_label'>Period From {searchFilter?.start_date} to {searchFilter?.end_date}</p>
                </div>
                <div>
                    <CustomTable columns={Columns} rows={datas()} />
                </div>
            </> :
                <div className="transaction_no_data_container">
                    <div className="no_data_sub_container">
                        <p>No data available</p>
                    </div>
                </div>}

        </div>
    )
}
export default TransactionReports