import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        isCollapsed: false,
        privilage_Details: null,
        notificationUpdate: false,
        notification_redirect: {}
    },
    reducers: {
        updateCollapsedState: (state, actions) => {
            state.isCollapsed = !state.isCollapsed
        },
        updatePrivilageDetails: (state, actions) => {
            state.privilage_Details = !state.privilage_Details
        },
        updateNotificationRedirect: (state, actions) => {
            state.notification_redirect = actions.payload
        },
        updateNotificationState: (state, _) => {
            state.notificationUpdate = !state.notificationUpdate
        },
        resetNotificationRedirect: (state, actions) => {
            state.notification_redirect = {}
        }
    }
})

export const { updateCollapsedState, updatePrivilageDetails, updateNotificationRedirect, resetNotificationRedirect, updateNotificationState } = commonSlice.actions

export default commonSlice.reducer