import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from 'formik'
import las_logo from '../assets/las_logo.png'
import m2_logo from '../assets/m2_logo.png'
import './resetPasswordStyle.css'
import CustomTextField from '../components/custom_text_field/CustomTextField';
import { useNavigate } from 'react-router-dom';
import { user_reset_password } from '../api/userApis';
import { ErrorToast, SuccessToast } from '../toast/toasts';
import { TailSpin } from 'react-loader-spinner';
function ResetPassword() {
const navigate=useNavigate()
const [token,setToken]=useState(null)
const [loading,setLoading]=useState(false)
useEffect(()=>{
    const url_token = new URLSearchParams(window.location.search).get('token');
    if(url_token){
        setToken(url_token)
    }else{
        navigate('/ecrs_portal/login')
    }
},[])

    const reset_password_formik = useFormik({
        initialValues: {
            "otp": "",
            "password": "",
            "confirm_password": ""
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("User name is required"),
            password: Yup.string()
                .required("New Password is required")
                .min(8, "Password must be at least 8 characters") // Minimum length rule
                .matches(/[A-Z]/, "Password must contain at least one uppercase letter") // Optional: At least one uppercase
                .matches(/[a-z]/, "Password must contain at least one lowercase letter") // Optional: At least one lowercase
                .matches(/\d/, "Password must contain at least one number") // Optional: At least one number
                .matches(/[@$!%*?&]/, "Password must contain at least one special character") , // Optional: Special character,
            confirm_password: Yup.string()
                .required("Confirm Password is required")
                .oneOf([Yup.ref("password"), null], "New password and confirm password do not match."),
        }),
        onSubmit: (values,{resetForm}) => {
            const value=JSON.parse(JSON.stringify(values))
            value['token'] = token
            setLoading(true)
            user_reset_password(value).then((response)=>{
                if(response.code==200){
                    SuccessToast(response.message)
                    navigate('/ecrs_portal/login')
                }else{
                    ErrorToast(response.message)
                }
            }).catch((error)=>{
                console.log(error)
            }).finally(()=>{
                setLoading(false)
            })
        }
    })
    return (
        <div className='reset_password_main_container'>
            <div className='reset_password_sub_container'>
                <div>
                    <div className='reset_password_form_main_container'>
                        <div className='reset_password_form_sub_container_1'>
                            <img src={las_logo} className='reset_password_form_las_logo' />
                            <img src={m2_logo} className='reset_password_form_m2_logo' />
                        </div>
                        <div className='reset_password_form_sub_container_2'>
                            <h5 className='reset_password_form_heading_title'>Forgot your password?</h5>
                            <p className='reset_password_form_sub_title'>Reset it here to regain access and seize your next opportunity!</p>
                        </div>
                        <form id="reset_password_form" onSubmit={reset_password_formik.handleSubmit}>
                            <div className='reset_password_form_fields_labels_container'>
                                <div>
                                    <p className='reset_password_label'>OTP</p>
                                    <CustomTextField
                                        name="otp"
                                        placeholder={"Enter otp"}
                                        onChange={reset_password_formik.handleChange}
                                        onBlur={reset_password_formik.handleBlur}
                                        value={reset_password_formik.values.otp}
                                        error={reset_password_formik.touched.otp &&
                                            Boolean(reset_password_formik.errors.otp)
                                        }
                                        helperText={
                                            reset_password_formik.touched.otp && reset_password_formik.errors.otp
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='reset_password_label'>New Password</p>
                                    <CustomTextField
                                        name="password"
                                        type="password"
                                        placeholder={"Enter password"}
                                        onChange={reset_password_formik.handleChange}
                                        onBlur={reset_password_formik.handleBlur}
                                        value={reset_password_formik.values.password}
                                        error={reset_password_formik.touched.password &&
                                            Boolean(reset_password_formik.errors.password)
                                        }
                                        helperText={
                                            reset_password_formik.touched.password && reset_password_formik.errors.password
                                        }
                                    />

                                </div>
                                <div>
                                    <p className='reset_password_label'>Confirm New Password</p>
                                    <CustomTextField
                                        name="confirm_password"
                                        type="password"
                                        placeholder={"Enter password"}
                                        onChange={reset_password_formik.handleChange}
                                        onBlur={reset_password_formik.handleBlur}
                                        value={reset_password_formik.values.confirm_password}
                                        error={reset_password_formik.touched.confirm_password &&
                                            Boolean(reset_password_formik.errors.confirm_password)
                                        }
                                        helperText={
                                            reset_password_formik.touched.confirm_password && reset_password_formik.errors.confirm_password
                                        }
                                    />

                                </div>
                                <button disabled={loading} className='reset_password_form_sign_up_container'
                                    type="submit"
                                    form="reset_password_form">{loading? <div className='submit_button_loader'>
                                        <TailSpin
                                        visible={loading}
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        /> 
                                    </div> :"Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword