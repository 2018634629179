import { ApiConfig } from "./apiConfig";

export const get_transactions = async (role_id) => {
    try {
      const response = await ApiConfig.get(`listTransacation_type`);
      return response.data;
    } catch (error) {
      throw error;
    }
};


export const get_transaction_report = async (data) => {
  try {
    const response = await ApiConfig.post(`getTransactionReport`,data);
    return response.data;
  } catch (error) {
    throw error;
  }
};