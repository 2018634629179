import React, { useEffect } from 'react'
import { get_status } from '../../../../../api/statusApis'
import { useFormik } from 'formik'
import './locationFilterStyle.css'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { useState } from 'react'

function LocationFilter({searchFilter}) {
    const [state, setStatus] = useState([])
    useEffect(() => {
        getStatus()
    }, [])
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            'name': '',
            'phone': '',
            'email_id': '',
            'contact_person_name': "",
            'status_id': ""
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'name': '',
                'phone': '',
                'email_id': '',
                'contact_person_name': "",
                'status_id': ""
            }
        })
        searchFilter(null)
    }
    return (
        <div className='location_filter_main_container'>
            <form id="locationFilterform" onSubmit={formik.handleSubmit}>
                <div className='location_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="name"
                            placeholder={"Enter name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                    </div>
                    <div>
                        <CustomTextField
                            name="email_id"
                            placeholder={"Enter email"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email_id}
                        />
                    </div>
                    <div>
                        <CustomTextField
                            name="phone"
                            placeholder={"Enter phone number"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                    </div>
                    <div>
                        <CustomTextField
                            name="contact_person_name"
                            placeholder={"Enter contact person name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.contact_person_name}
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="status_id"
                            placeholder={"Select a status"}
                            options={state}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "status_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                state.find((status) =>
                                    status.value ===
                                    formik.values.status_id
                                ) || null
                            }
                        />
                    </div>
                    <div className='location_filter_actions'>
                        <button className='location_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='location_filter_action_search'
                            type="submit"
                            form="locationFilterform"
                        >Search</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default LocationFilter