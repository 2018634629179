import React from 'react'
import './servicesStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { LocalStorageServices } from '../../../../services/localStorageServices';

function Services() {
    const baseRouteUrl=`/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings/create_new_service`
    const navigate=useNavigate()
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        wrapper: (row) => (
          <div >
            <p>{row.status}</p>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        action: (row) => (
          <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
            <BiEditAlt className='user_action_edit'  />
            <MdOutlineDeleteOutline className='user_action_delete'/>
          </div>
        ),
      },
    ];
    const data = [
      {
        key: "1",
        services: "Flight",
        status: "Active",
      },
      {
        key: "2",
        services: "Train",
        status: "Inactive",
      },
      {
        key: "3",
        services: "Bus",
        status: "Active",
      },
      {
        key: "4",
        services: "Cabs",
        status: "Active",
      },
      {
        key: "5",
        services: "Ship",
        status: "Inactive",
      }
    ];
    
    return (
      <div className='services_module_main_container'>
        <p className='services_title'>Services</p>
        <div className='service_module_filter'>
          <p>Filter</p>
          <button className='service_add_button' onClick={()=>{
            navigate(baseRouteUrl)
          }}>New</button>
        </div>
        <div className='service_table_main_container'>
          <CustomTable columns={Columns} rows={data}/>
        </div>
      </div>
    )
}

export default Services