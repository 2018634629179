import { ApiConfig } from "./apiConfig";


export const create_new_passport_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`addPassportBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const update_passportt_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`updatePassportBooking`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getPassport_Booking_lists_with_pagination = async (data) => {
    try {
        const response = await ApiConfig.post(`getPassportBookingsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};
export const get_Passport_Booking_details_by_id = async (data) => {
    try {
        const response = await ApiConfig.post(`getPassportBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const delete_Passport_Booking_by_id = async (id) => {
    try {
        const response = await ApiConfig.post(`deletePassportBooking`, id);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const Passport_Booking_cancel = async (data) => {
    try {
        const response = await ApiConfig.post(`changePassportBookingStatus`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const approve_or_reject_Passport_Booking = async (data) => {
    try {
        const response = await ApiConfig.post(`changePassportBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const Passport_Booking_review_comment = async (booking_id) => {
    try {
        const response = await ApiConfig.post(`getPassportBookingHistory`, booking_id);
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_passport_status = async () => {
    try {
      const response = await ApiConfig.post(`getStatusList`);
      return response;
    } catch (error) {
      throw error;
    }
  };