import React from 'react'
import CustomModal from '../custom_modal/CustomModal'
import './customDeleteConfirmModal.css'
function CustomDeleteConfirmModal({module_name,open_modal,close_modal,confirm_function,custom_text}) {
  return (
    <CustomModal
          open={open_modal}
          handleClose={close_modal}
          body={
            <div>
              <p className='custom_delete_confirm_modal_text'>{module_name?`Are you sure you want to delete ${module_name} ?`:custom_text}</p>
              <div className='custom_delete_confirm_modal_action_container'>
                <button className='custom_delete_confirm_modal_yes_action' onClick={()=>{
                  confirm_function()
                }}>Yes</button>
                <button className='custom_delete_confirm_modal_no_action' onClick={()=>{
                  close_modal(false)
                }}>No</button>
              </div>
            </div>
          }
          title={"Are You Sure ?"}
        />
  )
}

export default CustomDeleteConfirmModal