import React, { useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { hotel_booking_level_actions } from '../../../../../api/process_ecr/hotelBookingApis'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'

function HotelManagerLevelModal({ selected_item, open_modal, modal_close }) {
    const [comment, setComment] = useState('')
    const [showError, setShowError] = useState(false)
    const onSubmit = () => {
        if (!comment.trim()) return setShowError(true)
        // const currentDate = dayjs();
        // const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
        const formData = new FormData();
        formData.append('booking_id', selected_item?.id)
        formData.append('type', selected_item?.type)
        formData.append('comments', comment)

        // formData.append('FBD_ECRstatusID', selected_item?.type === "Approve" ? selected_item?.ecr_cancelled_status === 3 ? 11 : selected_item?.ecr_date_change_status === 3 ? 15 : 3 : 6)
        // formData.append('FBRH_review_date', formattedDate)
        hotel_booking_level_actions(formData).then((response) => {
            if (response?.data?.status === true) {
                SuccessToast(response?.data?.message)
                modal_close(false)
            }
        }).catch((error) => {
            ErrorToast(`Something went wrong!`)
            console.log(error)
        })
    }
  return (
    <div>
    <CustomModal
        open={open_modal}
        handleClose={modal_close}
        body={
            <div>
                <div>
                    <p className='manager_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                    <CustomTextArea
                        autoFocus
                        rows={10}
                        placeholder={"Enter a comment"}
                        onChange={(e) => {
                            setComment(e.target.value)
                            if (showError) {
                                setShowError(false)
                            }
                        }}
                    />
                    {showError && (<p className='comment_error_message'>Comment is required</p>)}
                </div>
                <div className='manager_level_modal_form_single_row_alignment'>
                    <button className='manager_level_modal_form_reset_button' onClick={() => {
                        modal_close(false)
                    }}>Cancel</button>

                    <button
                        className='manager_level_modal_form_update_button' onClick={() => {
                            onSubmit()
                        }}>Submit</button>
                </div>
            </div>
        }
        title_other={'Enquiry - ' + selected_item?.enquiry_number}
        title={selected_item?.type === "Review" || selected_item?.type ===  "CancelReviewed" ? "Review Booking" : "Reject Booking"}
    />
</div>
  )
}

export default HotelManagerLevelModal