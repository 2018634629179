import { ApiConfig } from "./apiConfig";

export const hotel_registation = async (data) => {
    try {
        const response = await ApiConfig.post(`addHotel`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const get_hotel_lists_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`getHotelsList`, data, { headers: { "Content-Type": "multipart/form-data" } });
      return response;
    } catch (error) {
      throw error;
    }
  };

export const get_hotels = async () => {
    try {
        const response = await ApiConfig.post(`getHotelsList`);
        return response.data?.data;

    } catch (error) {
        throw error;
    }
};
export const get_hotels_details = async (id) => {
    try {
        const response = await ApiConfig.post(`getHotelDetails`, id);
        return response.data?.data;

    } catch (error) {
        throw error;
    }
};
export const update_hotel_by_id = async (hotel_id, data) => {
    try {
        ///${hotel_id}
        const response = await ApiConfig.post(`updateHotelDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
    } catch (error) {
        throw error;
    }
};
export const delete_hotel_by_id = async (hotel_id) => {
    try {
        const response = await ApiConfig.post(`deleteHotelDetails`, hotel_id);
        return response.data;

    } catch (error) {
        throw error;
    }
};



  export const get_gst_List = async () => {
    try {
        const response = await ApiConfig.post(`getGstList`);

        return response.data?.data;

    } catch (error) {
        throw error;
    }
};