import { ApiConfig } from "./apiConfig";

export const get_services = async () => {
    try {
      const response = await ApiConfig.post(`EcrServices`);
      return response.data?.data;
    } catch (error) {
      throw error;
    }
};

export const get_services_list = async () => {
  try {
    const response = await ApiConfig.post(`EcrServices`);
    return response;
  } catch (error) {
    throw error;
  }
};