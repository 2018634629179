import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../components/custom_table/CustomTable'
import '../Sales_Report/SalesReport.css'
import { Select, MenuItem, FormControl, Box } from '@mui/material';
import CustomBottomPagination from '../../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { ApiConfig } from '../../../../../api/apiConfig';
import { ErrorToast } from '../../../../../toast/toasts';

const AR_Apreports = () => {
    const [selectedOption, setSelectedOption] = useState('B2B'); // Selected option state
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItemPerPage, setTotalItemPerPage] = useState(10)
    const [reportList, setReportList] = useState([])
    const [monthselected, setMonthselected] = useState("01")
    const [monthName, setmonthName] = useState("January")
    const getB2Breport = async () => {
        const req = {
            month: monthselected
        }
        const response = await ApiConfig.post('/flightBookingReport_B2B', req).then((response) => {
            setReportList(response.data.data.data)
            setTotalPages(response.data.last_page)
        }).catch((error) => {
            let message = "";
            for (const key in error.data.errors) {
                if (error.data.errors.hasOwnProperty(key)) {
                    const keyMessages = error.data.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }


    // Handle the change of the select box
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'B2B') {
            getB2Breport();
        } else if (selectedOption === 'B2C') {
        }
    };
    useEffect(() => {
        if (selectedOption === 'B2B') {
            getB2Breport();
        } else if (selectedOption === 'B2C') {
        }
    }, [monthselected])
    const updatePagination = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const updateItemPageCount = (pageItemCount) => {
        setTotalItemPerPage(pageItemCount)
    }

    const Columns = [
        {
            title: "Sl.No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Invoice Date",
            dataIndex: "Invoice_Date",
            key: "Invoice_Date",
        },
        {
            title: "Invocie No",
            dataIndex: "Invocie_No",
            key: "Invocie_No",
        },
        {
            title: "Name of Client",
            dataIndex: "Name_of_Client",
            key: "Name_of_Client",
        },
        {
            title: "GSTIN No",
            dataIndex: "GSTIN_No",
            key: "GSTIN_No",
        },
        {
            title: "Service Details",
            dataIndex: "Service_Details",
            key: "Service_Details",
        },
        {
            title: "Taxable  Amount",
            dataIndex: "Taxable_Amount",
            key: "Taxable_Amount",
        },
        {
            title: "GST Rate",
            dataIndex: "GST_Rate",
            key: "GST_Rate",
        },
        {
            title: "CGST",
            dataIndex: "CGST",
            key: "CGST",
        },
        {
            title: "SGST",
            dataIndex: "SGST",
            key: "SGST",
        },
        {
            title: "IGST",
            dataIndex: "IGST",
            key: "IGST",
        },
        {
            title: "Total GST",
            dataIndex: "Total_GST",
            key: "Total_GST",
        },
        {
            title: "Supplier Amount",
            dataIndex: "Supplier_Amount",
            key: "Supplier_Amount",
        },
        {
            title: "Processing Charge",
            dataIndex: "Processing_Charge",
            key: "Processing_Charge",
        },
        {
            title: "Total Invoice Value",
            dataIndex: "TotalInvoice_value",
            key: "TotalInvoice_value",
        },
        {
            title: "Exempted",
            dataIndex: "Exempted",
            key: "Exempted",
        },
        {
            title: "Refund",
            dataIndex: "Refund",
            key: "Refund",
        },
        {
            title: "Total Sales",
            dataIndex: "Total_Sales",
            key: "Total_Sales",
        },
        {
            title: "Remarks",
            dataIndex: "Remarks",
            key: "Remarks",
        },
        {
            title: "Amount Received ",
            dataIndex: "Amount_Received",
            key: "Amount_Received",
        },
        {
            title: "Date of Rcpt",
            dataIndex: "DateofRcpt",
            key: "DateofRcpt",
        },
        {
            title: "Rcpt No",
            dataIndex: "RcptNo",
            key: "RcptNo",
        },
        {
            title: "Balance  Rcvble",
            dataIndex: "BalanceRcvble",
            key: "BalanceRcvble",
        },
        {
            title: "Bank",
            dataIndex: "Bank",
            key: "Bank",
        },
        {
            title: "Amount paid to supplier",
            dataIndex: "Amountpaidto_supplier",
            key: "Amountpaidto_supplier",
        },
        {
            title: "Date",
            dataIndex: "Date",
            key: "Date",
        },
        {
            title: "Bank",
            dataIndex: "Bank_s",
            key: "Bank_s",
        },
        {
            title: "Balance to Supplier",
            dataIndex: "Balanceto_Supplier",
            key: "Balanceto_Supplier",
        },
    ]
    const Columns_B2C = [
        {
            title: "Sl.No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Invoice Date",
            dataIndex: "Invoice_Date",
            key: "Invoice_Date",
        },
        {
            title: "Invocie No",
            dataIndex: "Invocie_No",
            key: "Invocie_No",
        },
        {
            title: "Name of Client",
            dataIndex: "Name_of_Client",
            key: "Name_of_Client",
        },
        {
            title: "GSTIN No",
            dataIndex: "GSTIN_No",
            key: "GSTIN_No",
        },
        {
            title: "Service Details",
            dataIndex: "Service_Details",
            key: "Service_Details",
        },
        {
            title: "Taxable  Amount",
            dataIndex: "Taxable_Amount",
            key: "Taxable_Amount",
        },
        {
            title: "GST Rate",
            dataIndex: "GST_Rate",
            key: "GST_Rate",
        },
        {
            title: "CGST",
            dataIndex: "CGST",
            key: "CGST",
        },
        {
            title: "SGST",
            dataIndex: "SGST",
            key: "SGST",
        },
        {
            title: "IGST",
            dataIndex: "IGST",
            key: "IGST",
        },
        {
            title: "Total GST",
            dataIndex: "Total_GST",
            key: "Total_GST",
        },
        {
            title: "Supplier Amount",
            dataIndex: "Supplier_Amount",
            key: "Supplier_Amount",
        },
        {
            title: "Processing Charge",
            dataIndex: "Processing_Charge",
            key: "Processing_Charge",
        },
        {
            title: "Total Invoice Value",
            dataIndex: "TotalInvoice_value",
            key: "TotalInvoice_value",
        },
        {
            title: "Exempted",
            dataIndex: "Exempted",
            key: "Exempted",
        },
        {
            title: "Refund",
            dataIndex: "Refund",
            key: "Refund",
        },
        {
            title: "Total Sales",
            dataIndex: "Total_Sales",
            key: "Total_Sales",
        },
        {
            title: "Remarks",
            dataIndex: "Remarks",
            key: "Remarks",
        }
    ]
    const datas = () => {
        const result =
            reportList && reportList.map((item, index) => {
                return {
                    key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
                    Invocie_No: item.FBD_invoice_number,
                    Supplier_Amount: item.SupplierAmount,
                    Taxable_Amount: item.TaxableAmount,
                    Name_of_Client: item.client_name,
                    GSTIN_No: item.gst_number,
                    LastMargin: item.las_Margin,
                    CGST: item.CGST,
                    Exempted: item.Exepmted,
                    IGST: item.IGST,
                    Refund: item.Refund,
                    Remarks: item.Remark,
                    SGST: item.SGST,
                    Total_GST: item.TotalGST,
                    TotalInvoice_value: item.TotalInvoice,
                    Total_Sales: item.Totalsale,
                    Agent_Commision: item.agent_commission,
                    Total_Sales: item.Totalsale,
                    Total_Sales: item.Totalsale,

                };
            });
        return result;
    };
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ];
    const getmonthName = (value) => {
        const res = months && months.filter((val) => {
            return Number(val.value) === Number(value)
        })
        setmonthName(res?.[0].label)
    }
    return (
        <div className='sales_report_main_container'>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 2, // Adjust gap for proper spacing between select boxes
                }}
            >
                <FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={selectedOption}
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="B2B">B2B Sales</MenuItem>
                        <MenuItem value="B2C">B2C Sales</MenuItem>
                    </Select>
                </FormControl>

                <FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={monthselected}
                        onChange={(e) => {
                            setMonthselected(e.target.value);
                            getmonthName(e.target.value);
                        }}
                    >
                        {months &&
                            months.map((val) => {
                                return <MenuItem value={val.value}>{val.label}</MenuItem>;
                            })}
                    </Select>
                </FormControl>
            </Box>

            {
                selectedOption === 'B2B' ? <div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>{`AR & AP Report for the month of - ${monthName}`}  </p>
                    </div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>B2 B Sales   </p>
                    </div>
                    <CustomTable columns={Columns} />
                    <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
                </div> : selectedOption === 'B2C' ? <div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>{`AR & AP Report for the month of - ${monthName}`}  </p>
                    </div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>B2 C Sales   </p>
                    </div>
                    <CustomTable columns={Columns_B2C} />
                    <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
                </div> : null

            }

        </div>
    )
}

export default AR_Apreports