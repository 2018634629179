import React from 'react'
import './customCardV1Style.css'
function CustomCardV1({ miniContainerColor,borderColor,backgroundColor,image,text,children}) {
  return (
    <div className='custom_card_v1_main_container' style={{backgroundColor,border:"1px solid"+borderColor}}>
        <div className='custom_card_v1_sub_container' style={{backgroundColor:miniContainerColor}}>
            <img src={image} className='card_image'/>
            <div className='custom_card_v1_sub_container_text'>
              <p className='custom_card_v1_sub_text'>{text}</p>
            </div>
           
        </div>
        <div style={{height:130,marginTop:20}}>
            {children}
        </div>
    </div>
  )
}

export default CustomCardV1