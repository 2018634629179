import React, { useEffect } from 'react'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import './roleStyle.css'
import { useNavigate } from 'react-router-dom';
import { get_roles_with_pagination, role_deletion } from '../../../../api/rolesApis';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import { useState } from 'react';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import RoleFilter from './role_filter/RoleFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';
import CustomDeleteConfirmModal from '../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
function Role() {
  const navigate = useNavigate()
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/user_management`
  const [rolesListing, setRolesListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const [searchFilter, setSearchFilter] = useState(null)
  const [selectedId, setSelectedId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    get_roles(pageNumber, searchFilter)
  }, [pageNumber, totalItemPerPage])

  const get_roles = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key]);
      }
    }
    get_roles_with_pagination(formData).then((response) => {
      setRolesListing(response.data.data)
      setTotalPages(response.data.last_page)
    }).catch((error) => {
      let message = "";
      for (const key in error.data.errors) {
        if (error.data.errors.hasOwnProperty(key)) {
          const keyMessages = error.data.errors[key].map(message => message);
          message = message.concat(keyMessages);
        }
      }
      ErrorToast(message)
    })
  }

  // Get privileges details from local storage
  const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // Check if a specific privilege exists in User Management -> Role
  const hasPrivilege = (privilegeName) =>
    getPrivilegesDetails().some(
      (item) =>
        item.module_name === "User Management" &&
        item.submodules?.["Role"]?.privileges?.some((priv) => priv.name === privilegeName)
    );

  const Is_User_View = hasPrivilege("View");
  const Is_User_Add = hasPrivilege("Add");
  const Is_User_Edit = hasPrivilege("Edit");
  const Is_User_Delete = hasPrivilege("Delete");


  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      wrapper: (row) => (
        <div >
          <p style={{ color: row.status == 'Active' ? '#0A9E02' : "#FF0000" }}>{row.status}</p>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
          {Is_User_View && <PiEye className='role_action_view' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/view_role`)
          }} />
          }
          {Is_User_Edit && <BiEditAlt className='role_action_edit' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/update_role`)
          }} />}
          {Is_User_Delete && <MdOutlineDeleteOutline className='role_action_delete' onClick={() => {
            setSelectedId(row.id)
            setDeleteConfirmation(true)
          }} />}


        </div>
      ),
    },
  ];
  const delete_role = () => {
    if (selectedId) {
      role_deletion(selectedId).then((response) => {
        handleDeleteModalClose(false)
        SuccessToast(response.data.message)
        get_roles(pageNumber)
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
        console.log(error)
      })
    }

  }

  const datas = () => {
    const result =
      rolesListing &&
      rolesListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.role_id,
          role: item.role_name,
          status: item.status_name,
        };
      });
    return result;
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }

  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      get_roles(pageNumber, value)
    } else {
      get_roles(1, value)
      setPageNumber(1)
    }
  }

  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };

  return (
    <div className='roles_module_main_container'>
      <p className='role_title'>Roles</p>
      <div className='role_module_filter'>
        <RoleFilter searchFilter={searchfilter} />
        {Is_User_Add && <button className='role_add_button' onClick={() => {
          navigate(baseRouteUrl + `/create_new_role`)
        }}>Add New</button>}
      </div>
      <div className='role_table_main_container'>
        <CustomTable columns={Columns} rows={datas()} />
      </div>
      <div>
        <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
      </div>
      <CustomDeleteConfirmModal module_name={'role'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={delete_role} />
    </div>
  )
}

export default Role