import React from 'react'
import "./customPopOverMenuStyle.css"
import { useNavigate } from 'react-router-dom';
function CustomPopOverMenu( {visibilityChange,text,initialRoute, subRoute }) {
    const navigate=useNavigate()
  return (
    <div className="CustomPopOverMenu_MainConatiner">
    {subRoute ? (
      <>
        {subRoute.map((item) => {
          return (
            <p
            className="CustomPopOverMenu_Text"
            style={{
                color:
                window.location.pathname == item.route ? '#5C7F7F' : item?.otherRoutes?.includes(window.location.pathname.split("/").pop())? '#5C7F7F': '' 
              }}
              onClick={()=>{
                  visibilityChange(false)
                  navigate(item.route)
              }}
            >
              {item.text}
            </p>
          );
        })}
      </>
    ) : (
      <p
        className="CustomPopOverMenu_Text"
        style={{
            color:
            window.location.pathname === initialRoute ? "#5C7F7F" : "",
          }}
        onClick={()=>{
          visibilityChange(false)
          navigate(initialRoute)
      }}
      >
        {text} 
      </p> 
    )}
  </div>
  )
}

export default CustomPopOverMenu