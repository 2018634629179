// Created By - Sajin S Kumar
// Date - 25-11-2024
// Pupose - Airline Listing page - >Master settings ->General settings


import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../../components/custom_table/CustomTable'
import './airlineStyle.css'
import { useNavigate } from 'react-router-dom';
import AirlineForm from './sub_pages/AirlineForm';
import { delete_airline_by_id, get_airline_list } from '../../../../../../api/generalSettingApis';
import CustomBottomPagination from '../../../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { IconButton } from '@mui/material';
import { ErrorToast, SuccessToast } from '../../../../../../toast/toasts';
import CustomDeleteConfirmModal from '../../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
function Airlines({ isSavePermission }) {
  const [airlineModal, setAirlineModal] = useState(false)
  const [airlineListing, setAirlineListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    getAirlineList(pageNumber, totalItemPerPage)
  }, [airlineModal,pageNumber])

  const getAirlineList = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    get_airline_list(formData).then((response) => {
      setAirlineListing(response.data.data.data)
      setTotalPages(response.data.data.last_page)
    }).catch((error) => {
      console.log(error)
    })
  }
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Airline Name",
      dataIndex: "airline_name",
      key: "airline_name",
    },
    {
      title: "IATA Code",
      dataIndex: "iata_code",
      key: "iata_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      wrapper: (row) => (
        <div >
          <p style={{ color: row.status == 'Active' ? '#0A9E02' : "#FF0000", textAlign: isSavePermission ? '' : 'center' }}>{row.status}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      action: (row) => (
        <div>
          <IconButton onClick={() => {
            updateAirlineModalStates(true, row.id)
          }}><BiEditAlt className='airline_edit' /></IconButton>
          <IconButton onClick={() => {
            handleDeleteModalClose(true, row.id)
          }} ><MdOutlineDeleteOutline className='airline_delete' /></IconButton>
        </div>
      )
    },
  ];
  // Filter the columns dynamically when isSavePermission is true show all columns otherwise hide action 
  const visibleColumns = isSavePermission
    ? Columns
    : Columns.filter((column) => column.key !== "action");

  const updateAirlineModalStates = (state, selected_id) => {
    setAirlineModal(state)
    setSelectedItemId(selected_id)
  }
  const handleDeleteModalClose = (state, selected_id) => {
    setDeleteConfirmation(state);
    setSelectedItemId(selected_id)
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }
  const deleteAirline = () => {
    if (selectedItemId) {
      delete_airline_by_id(selectedItemId).then((response) => {
        handleDeleteModalClose(false)
        SuccessToast(response.data.message)
        getAirlineList(pageNumber, totalItemPerPage)
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
      })
    }
  }
  const datas = () => {
    const result =
      airlineListing &&
      airlineListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.AirlineID,
          airline_name: item.AirlineName,
          iata_code: item.IATA_Code,
          status: item?.status?.status_name,
        };
      });
    return result;
  };

  return (
    <div>
      <div className='airline_add_container'>
        {isSavePermission && <button className='airline_add_button' onClick={() => {
          updateAirlineModalStates(true)
        }}> Add New</button>}
      </div>
      <CustomTable columns={visibleColumns} rows={datas()} />
      <div>
        <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
      </div>
      {airlineModal ? <AirlineForm open={airlineModal} handleClose={updateAirlineModalStates} airline_id={selectedItemId} /> : <></>}
      {deleteConfirmation ? <CustomDeleteConfirmModal module_name={'airline'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deleteAirline} /> : <></>}
    </div>
  )
}

export default Airlines