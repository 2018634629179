import React, { useEffect, useState } from 'react'
import './flightPaymentInformation.css'
import { get_payment_mode_list, get_payment_status_list } from '../../../../../api/paymentApis'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { IconButton } from '@mui/material'
import { MdDelete } from "react-icons/md";
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { useLocation } from 'react-router-dom'
import CustomTable from '../../../../../components/custom_table/CustomTable'
function FlightPaymentInformation({ table_data, formik, paymentAttachmnet, updatePaymentAttachment, inline_action,form_visibility ,no_form_privilage}) {
    const location = useLocation()
    const [paymentModes, setPaymentModes] = useState([])
    const [paymentStatus, setPaymentStatus] = useState([])
    const [isView, setIsView] = useState(null)
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        }
        getPaymentModeList()
        getPaymentStatusList()
    }, [])
    const getPaymentModeList = () => {
        get_payment_mode_list().then((response) => {
            const data = response?.sort((a, b) => a.col_index - b.col_index)?.map((item) => {
                return {
                    label: item.ESPS_name,
                    value: item.ESPS_id,
                };
            });
            setPaymentModes(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getPaymentStatusList = () => {
        get_payment_status_list().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.EPS_name,
                    value: item.EPS_id,
                };
            });
            setPaymentStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    function handleFileSelect(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        updatePaymentAttachment({ file: file, fileName: fileName, fileType: fileType })
    }
    const Columns = [
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Payment Status",
            dataIndex: "payment_status",
            key: "payment_status",
        },
        {
            title: "Payment Method",
            dataIndex: "payment_method",
            key: "payment_method",
        },
        {
            title: "Date and Time",
            dataIndex: "date_and_time",
            key: "date_and_time",
        },
        {
            title: "Ticket Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "Amount Paid",
            dataIndex: "total_paid_amount",
            key: "total_paid_amount",
        },
        {
            title: "Outstanding Amount",
            dataIndex: "balance_amount",
            key: "balance_amount",

        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
        },
        {
            title: "Attachment",
            dataIndex: "attachment",
            key: "attachment",
            wrapper: (row) => (
                <div >
                    {row.attachment_name ? <p className='flight_payment_info_download_attachment_action_text' onClick={() => {
                        if (row.attachment_url) {
                            CommonHelpers.DownloadAssets(row.attachment_url)
                        }
                    }}>{row.attachment_name}</p> : <p>-</p>}

                </div>
            ),
        },
    ];
    const datas = () => {
        let modifiedTableData=[...table_data]?.slice(0, -1)
        const result =
        modifiedTableData &&
        modifiedTableData?.map((item, index) => {
                return {
                    key: index + 1,
                    index: index,
                    payment_status: item.EPH_payment_status_name,
                    payment_method: item.EPH_payment_method_name,
                    total_amount: item.EPH_total_amount,
                    total_paid_amount: item.EPH_paid_amount,
                    balance_amount: item.EPH_balance_paid_amount,
                    date_and_time: item?.EPH_created_at,
                    remark: item.EPH_remark,
                    attachment_name: item.EPH_attchment,
                    attachment_url: item.EPH_attchment_url,
                };
            });
        return result;
    };

    return (
        <div>
            {no_form_privilage ||  (formik?.values?.outstanding_amount && formik?.values?.outstanding_amount > 0)  ?<>
                {!isView && (no_form_privilage?true:form_visibility)  && (
                <form id="payment_info_form" onSubmit={formik.handleSubmit} className={inline_action ? 'flight_payment_information_container' : ''}>
                    <div className='flight_payment_information_grid'>
                        <div>
                            <p className='flight_ticket_form_label'>Payment Status{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomAutoCompleteDropdown
                                isDisabled={isView}
                                name="payment_status_id"
                                placeholder={"Select a payment status"}
                                options={paymentStatus}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        "payment_status_id",
                                        newValue ? newValue.value : ""
                                    );
                                    // if(formik.values.payment_status_id==1){
                                    //     if(!formik.values.paid_amount){
                                    //         formik.setFieldValue("paid_amount",formik.values.total_amount);
                                    //     }
                                    // }else{
                                    //     formik.setFieldValue("paid_amount",0);
                                    // }

                                }}
                                onBlur={formik.handleBlur}
                                value={
                                    paymentStatus.find((type) =>
                                        type.value ===
                                        formik.values.payment_status_id
                                    ) || null
                                }
                                error={formik.touched.payment_status_id &&
                                    Boolean(formik.errors.payment_status_id)
                                }
                                helperText={
                                    formik.touched.payment_status_id && formik.errors.payment_status_id
                                }
                            />
                        </div>
                        <div>
                            <p className='flight_ticket_form_label'>Payment Mode{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomAutoCompleteDropdown
                                isDisabled={isView}
                                name="payment_method_id"
                                placeholder={"Select a payment mode"}
                                options={paymentModes}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        "payment_method_id",
                                        newValue ? newValue.value : ""
                                    );

                                }}
                                onBlur={formik.handleBlur}
                                value={
                                    paymentModes.find((type) =>
                                        type.value ===
                                        formik.values.payment_method_id
                                    ) || null
                                }
                                error={formik.touched.payment_method_id &&
                                    Boolean(formik.errors.payment_method_id)
                                }
                                helperText={
                                    formik.touched.payment_method_id && formik.errors.payment_method_id
                                }
                            />
                        </div>
                        <div>
                            <p className='flight_ticket_form_label'>Ticket Amount{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomTextField
                                isDisabled={true}
                                name="total_ticket_amount"
                                placeholder={"Enter amount"}
                                value={formik.values.total_ticket_amount}
                            />
                        </div>
                        <div>
                            <p className='flight_ticket_form_label'>Oustanding Amount{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomTextField
                                isDisabled={true}
                                name="outstanding_amount"
                                placeholder={"Enter amount"}
                                value={formik.values.outstanding_amount}
                            />
                        </div>
                        <div>
                            <p className='flight_ticket_form_label'>Enter Amount To Pay{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomTextField
                                isDisabled={isView}
                                inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                type={'number'}
                                name="enter_amount_to_pay"
                                placeholder={"Enter amount"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.enter_amount_to_pay}
                                error={formik.touched.enter_amount_to_pay &&
                                    Boolean(formik.errors.enter_amount_to_pay)
                                }
                                helperText={
                                    formik.touched.enter_amount_to_pay && formik.errors.enter_amount_to_pay
                                }
                            />
                        </div>
                    </div>
                    <div className='flight_payment_information_attachment_remark_grid'>
                        <div>
                            <p className='flight_ticket_form_label'>Attachment</p>
                            {paymentAttachmnet ? <div className='flight_payment_info_container'>
                                <p className='flight_payment_info_attchment_item_text' onClick={() => {
                                    if (paymentAttachmnet?.file_url) {
                                        CommonHelpers.DownloadAssets(paymentAttachmnet?.file_url)
                                    } else {
                                        CommonHelpers.DownloadFileObjectAssets(paymentAttachmnet.fileName, paymentAttachmnet.file)
                                    }

                                }}>{paymentAttachmnet.fileName}</p>
                                {!isView && (
                                    <IconButton onClick={() => {
                                        updatePaymentAttachment(null)
                                    }}><MdDelete className='flight_payment_info_delete' /></IconButton>
                                )}

                            </div> : <div style={{ margin: '20px 0px' }}>
                                <label htmlFor="payment_indo_file" className="flight_ticket_choose_file_field">
                                    <span id="fileInfo">Choose file</span>
                                </label>
                                <input
                                    id="payment_indo_file"
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/jpeg,image/png,application/pdf"
                                    onChange={(event) => handleFileSelect(event)}
                                />
                            </div>}

                        </div>
                        <div>
                            <p className='flight_ticket_form_label'>Remark{no_form_privilage && table_data?.length>0?'': <span className='label_required_remark'>*</span>}</p>
                            <CustomTextArea
                                rows={4}
                                name="remark"
                                placeholder={"Enter a remark"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.remark}
                                error={formik.touched.remark &&
                                    Boolean(formik.errors.remark)
                                }
                                helperText={
                                    formik.touched.remark && formik.errors.remark
                                }
                            />
                        </div>
                    </div>
                    {inline_action ?
                        <div className='flight_payment_information_action'>
                            <button
                                className='flight_payment_information_form_save_button'
                                type="submit"
                                form="payment_info_form">Save</button>
                        </div> : <></>}
                </form>
            )}
            </>:<></>}
            <div>
                <CustomTable columns={Columns} rows={datas()} />
            </div>


        </div>
    )
}

export default FlightPaymentInformation