import { styled, TextField } from '@mui/material';
import React from 'react'

function CustomTextField({ id, name, defaultValue, onMouseDown, placeholder, type, onChange, onBlur, value, error, helperText, inputProps, isDisabled ,autoFocus}) {
    const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
            fontFamily: 'Poppins',
            fontSize:'15px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#C0C0C0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C0C0C0',
            },

        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#272727",
        },
    };

    return (
        <div>
            <TextField
                autoFocus={autoFocus}
                id={id}
                disabled={isDisabled}
                className="modified_text_field"
                name={name}
                fullWidth
                autoComplete="off"
                size="small"
                defaultValue={defaultValue}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error}
                type={type}
                helperText={helperText}
                FormHelperTextProps={{
                    style: { marginLeft: 0 },
                }}
                variant="outlined"
                sx={ModifiedTextField}
                InputProps={inputProps}
                onMouseDown={onMouseDown}

            />
        </div>
    )
}

export default CustomTextField