import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField'
import '../supplierFormStyle.css'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useLocation, useParams } from 'react-router-dom';
import SupplierBankTable from '../SupplierBankTable';
import { get_supplier_by_id } from '../../../../../../api/supplierApis';


function SupplierBankDetailsForm({ bankDetails, onUpdateBankDetails, UpdateParticularBankDetails, DeleteParticularBankDetails }) {
    const { supplier_id } = useParams()
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [editDetails, setEditDetails] = useState(null)
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_supplier") {
            setIsView(true)
        }
        if (supplier_id) {
            get_supplier_by_id(supplier_id).then((response) => {
                const response_data = response
                onUpdateBankDetails(response_data.sbd)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [supplier_id])
    const formik = useFormik({
        initialValues: {
            "SBD_bank": "",
            "SBD_account_name": "",
            "SBD_account_num": "",
            "SBD_branch_name": "",
            "SBD_ifsc_code": "",
        },
        validationSchema: Yup.object({
            SBD_bank: Yup.string().required("Bank name is required"),
            SBD_account_num: Yup.string().required("Account number is required"),
            SBD_account_name: Yup.string().required("Account name is required"),
            SBD_branch_name: Yup.string().required("Branch name is required"),
            SBD_ifsc_code: Yup.string().required("IFSC code is required")
        }),
        onSubmit: (values, { resetForm }) => {
            if (editDetails) {
                UpdateParticularBankDetails(editDetails.id, values)
                setEditDetails(null)
            } else {
                onUpdateBankDetails(values)
            }
            resetForm()
        }
    })

    const onEditDetails = (value) => {
        formik.setValues({
            ...formik.values,
            "SBD_bank": value.bank_name,
            "SBD_account_name": value.account_name,
            "SBD_account_num": value.account_number,
            "SBD_branch_name": value.branch_name,
            "SBD_ifsc_code": value.ifsc_code,
        })
        setEditDetails(value)
    }
    const onDeleteItemIndex = (index) => {
        DeleteParticularBankDetails(index)
    }
    return (
        <div>
            {!isView && (
                <form id="supplier_bank_details_form" onSubmit={formik.handleSubmit} >
                    <div className='suppliers_form_main_container'>
                        <p className="suppliers_form_legend"> Bank Details</p>

                        <div className='suppliers_form_sub_container_1'>

                            <div>
                                <p className='suppliers_form_label'>Bank Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="SBD_bank"
                                    placeholder={"Enter bank name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.SBD_bank}
                                    error={formik.touched.SBD_bank &&
                                        Boolean(formik.errors.SBD_bank)
                                    }
                                    helperText={
                                        formik.touched.SBD_bank && formik.errors.SBD_bank
                                    }
                                />
                            </div>
                            <div>
                                <p className='suppliers_form_label'>Account No<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="SBD_account_num"
                                    placeholder={"Enter account no"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.SBD_account_num}
                                    error={formik.touched.SBD_account_num &&
                                        Boolean(formik.errors.SBD_account_num)
                                    }
                                    helperText={
                                        formik.touched.SBD_account_num && formik.errors.SBD_account_num
                                    }
                                />
                            </div>
                            <div>
                                <p className='suppliers_form_label'>Branch<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="SBD_branch_name"
                                    placeholder={"Enter branch"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.SBD_branch_name}
                                    error={formik.touched.SBD_branch_name &&
                                        Boolean(formik.errors.SBD_branch_name)
                                    }
                                    helperText={
                                        formik.touched.SBD_branch_name && formik.errors.SBD_branch_name
                                    }
                                />
                            </div>
                            <div>
                                <p className='suppliers_form_label'>IFSC Code<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="SBD_ifsc_code"
                                    placeholder={"Enter ifsc code"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.SBD_ifsc_code}
                                    error={formik.touched.SBD_ifsc_code &&
                                        Boolean(formik.errors.SBD_ifsc_code)
                                    }
                                    helperText={
                                        formik.touched.SBD_ifsc_code && formik.errors.SBD_ifsc_code
                                    }
                                />
                            </div>


                            <div>
                                <p className='suppliers_form_label'>Account Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="SBD_account_name"
                                    placeholder={"Enter account name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.SBD_account_name}
                                    error={formik.touched.SBD_account_name &&
                                        Boolean(formik.errors.SBD_account_name)
                                    }
                                    helperText={
                                        formik.touched.SBD_account_name && formik.errors.SBD_account_name
                                    }
                                />
                            </div>
                        </div>

                        <div className='suppliers_bank_form_sub_action'>
                            {editDetails ? <button className='suppliers_form_save_button' type="button"
                                onClick={() => {
                                    formik.resetForm()
                                    setEditDetails(null)
                                }}
                            >Cancel</button> : <></>}
                            <button className='suppliers_form_save_button' type="submit"
                                form="supplier_bank_details_form">{editDetails ? 'Update' : 'Add'} Bank</button>
                        </div>

                    </div>
                </form>
            )}

            <div style={{ marginTop: '10px' }}>
                <SupplierBankTable rows={bankDetails} onEditDetails={onEditDetails} onDeleteItemIndex={onDeleteItemIndex} />
            </div>
        </div>
    )
}

export default SupplierBankDetailsForm