export class CommonHelpers{
    static DownloadAssets(url){
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    static DownloadFileObjectAssets(fileName,file){
        const link = document.createElement('a')
        link.download = fileName
        link.href = URL.createObjectURL(file)
        link.click()
    }
}

