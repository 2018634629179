import React, { useEffect, useState } from 'react'
import './notificationStyle.css'
import { get_user_notification, user_notification_read } from '../../../api/userApis'
import { GoDotFill } from "react-icons/go";
import CustomNotificationContainer from '../../../components/custom_notification_container/CustomNotificationContainer';
function Notifications() {
    const [notificationList,setNotificationList]=useState([])
    useEffect(()=>{
        get_all_notifications()
    },[])
    const get_all_notifications=()=>{
        get_user_notification().then((response)=>{
            setNotificationList(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    }
    const update_notification_read=(notification_id)=>{
        user_notification_read(notification_id).then((response)=>{
            get_all_notifications()
        }).catch((error)=>{
            console.log(error)
        })
    }
  return (
    <div className='notification_module_main_container'>
       <CustomNotificationContainer notificationList={notificationList} update_notification_read={update_notification_read}/>
    </div>
  )
}

export default Notifications