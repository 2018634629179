import React, { useEffect } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './locationFormStyle.css'
import { get_status } from '../../../../../api/statusApis';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useState } from 'react';
import { get_location_by_id, location_registation, update_location_by_id } from '../../../../../api/locationApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
function LocationForm() {
    const navigate = useNavigate()
    let { location_id } = useParams();
    const [state, setStatus] = useState([])
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_location") {
            setIsView(true)
        }
        getStatus()
        if (location_id) {
            get_location_by_id(location_id).then((response) => {
                const response_data = response.data
                formik.setValues({
                    ...formik.values,
                    'ld_name': response_data.ld_name?response_data.ld_name:"",
                    'ld_email_id': response_data.ld_email_id?response_data.ld_email_id:"",
                    'ld_phone': response_data.ld_phone?response_data.ld_phone:"",
                    'ld_address': response_data.ld_address?response_data.ld_address:"",
                    'ld_pincode': response_data.ld_pincode?response_data.ld_pincode:"",
                    'ld_contact_person_name': response_data.ld_contact_person_name?response_data.ld_contact_person_name:"",
                    'ld_statusId': response_data.ld_statusId?response_data.ld_statusId:""
                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [location_id])

    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const formik = useFormik({
        initialValues: {
            'ld_name': '',
            'ld_email_id': '',
            'ld_phone': '',
            'ld_address': '',
            'ld_pincode': '',
            'ld_contact_person_name': '',
            'ld_statusId':1
        },
        validationSchema: Yup.object({
            ld_name: Yup.string().required("Name is required"),
            // contact_person_name: Yup.string().required("Contact person name is required"),
            // email_id: Yup.string().email("Invalid email address").required("Email is required"),
            ld_statusId: Yup.string().required("Status is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    formData.append(key, values[key]);
                }
            }
            if (location_id) {
                update_location_by_id(location_id, formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                location_registation(formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.status)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }
        }
    });
    const ResetForm = () => {
        formik.setValues({
            ...formik.values,
            'ld_name': '',
            'ld_email_id': '',
            'ld_phone': '',
            'ld_address': '',
            'ld_pincode': '',
            'ld_contact_person_name': '',
            'ld_statusId':1
        });
    }
    return (
        <div className='locations_form_super_container'>
            <div >
                <div className='locations_form_sub_container'>
                    <div className='locations_form_single_row_alignment'>
                        <IoArrowBackSharp className='locations_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='locations_form_title'>{location_id && isView ? "View" : location_id ? 'Update' : "Add New"} location</p>
                    </div>
                    {!isView && (
                        <div className='locations_form_single_row_alignment'>
                            {!location_id && (
                                <button className='locations_form_reset_button' onClick={() => {
                                    ResetForm()
                                }}>Reset</button>
                            )}

                            <button className='locations_form_save_button'
                                type="submit"
                                form="locationsform"
                            >Save</button>
                        </div>)}
                </div>

                <div>
                    <form id="locationsform" onSubmit={formik.handleSubmit}>
                        <div className='locations_form_main_container'>
                            <p className="locations_form_legend">Details</p>
                            <div>
                                <p className='locations_form_label'>Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    autoFocus
                                    isDisabled={isView}
                                    name="ld_name"
                                    placeholder={"Enter office name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_name}
                                    error={formik.touched.ld_name &&
                                        Boolean(formik.errors.ld_name)
                                    }
                                    helperText={
                                        formik.touched.ld_name && formik.errors.ld_name
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Address</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="ld_address"
                                    placeholder={"Enter address"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_address}
                                    error={formik.touched.ld_address &&
                                        Boolean(formik.errors.ld_address)
                                    }
                                    helperText={
                                        formik.touched.ld_address && formik.errors.ld_address
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Pincode</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="ld_pincode"
                                    placeholder={"Enter pincode"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_pincode}
                                    error={formik.touched.ld_pincode &&
                                        Boolean(formik.errors.ld_pincode)
                                    }
                                    helperText={
                                        formik.touched.ld_pincode && formik.errors.ld_pincode
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Contact Person Name</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="ld_contact_person_name"
                                    placeholder={"Enter contact person name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_contact_person_name}
                                    error={formik.touched.ld_contact_person_name &&
                                        Boolean(formik.errors.ld_contact_person_name)
                                    }
                                    helperText={
                                        formik.touched.ld_contact_person_name && formik.errors.ld_contact_person_name
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Email</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="ld_email_id"
                                    placeholder={"Enter email"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_email_id}
                                    error={formik.touched.ld_email_id &&
                                        Boolean(formik.errors.ld_email_id)
                                    }
                                    helperText={
                                        formik.touched.ld_email_id && formik.errors.ld_email_id
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Phone Number</p>
                                <CustomTextField
                                    isDisabled={isView}
                                    name="ld_phone"
                                    placeholder={"Enter phone number"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ld_phone}
                                    error={formik.touched.ld_phone &&
                                        Boolean(formik.errors.ld_phone)
                                    }
                                    helperText={
                                        formik.touched.ld_phone && formik.errors.ld_phone
                                    }
                                />
                            </div>
                            <div>
                                <p className='locations_form_label'>Status<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    isDisabled={isView}
                                    name="ld_statusId"
                                    placeholder={"Select a status"}
                                    options={state}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "ld_statusId",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        state.find((status) =>
                                            status.value ===
                                            formik.values.ld_statusId
                                        ) || null
                                    }
                                    error={
                                        formik.touched.ld_statusId &&
                                        Boolean(formik.errors.ld_statusId)
                                    }
                                    helperText={
                                        formik.touched.ld_statusId &&
                                        formik.errors.ld_statusId
                                    }
                                />
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default LocationForm