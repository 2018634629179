import React, { useState } from 'react'
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import './flightTicketManagerLevelModal.css'
import { approve_or_reject_filght_ticket } from '../../../../../api/process_ecr/flightTicketApis';
import { SuccessToast } from '../../../../../toast/toasts';
import dayjs from 'dayjs';
import { TailSpin } from 'react-loader-spinner';
function FlightTicketManagerLevelModal({ selected_item, open_modal, modal_close }) {
    const [comment, setComment] = useState('')
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        if (!comment.trim()) return setShowError(true)
        const currentDate = dayjs();
        const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
        const formData = new FormData();
        formData.append('bookingId', selected_item?.id)
        formData.append('PNR_Number', selected_item?.pnr)
        formData.append('FBD_ECRstatusID', selected_item?.type === "Approve" ? selected_item?.ecr_cancelled_status === 3 ? 11 : selected_item?.ecr_date_change_status === 3 ? 15 : 3 : 6)
        formData.append('FBRH_review_comment', comment)
        formData.append('FBRH_review_date', formattedDate)
        setLoading(true)
        approve_or_reject_filght_ticket(formData).then((response) => {
            if (response.status === 200) {
                if (selected_item?.type === "Approve") {
                    SuccessToast('Sucessfully approved')
                } else {
                    SuccessToast('Sucessfully rejected')
                }
                modal_close(false)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div>
            <CustomModal
                open={open_modal}
                handleClose={modal_close}
                body={
                    <div>
                        <div>
                            <p className='manager_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                autoFocus
                                rows={10}
                                placeholder={"Enter a comment"}
                                onChange={(e) => {
                                    setComment(e.target.value)
                                    if (showError) {
                                        setShowError(false)
                                    }
                                }}
                            />
                            {showError && (<p className='comment_error_message'>Comment is required</p>)}
                        </div>
                        <div className='manager_level_modal_form_single_row_alignment'>
                            <button className='manager_level_modal_form_reset_button' onClick={() => {
                                modal_close(false)
                            }}>Cancel</button>

                            {/* <button
                                className='manager_level_modal_form_update_button' onClick={()=>{
                                    onSubmit()
                                }}>Submit</button> */}
                            <button disabled={loading} className='manager_level_modal_form_update_button'
                                type="submit"
                                onClick={() => {
                                    onSubmit()
                                }}> {loading ? <div className='submit_button_loader'>
                                    <TailSpin
                                        visible={loading}
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div> : "Submit"}</button>

                        </div>
                    </div>
                }
                title_other={'PNR - ' + selected_item?.pnr}
                title={selected_item?.type === "Approve" ? "Review Ticket" : "Reject Ticket"}
            />
        </div>
    )
}

export default FlightTicketManagerLevelModal